<template>
    <div class="bg-online-class absolute -mt-10 -z-10 w-full h-50v"></div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .bg-online-class {
        background: linear-gradient(90deg, #313F6E 0%, #5B6CA5 24.69%, #596795 70.8%, #293D80 100%);
    }
</style>