<template>
    <div class="h-full">
        <div class="relative h-2/5">
            <div id="certificate" class="flex justify-between bg-white rounded h-screen p-7 -z-10 absolute">
                <img src="../../public/assets/img/certificate-img.png" alt="certificate-img" class="mr-28">
                <div class="flex flex-col justify-between items-start text-left py-10 w-full">
                    <div>
                        <p class="flex text-63 mt-28">Certified &#160;<span class="font-bold">User</span></p>
                        <p class="font-extrabold text-48 mt-12 capitalize" v-if="quiz_user.user">{{quiz_user.user.teacher.name}}</p>
                        <p class="mt-6 ">for successfully completing the examination <span class="capitalize" v-if="course">{{course.name}}</span> with grade of : </p>
                        <p class="font-semibold text-48 mt-3">{{certificate.accumulative_score}} / 100</p>
                    </div>
                    <div>
                        <p class="font-semibold uppercase">Certification code : {{certificate.code}}</p>
                        <p class="mt-5 font-semibold ">{{$filters.convertDate(certificate.created_at)}}</p>
                        <img class="mt-5" width="150px" height="150px" src="../../public/assets/img/certificate-barcode.png" alt="certificate-barcode">
                    </div>
                </div>
                <div class="flex flex-col justify-between py-10">
                    <div class="flex w-full">
                        <div class="flex justify-end relative items-center ">
                            <div class="text-36 font-bold mt-0 mr-24"><span class="text-primary">Educazone</span></div>
                            <div style="height: 70px; width: 70px" class="bg-blue-200 rounded absolute mt-5 mr-6"></div>
                            <div style="height: 70px; width: 70px" class="bg-primary rounded absolute mt-0"></div>
                        </div>
                    </div>
                    <div class="flex flex-col justify-between space-y-5">
                        <div class="flex justify-center">
                            <img src="../../public/assets/img/certificate-signature.png" alt="certificate-signature">
                        </div>
                        <div class="text-center">
                            <hr class="font-bold border-black mb-4 ">
                            <p>Ardharikza</p>
                            <p>CEO</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="flex items-start justify-center bg-black w-full z-10 py-10 absolute h-3/5">
                <img ref="image_tag" id="image_tag" :src="image_url" style="width: 40%;">
            </div>
            <div class="flex items-center justify-end bg-white rounded h-full p-3 z-10">
                <button class="flex items-center justify-center bg-primary rounded text-center font-bold text-white text-sm py-2 px-6 ">Unduh Sertifikat</button>
            </div>
        </div>
    </div>
    
    <div class="flex items-center justify-center absolute top-0 bg-black w-full h-3/5 z-20">
        <img ref="image_tag" id="image_tag" :src="image_url" style="width: 40%;">
    </div>

    <div class="bg-white absolute h-full w-full -z-10"></div>
    
    <div id="certificate" class="flex justify-between bg-white rounded h-screen p-7 -z-20 absolute">
        <img src="../../public/assets/img/certificate-img.png" alt="certificate-img" class="mr-28">
        <div class="flex flex-col justify-between items-start text-left py-10 w-full">
            <div>
                <p class="flex text-63 mt-28">Certified &#160;<span class="font-bold">User</span></p>
                <p class="font-extrabold text-48 mt-12 capitalize" v-if="quiz_user.user">{{quiz_user.user.teacher.name}}</p>
                <p class="mt-6 ">for successfully completing the examination <span class="capitalize" v-if="course">{{course.name}}</span> with grade of : </p>
                <p class="font-semibold text-48 mt-3">{{certificate.accumulative_score}} / 100</p>
            </div>
            <div>
                <p class="font-semibold uppercase">Certification code : {{certificate.code}}</p>
                <p class="mt-5 font-semibold ">{{$filters.convertDate(certificate.created_at)}}</p>
                <img class="mt-5" width="150px" height="150px" src="../../public/assets/img/certificate-barcode.png" alt="certificate-barcode">
            </div>
        </div>
        <div class="flex flex-col justify-between py-10">
            <div class="flex w-full">
                <div class="flex justify-end relative items-center ">
                    <div class="text-36 font-bold mt-0 mr-24"><span class="text-primary">Educazone</span></div>
                    <div style="height: 70px; width: 70px" class="bg-blue-200 rounded absolute mt-5 mr-6"></div>
                    <div style="height: 70px; width: 70px" class="bg-primary rounded absolute mt-0"></div>
                </div>
            </div>
            <div class="flex flex-col justify-between space-y-5">
                <div class="flex justify-center">
                    <img src="../../public/assets/img/certificate-signature.png" alt="certificate-signature">
                </div>
                <div class="text-center">
                    <hr class="font-bold border-black mb-4 ">
                    <p>Ardharikza</p>
                    <p>CEO Educazone</p>
                </div>
            </div>
        </div>
    </div>
    
    <div class="relative w-full">
        
        
        <div class="grid grid-cols-4 w-full h-full text-black bg-primary z-20">
            <div class="relative h-full col-span-1 flex flex-col items-center justify-center bg-white rounded shadow">
                <img src="../assets/logo.png" alt="" class="rounded-full mb-4">
                <p class="mb-4 capitalize">Indra Wahyu</p>
                <div class="flex flex-col items-center justify-center">
                    <p>Diberikan Pada</p>
                    <p>May 31, 2022</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import domtoimage from 'dom-to-image'
export default {
    data() {
        return {
            certificate: {},
            course: {},
            quiz_user: {},
            image_url: '',
            certificate_id: this.$route.params.certificate_id,

            pdfOptions: {
                image: {
                    type: "jpeg",
                    quality: 1,
                },
                html2canvas: { scale: 5 },
                jsPDF: {
                    unit: "mm",
                    orientation: "l",
                },
            },
            exportFilename: "certificate.pdf",
        };
    },
    methods: {
        getCertificateData() {
            this.axios.get(`certificate?id=${this.certificate_id}`).then(response => {
                this.certificate = response.data.data.certificate;
                this.course = response.data.data.course;
                this.quiz_user = this.certificate.quiz_user;
                console.log(this.quiz_user);
                if (this.certificate) {
                    this.createImage();
                }
            }).catch(error => {
                console.log(error.response);
            });
        },
        createImage() {
            this.image_url = domtoimage
            .toJpeg(document.getElementById('certificate'), {
                quality: 0.95
            }).then(dataUrl => {
                this.image_url = dataUrl;
            })
        },
    },
    mounted() {
        this.getCertificateData();
    },
}
</script>

<style>
</style>