<template>
    <div class="container mx-auto">
        <div class="grid grid-cols-1 min-h-screen">
            <div class="my-auto mx-auto w-[345px]">
                <div class="mx-auto">
                    <form @submit.prevent="send" class="align-left mx-auto text-left">
                        <img class="mb-20" src="../../public/assets/img/logo/main-logo.png" alt="main-logo" />
                        <p class="font-bold mb-6 text-24">Buat Kata Sandi Baru</p>

                        <div class="text-left mb-8">
                            <label for="password-field" class="block text-sm font-bold text-slate-700 mb-3">Kata Sandi Baru</label>
                            <input @keyup.enter="login" v-model="password" id="password-field" :type="showPassword ? 'text' : 'password'" class="input-field w-full py-2" placeholder="Masukkan kata sandi baru"/>
                            <!-- eye icon -->
                            <span class="eye-icon" v-if="!showPassword" @click="showPassword = !showPassword"><i class="fa fa-eye" aria-hidden="true"></i></span>
                            <span class="eye-icon" v-else-if="showPassword" @click="showPassword = !showPassword"><i class="fa fa-eye-slash" aria-hidden="true"></i></span>
                        </div>
                        <div class="text-left mb-8">
                            <label for="password-confirmation-field" class="block text-sm font-bold text-slate-700 mb-3">Konfirmasi Kata Sandi</label>
                            <input @keyup.enter="login" v-model="password_confirmation" id="password-confirmation-field" :type="showPasswordConfirmation ? 'text' : 'password'" class="input-field w-full py-2" placeholder="Konfirmasi kata sandi baru"/>
                            <!-- eye icon -->
                            <span class="eye-icon" v-if="!showPasswordConfirmation" @click="showPasswordConfirmation = !showPasswordConfirmation"><i class="fa fa-eye" aria-hidden="true"></i></span>
                            <span class="eye-icon" v-else-if="showPasswordConfirmation" @click="showPasswordConfirmation = !showPasswordConfirmation"><i class="fa fa-eye-slash" aria-hidden="true"></i></span>
                        </div>
                        <p class="text-red-500 text-center mb-4 px-2" v-if="message">{{message}}</p>
                        <button type="submit" class="bg-primary rounded text-center font-bold w-full text-white py-2">Kirim</button>
                    </form>
                    <div class="text-center mx-auto my-4">
                        <!-- <p class="mx-auto mb-6 font-bold text-primary"><router-link to="/login">Kembali ke halaman Masuk</router-link></p> -->
                        <p class="mx-auto mb-6">Tunggu, saya ingat kata sandinya. <span class="font-bold text-primary"><router-link to="/login">Masuk sekarang!</router-link></span> </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showPassword: false,
            showPasswordConfirmation: false,
            password: '',
            password_confirmation: '',
            message: '',
            token: this.$route.params.token,
        }
    },
    methods: {
        send() {
            const data = {
                token: this.token,
                password: this.password,
                conf_password: this.password_confirmation,
            }
            this.axios.post('auth/reset-password', data).then(response => {
                this.message = "Sukses mengubah kata sandi!"
                if (response.data.success) {
                    this.$router.push('/login')
                }
            }).catch(error => {
                console.log(error);
            })
        }
    },
};
</script>

<style scoped>
.input-field {
    border: none;
    border-bottom: 2px solid rgba(37, 40, 43, 0.33);
}
.input-field:focus {
    outline: none;
    border-bottom: 2px solid #313F6E;
}
.eye-icon {
	float: right;
    /* margin-left: -25px; */
    margin-top: -32px;
    margin-right: 5px;
    position: relative;
    z-index: 2;
}
</style>