<template>
  <div class="flex justify-center items-center my-14 ">
      <img
        @click="this.$router.push('/')"
        src="../../public/assets/img/logo/main-logo.png"
        alt="main-logo-big"
        class="hover:cursor-pointer "
      />
  </div>
  <div class="text-left bg-white rounded shadow p-20 mx-20">
    <p class="text-24 font-bold mb-2">SYARAT DAN KETENTUAN PENGGUNAAN PLATFORM IKALSMART</p>
    <p class="text-16 font-semibold text-gray-500 mb-7">Berlaku efektif sejak Februari 2022</p>
    <p class="mb-7">Syarat dan Ketentuan ini merupakan perjanjian antara pengguna <b>(“Anda”)</b> dan PT. AZZUKHRUF INDONESIAN TRADE <b>(“Kami”)</b>, yaitu sebuah perseroan terbatas yang didirikan dan beroperasi secara sah berdasarkan hukum Negara Republik Indonesia dan berdomisili di DKI Jakarta, Indonesia. Syarat dan Ketentuan ini mengatur Anda saat mengakses dan menggunakan aplikasi, situs web (ikalsmart.id), fitur, teknologi, konten dan produk yang Kami sediakan (selanjutnya, secara bersama-sama disebut sebagai <b>“Platform”</b>. </p>
    <p class="mb-7"><b>Harap membaca Syarat dan Ketentuan ini secara seksama sebelum Anda mulai menggunakan Platform Kami, karena peraturan ini berlaku pada penggunaan Anda terhadap Platform Kami.</b> Syarat dan Ketentuan ini mencakup hal-hal sebagai berikut:</p>

    <p>
      <ul class="mb-7">
        <li>A. Pembukaan Akun Anda</li>
        <li>B. Informasi Pribadi Anda</li>
        <li>C. Akses terhadap Platform Kami</li>
        <li>D. Pembelian dan Hak Akses terhadap Kelas pada Platform</li>
        <li>E. Sertifikat</li>
        <li>F. Ketentuan Pembayaran </li>
        <li>G. Penggunaan yang Dilarang</li>
        <li>H. Hak Kekayaan Intelektual</li>
        <li>I. Pengunggahan Konten Pada Platform Kami</li>
        <li>J. Laporan Kemungkinan Pelanggaran</li>
        <li>K. Tindakan yang Kami ANggap Perlu</li>
        <li>L. Membuat Tautan (Link) ke Platform Kami</li>
        <li>M. Tanggung Jawab Anda</li>
        <li>N. Batasan Tanggung Jawab Kami</li>
        <li>O. Keadaan Kahar (Force Majeure)</li>
        <li>P. Hukum yang Berlaku</li>
        <li>Q. Ketentuan Lainnya</li>
        <li>R. Cara Menghubungi Kami</li>
      </ul>
    </p>

    <p class="mb-7">Dengan mendaftar dan/atau menggunakan Platform Kami, maka Anda dianggap telah membaca, mengerti, memahami dan menyetujui semua isi dalam Syarat dan Ketentuan ini.</p>

    <p class="mb-7">Dengan menyetujui Syarat dan Ketentuan ini, Anda juga menyetujui Syarat dan Ketentuan tambahan dan perubahannya yang merupakan bagian yang tidak terpisahkan dari Syarat dan Ketentuan ini (selanjutnya, Syarat dan Ketentuan, Syarat dan Ketentuan tambahan, dan perubahannya secara bersama-sama disebut sebagai “Syarat dan Ketentuan”). Meskipun merupakan satu kesatuan, Syarat dan Ketentuan tambahan akan berlaku dalam hal terdapat perbedaan dengan Syarat dan Ketentuan.</p>
    <p class="mb-7">Jika Anda tidak menyetujui Syarat dan Ketentuan ini, Kami berhak untuk menghentikan akses dan penggunaan Anda terhadap Platform kami.</p>

    <ol>
      <li>
        <b>A. PEMBUKAAN AKUN ANDA</b>
        <p>Sebelum menggunakan Platform, Anda menyetujui Syarat dan Ketentuan ini dan kebijakan privasi, dan mendaftarkan diri Anda dengan memberikan informasi yang Kami butuhkan. Saat melakukan pendaftaran, Kami akan meminta Anda untuk memberikan nama lengkap, alamat surat elektronik dan nomor telepon genggam pribadi Anda. Kami juga dapat menghentikan penggunaan Platform apabila dikemudian hari data-data yang Anda berikan kepada Kami terbukti tidak benar.</p>
        <p>Sistem Kami akan membuatkan Akun pada Platform <b>(“Akun”)</b> untuk Anda yang dapat digunakan untuk menggunakan Platform dan memesan layanan melalui Platform.</p>
        <p>Dalam hal Anda telah keluar dari Akun Anda makan Anda perlu memasukkan alamat surat elektronik yang Anda berikan pada saat mendaftarkan diri Anda dan masukkan password.</p>
        <p>Akun Anda hanya dapat digunakan oleh Anda, sehingga Anda tidak dapat mengalihkannya kepada orang lain dengan alasan apapun. Kamu berhak menolak untuk memfasilitasi layanan jika Kami mengetahui atau mempunyai alasan yang cukup untuk menduga bahwa Anda telah mengalihkan atau membiarkan Akun Anda digunakan oleh orang lain. </p>
        <p>Keamanan dan kerahasiaan Akun Anda, termasuk nama terdaftar, alamat surat elektronik, nomor telepon genggam terdaftar sepenuhnya merupakan tanggung jawab pribadi Anda. Segala kerugian dan risiko yang ada akibat kelalaian Anda, dalam menjaga keamanan dan kerahasiaan sebagaimana disebutkan ditanggung oleh Anda. Dengan demikian, Kami akan menganggap setiap penggunaan atau pesanan yang dilakukan melalui Akun Anda sebagai permintaan yang sah dari Anda.</p>
        <p>Anda harap segera memberitahukan kepada Kami jika Anda mengetahui atau menduga bahwa Akun Anda telah digunakan tanpa sepengetahuan dan persetujuan Anda. Kami akan melakukan tindakan yang Kami anggap perlu dan dapat Kami lakukan terhadap penggunaan tanpa persetujuan tersebut.</p>
      </li>

      <li>
        <b>B. INFORMASI PRIBADI ANDA</b>
        <p>Pengumpulan, penyimpanan, pengolahan, penggunaan dan pembagian informasi pribadi Anda, seperti nama, alamat surat elektronik, dan nomor telepon genggam Anda yang Anda berikan ketika Anda membuka Akun tunduk pada <b>kebijakan privasi</b>, yang merupakan bagian yang tidak terpisahkan dari Syarat dan Ketentuan ini. </p>
      </li>

      <li>
        <b>C. AKSES TERHADAP PLATFORM KAMI</b>
        <p>Kami tidak menjamin bahwa Platform Kami, maupun konten di dalamnya, akan selalu tersedia atau tanpa gangguan. Izin untuk mengakses platform Kami bersifat sementara. Kami dapat menangguhkan, menarik, memberhentikan, maupun mengganti bagian mana pun dari Platform Kami tanpa pemberitahuan sebelumnya. Kami tidak bertanggung jawab atas alasan apapun yang membuat Platform Kami tidak tersedia pada waktu atau periode tertentu. Anda bertanggung jawab untuk membuat semua pengaturan yang dibutuhkan untuk mendapatkan akses terhadap Platform Kami.</p>
      </li>

      <li>
        <b>D. PEMBELIAN DAN HAK AKSES TERHADAP KELAS PADA PLATFORM TERHADAP KELAS PELATIHAN YANG DISEDIAKAN OLEH KAMI</b>
        <p>Ketika Anda melakukan pembelian atas kelas pelatihan yang terdapat pada Platform, maka Kami memberi Anda lisensi terbatas, tidak eksklusif, dan tidak dapat dipindahtangankan untuk mengakses dan melihat materi dan konten terkait kelas yang telah Anda bayarkan. Penggunaan materi dan konten kelas yang telah Anda beli hanyalah untuk kepentingan pribadi Anda dan tidak untuk tujuan komersial. Semua penggunaan untuk tujuan lain secara tegas dilarang. Anda tidak boleh memproduksi ulang, merekam, mendistribusikan kembali, mengirimkan, menetapkan, menjual, menyiarkan, membagi meminjamkan, memodifikasi, mengadaptasi, mengedit, membuat karya turunan, sublisensi, atau mentransfer atau menggunakan materi dan konten pada platform kecuali atas persetujuan tertulis sebelumnya dari Kami. Pada dasarnya Kami memberikan lisensi akses selama Platform ini masih beroperasi kepada Anda ketika Anda membeli kelas pada Platform Kami. Akan tetapi, Kami berhak untuk mencabut lisensi apapun untuk mengakses dan menggunakan kelas sewaktu-waktu dalam hal Kami memutuskan atau wajib menonaktifkan akses terhadap kelas karena alasan tertentu, misalnya, jika kelas yang Anda beli merupakan salah satu objek suatu sengketa hak cipta. Kelas pelatihan yang telah Anda beli melalui Platform Kami tidak dapat dibatalkan atau diuangkan kembali (refund).</p>
      </li>

      <li>
        <b>E. SERTIFIKAT</b>
        <p>Sertifikat merupakan bentuk penghargaan atau apresiasi yang Kami berikan apabila Anda mengikuti seluruh rangkaian pelatihan dengan menyeluruh dan sebagai tanda bahwa Anda telah menguasai materi yang diberikan dalam kelas tersebut.</p>
        <p>Untuk memperoleh Sertifikat penamatan, Anda harus menyelesaikan seluruh rangkaian video ??(minimum 80% dari durasi setiap video)?? dan materi lain termasuk kuis serta materi teks secara komplet.</p>
        <p>Sertifikat ini bukan merupakan tanda pemberian sertifikasi atas penguasaan kompetensi kerja atau tAnda kelulusan atas suatu uji kompetensi kerja, yang pengaturannya diatur terpisah dalam peraturan perundang-undangan tersendiri.</p>
      </li>

      <li>
        <b>F. KETENTUAN PEMBAYARAN</b>
        <p>Ketika Anda melakukan pembayaran atas kelas pada platform Kami, maka Anda setuju untuk menggunakan alat pembayaran yang sah dengan metode yang tersedia pada Platform. Anda setuju untuk membayar harga pembelian akses terhadap kelas yang Anda beli dan Anda memberi wewenang kepada Kami untuk melakukan proses transaksi jual beli atas kelas yang Anda beli dengan kartu debit, kartu kredit atau alat pembayaran lainnya (seperti mobile wallet) yang Anda gunakan pada saat pembelian kelas tersebut di Platform Kami. Perlu diperhatikan bahwa Kami bekerja sama dengan pihak ketiga lainnya terkait proses pembayaran untuk menawarkan kepada Anda metode pembayaran yang paling nyaman dan untuk menjaga keamanan informasi pembayaran Anda. Anda dapat melihat pada kebijakan privasi Kami untuk lebih jelasnya. Ketika Anda melakukan pembelian, Anda setuju untuk tidak menggunakan metode pembayaran yang tidak valid atau tidak sah. Jika metode pembayaran Anda gagal, mohon untuk dapat segera memberitahukan kepada Kami melalui detail kontak yang tercantum pada bagian Syarat dan Ketentuan ini. Kami berhak menonaktifkan akses Anda terhadap kelas yang Anda beli apabila Kami belum menerima pembayaran atas kelas yang Anda beli tersebut. Kami dari waktu ke waktu akan memberikan promo dan/atau diskon untuk kelas-kelas pada platform Kami, yang hanya memungkinkan Anda untuk mendapatkan potongan harga pada jangka waktu tertentu. Harga yang berlaku untuk suatu kelas merupakan harga pada saat Anda menyelesaikan pembelian kursus tersebut (check out).</p>
      </li>

      <li>
        <b>G. PENGGUNAAN YANG DILARANG</b>
        <p>
          Anda hanya diperbolehkan menggunakan Platform Kami untuk tujuan-tujuan yang sah menurut hukum. Anda tidak boleh menggunakan Platform Kami:
          <ol>
            <li>a. Dengan cara-cara yang melanggar hukum dan peraturan loka, nasional, maupun internasional yang berlaku.</li>
            <li>b. Dengan cara-cara yang melanggar hukum atau menipu, atau memiliki tujuan atau dampak yang melanggar hukum atau menipu.</li>
            <li>c. Untuk tujuan membahayakan atau mencoba mencelakakan orang lain dengan cara apapun.</li>
            <li>d. Mengirim, secara sadar menerima, mengunggah, mengunduh, menggunakan, atau menggunakan kembali materi yang tidak sesuai dengan standar konten Kami.</li>
            <li>e. Menyebarkan atau mengirimkan materi iklan atau promosi yang tidak diinginkan atau tidak sah, serta bentuk permintaan serupa lainnya (seperti spam).</li>
            <li>f. Dengan sengaja meneruskan data, mengirimkan atau mengunggah materi yang mengandung virus, trojan worm, logic bomb, keystroke loggers, spyware, adware, maupun program berbahaya lainnya atau kode komputer sejenis yang dirancang untuk memberikan efek merugikan terhadap pengoperasian perangkat lunak atau perangkat keras apapun.</li>
          </ol>
          <br>
          Anda juga setuju:
          <ol>
            <li>a. Untuk tidak mereproduksi, menggAndakan, menyalin, atau menjual kembali bagian manapun dari PLatform Kami yang bertentangan dengan ketentuan dalam Syarat dan Ketentuan Platform Kami.</li>
            <li>b. Untuk tidak mengakses tanpa izin, mengganggu, merusak, atau mengacak-acak:
              <ol>
                <li>i. bagian mana pun dari Platform Kami;</li>
                <li>ii. peralatan atau jaringan di mana Platform Kami tersimpan;</li>
                <li>iii. perangkat lunak apapun yang digunakan dalam penyediaan Platform Kami; atau</li>
                <li>iv. peralatan atau jaringan atau perangkat lunak yang dimiliki oleh pihak ketiga mana pun. </li>
              </ol>
            </li>
          </ol>
          <br>
        </p>
      </li>

      <li>
        <b>H. HAK KEKAYAAN INTELEKTUAL</b>
        <p>Platform Kami, termasuk namun tidak terbatas pada nama, logo, kode program, desain, merek dagang, teknologi, basis data, proses dan model bisnis, konten-konten dan materi pada kelas dilindungi oleh hak cipta, merek, paten dan hak kekayaan intelektual lainnya yang tersedia berdasarkan hukum Republik Indonesia yang terdaftar atas nama Kami. kami memiliki seluruh hak dan kepentingan atas Platform, termasuk seluruh hak kekayaan intelektual terkait dengan seluruh fitur yang terdapat didalamnya dan hak kekayaan intelektual terkait.</p>
        <p>Anda tidak boleh mengubah salinan dalam bentuk kertas maupun digital dari materi apapun yang telah Anda cetak atau unduh dengan cara apapun, dan Anda tidak boleh menggunakan ilustrasi, foto-foto, potongan video atau audio, maupun grafis lain secara terpisah dari teks pendampingnya.</p>
        <p>
          Anda dilarang untuk:
          <ol>
            <li>I. menyalin, mengubah, mencetak, mengadaptasi, menerjemahkan, menciptakan karya tiruan dari, mendistribusikan, memberi lisensi, menjual, memindahkan, menampilkan secara publik, menunjukkan secara publik, menggAndakan, mengirimkan, menyiarkan lewat media online maupun offline, memotong, membongkar, atau sebaliknya, mengeksploitasi bagian mana pun dari Platform Kami, termasuk namun tidak terbatas pada konten-konten dan materi-materi berbayar pada Platform, baik secara fisik maupun digital, dalam masa berlangganan maupun selesai berlangganan Platform;</li>
            <li>II. memberi lisensi, lisensi turunan, menjual, menjual ulang, memindahkan, menetapkan, mendistribusikan, atau sebaliknya mengeksploitasi atau membagikan secara komersial Platform Kami dan/atau perangkat lunak lain yang terasosiasi dengan Platform Kami dengan cara apapun;</li>
            <li>III. menciptakan tautan internet menuju situs web Kami, atau menyadur (frame), atau mengkomputasi (mirror) perangkat lunak mana pun pada server (server atau perangkat nirkabel atau perangkat yang terhubung ke internet lainnya;</li>
            <li>IV. merekayasa balik atau mengakses Platform Kami guna (a) membangun produk atau jasa yang kompetitif, (b) membangun produk berdasarkan ide, fitur, fungsi, maupun grafis yang serupa dengan Platform Kami, atau (c) menyalin ide, fitur, fungsi, atau grafis pada Platform Kami;</li>
            <li>V. meluncurkan program atau skrip otomatis termasuk, namun tidak terbatas pada web spider, web crawler, robot web, web ant, pengindeksan web, bot, virus, worm, atau program apapun yang dapat meningkatkan permintaan server per detik, atau membuat beban terlalu berat yang mengganggu operasi dan/atau kinerja Platform Kami;</li>
            <li>VI. menggunakan robot, spider, aplikasi pencarian atau pengambilan situs, maupun perlengkapan dan proses manual atau otomatis lain untuk mengambil, membuat indeks, menambang data, atau dengan cara apapun, menggAndakan atau menghindari struktur navigasi atau tampilan Platform Kami maupun kontennya;</li>
            <li>VII. memasang, mendistribusikan, atau menggAndakan dengan cara apapun materi dengan hak cipta, merek dagang, atau informasi kepemilikan lain tanpa sebelumnya memperoleh persetujuan dari pemilik hak kepemilikan;</li>
            <li>VIII. menghapus pemberitahuan hak cipta, merek dagang, atau hak kepemilikan lainnya yang terkandung dalam Platform Kami. Tidak ada lisensi atau hak yang diberikan kepada Anda secara implisit atau sebaliknya berdasarkan hak kekayaan intelektual yang dimiliki atau dikendalikan oleh kami dan pemberi lisensi Kami, kecuali lisensi dan hak yang secara tersurat diberikan dalam Persyaratan Penggunaan ini. Anda tidak boleh menggunakan bagian mana pun dari konten pada Platform Kami untuk tujuan komersial tanpa sebelumnya memperoleh lisensi untuk melakukannya dari Kami atau pemberi lisensi Kami.</li>
          </ol>
        </p>
        <br>
        <p>Anda (1) tidak diperbolehkan mengirimkan spam atau pesan duplikatif yang tidak diinginkan dan melanggar peraturan yang berlaku; (ii) tidak diperbolehkan mengirimkan atau menyimpan materi yang mengganggu, bersifat asusila, mengancam, mencemarkan nama baik, maupun tidak mematuhi hukum serta membahayakan, termasuk namun tidak terbatas pada materi yang berbahaya bagi anak-anak atau melanggar hak privasi pihak ketiga; (iii) tidak diperbolehkan mengirimkan materi yang mengandung virus perangkat lunak seperti worm dan trojan, serta kode, berkas digital, kode skrip, agen, maupun program komputer lain yang berbahaya; (iv) tidak diperbolehkan mengganggu dan mengacaukan integritas atau kinerja Platform Kami serta data yang dimuat di dalamnya; (v) tidak diperbolehkan berusaha memperoleh akses yang tidak sah ke dalam Platform Kami maupun sistem dan jaringan terkait; dan (vi) tidak diperbolehkan menyamar sebagai individu atau entitas lain yang tidak mencerminkan afiliasi Anda sebenarnya dengan seseorang atau suatu entitas.</p>
        <p>Status Kami (dan status kontributor lain yang telah Kami identifikasi) sebagai penulis dari konten pada situs web Kami harus selalu diakui.</p>
        <p>Jika Anda mencetak, menyalin, atau mengunduh bagian mana pun dari Platform Kami yang melanggar Syarat dan Ketentuan ini, hak Anda untuk menggunakan Platform Kami akan segera diberhentikan dan Anda harus, berdasarkan keputusan Kami, mengembalikan atau memusnahkan salinan dari materi yang telah Anda buat.</p>
        <p>Kami berhak untuk melakukan investigasi maupun menuntut bentuk pelanggaran apapun terhadap Persyaratan Penggunaan di atas sesuai dengan ketentuan hukum yang berlaku. Kami dapat melibatkan dan bekerja sama dengan pihak yang berwenang dalam menuntut pengguna yang melanggar Persyaratan Pengguna ini. Anda mengakui bahwa Kami tidak diwajibkan untuk mengawasi akses Anda terhadap Platform Kami, tapi Kami berhak untuk melakukannya dengan tujuan mengoperasikan. </p>
        <p>Pada saat menggunakan Platform Kami, Anda harus memastikan kepatuhan Anda dengan Syarat dan Ketentuan ini, atau untuk menaati hukum yang berlaku atau keputusan pengadilan, lembaga administratif, maupun badan pemerintahan lainnya.</p>
      </li>

      <li>
        <b>I. PENGUNGGAHAN KOMENTAR PADA FORUM IKALSMART</b>
        <p>Anda dapat menggunakan fitur forum yang memungkinkan Anda mengunggah komentar dan pertanyaan ke Platform Kami, atau untuk berhubungan dengan pengguna Platform Kami lainnya melalui layanan interaktif.</p>
        <p>
          Komentar dan pertanyaan harus:
          <ol>
            <li>a. Akurat (di mana mereka menyatakan fakta);</li>
            <li>b. Benar-benar dimaksudkan (di mana mereka menyatakan opini dan bertanya); dan</li>
            <li>c. Mematuhi hukum yang berlaku di Indonesia dan di negara dari mana mereka dituliskan.</li>
          </ol>

          	Komentar tidak boleh:
            <ol>
              <li>a. Mengandung materi yang mencemarkan nama baik seseorang;</li>
              <li>b. Mengandung materi yang tidak senonoh, menyinggung, bersifat kebencian, atau menghasut;</li>
              <li>c. Mempromosikan konten yang berkaitan dengan perjudian, undian, dan/atau taruhan;</li>
              <li>d. Mempromosikan materi yang eksplisit secara seksual;</li>
              <li>e. Mempromosikan kekerasan;</li>
              <li>f. Mempromosikan diskriminasi berdasarkan ras, jenis kelamin, agama, kebangsaan, kecacatan, orientasi seksual, atau usia;</li>
              <li>g. Mempromosikan layanan meretas (hacking) dan/atau membobol (cracking);</li>
              <li>h. Mempromosikan akses terhadap produk penipuan, pencucian uang, skema pemasaran berjenjang (multi-level marketing), serta produk bajakan;</li>
              <li>i. Mempromosikan akses terhadap perdagangan manusia dan organ tubuh manusia;</li>
              <li>j. Mempromosikan akses terhadap zat-zat terlarang dan narkotika;</li>
              <li>k. Mempromosikan akses terhadap rokok dan materi berbahan tembakau;</li>
              <li>l. Mempromosikan penjualan tidak sah terhadap produk-produk yang membutuhkan lisensi (misalnya obat-obatan, bahan peledak, senjata api, dll);</li>
              <li>m. Melanggar hak cipta, hak pangkalan data (database right), atau pun merek dagang orang lain;</li>
              <li>n. Dapat menipu seseorang;</li>
              <li>o. Dibuat dengan melanggar kewajiban hukum apapun kepada pihak ketiga, seperti kewajiban kontrak dan kewajiban menjaga kerahasiaan;</li>
              <li>p. Mempromosikan aktivitas melanggar hukum apapun;</li>
              <li>q. Mengancam, menyalahgunakan, atau menyerang privasi orang lain, atau menyebabkan gangguan, ketidaknyamanan, maupun kecemasan yang tidak perlu;</li>
              <li>r. Dapat melecehkan, menyinggung, mempermalukan, membuat khawatir, atau mengganggu siapapun;</li>
              <li>s. Digunakan untuk menyamar sebagai orang lain, atau untuk memalsukan identitas atau afiliasi Anda dengan orang lain;</li>
              <li>t. Memberi kesan bahwa konten tersebut berasal dari Kami, ketika tidak benar demikian;</li>
              <li>u. Mengadvokasi, mempromosikan, atau membantu tindakan yang melanggar hukum seperti (hanya sebagai contoh) pelanggaran hak cipta atau penyalahgunaan komputer; dan</li>
              <li>v. Mengandung atau berhubungan dengan materi dan/atau konten kelas yang dibeli.</li>
            </ol>
        </p>
        <p>Anda menjamin bahwa komentar dan pertanyaan tersebut telah mematuhi stAndar yang telah disebutkan, dan bahwa Anda akan bertanggung jawab secara penuh kepada Kami dan mengganti kerugian Kami atas pelanggaran terhadap jaminan tersebut. Dengan demikian, Anda akan bertanggung jawab untuk segala kerugian atau kerusakan yang Kami derita akibat pelanggaran jaminan Anda.</p>
        <p>Seluruh komentar yang Anda unggah ke Platform kami akan dianggap bersifat tidak rahasia, tidak bersifat kepemilikan dan tidak melanggar hak kekayaan intelektual dari pihak manapun, kecuali Anda nyatakan sebaliknya kepada Kami sebagaimana dijelaskan dalam Kebijakan Privasi Platform Kami. Anda tetap memiliki semua hak kepemilikan terhadap komentar Anda, namun Anda diharuskan untuk memberi Kami dan pengguna Platform Kami lainnya lisensi terbatas untuk menggunakan, menyimpan, dan menyalin komentar tersebut, serta untuk mendistribusikan dan membuatnya tersedia bagi pihak ketiga. </p>
        <p>Kami juga berhak menyingkap identitas Anda kepada pihak ketiga mana pun yang mengklaim bahwa komentar yang Anda pampang atau unggah merupakan pelanggaran terhadap hak kekayaan intelektual mereka, atau hak privasi mereka.</p>
        <p>Kami tidak bertanggung jawab atau dikenakan kewajiban oleh pihak mana pun, untuk komentar atau akurasi dari komentar manapun yang Anda pampang atau pengguna Platform Kami lainnya.</p>
        <p>Kami berkah menghapus setiap komentar dan pertanyaan yang Anda buat pada Platform Kami jika menurut pendapat Kami komentar dan pertanyaan Anda tidak sesuai dengan stAndar komentar dan pertanyaan yang ditetapkan dalam Syarat dan Ketentuan Kami.</p>
        <p>Pandangan yang diungkapkan oleh pengguna lain pada Platform Kami tidak mewakili pandangan maupun nilai-nilai Kami. </p>
        <p>Anda sepenuhnya bertanggung jawab untuk mengamankan dan membuat cadangan dari komentar dan pertanyaan Anda. </p>
      </li>

      <li>
        <b>J. LAPORAN KEMUNGKINAN PELANGGARAN</b>
        <p>Jika Anda menemukan konten apapun pada Platform Kami yang Anda yakini melanggar hak cipta apapun, menyalahi hak lainnya,, merusak nama baik, bersifat pornografis atau tidak senonoh, rasis, SARA, atau dengan cara-cara lain menyebabkan pelanggaran secara luas, atau yang merupakan peniruan identitas, penyalahgunaan, spam, atau sebaliknya menyalahi Persyaratan Penggunaan serta Kebijakan Privasi maupun peraturan yang berlaku lainnya, silakan laporkan ini kepada Kami melalui surat elektronik ke hello@ikalsmart.id dan/atau melalui surat ke alamat berikut: ikalsmart GEDUNG GRAHA KRAMAYUDHA LANTAI 4 UNIT B, JL. WARUNG JATI BARAT NO.43, DUREN TIGA, PANCORAN.</p>
        <p>Pastikan bahwa Anda menyertakan, dalam laporan tersebut (“Laporan”), informasi sebagai berikut:
          <ol>
            <li>a. Pernyataan bahwa Anda telah mengidentifikasi konten yang melanggar atau menyalahi Syarat dan Ketentuan dan Kebijakan Privasi Platform Kami maupun peraturan yang berlaku lainnya pada Platform Kami;</li>
            <li>b. Deskripsi dari pelanggaran atau konten ilegal serta tautan di mana konten tersebut berada;</li>
            <li>c. Screenshot dari konten yang melanggar atau bersifat ilegal;</li>
            <li>d. Nama lengkap, alamat dan nomor telepon Anda, alamat surat elektronik di mana Anda dapat dihubungi, serta nama pengguna (username) Akun Anda jika Anda memilikinya.</li>
          </ol>
        </p>
        <p>
          Dengan membuat Laporan, Anda akan dianggap telah menyertakan, dalam Laporan tersebut:
          <ol>
            <li>a. Pernyataan bahwa Anda dengan niatan baik percaya bahwa penggunaan materi yang disengketakan tersebut tidak diizinkan oleh pemilik hak cipta, agennya, maupun hukum;</li>
            <li>b. Pernyataan bahwa informasi dalam Laporan tersebut adalah akurat; dan </li>
            <li>c. Pada kasus di mana Anda melaporkan konten yang Anda yakini melanggar hak cipta atau hak-hak terkait lainnya, pernyataan bahwa Anda berwenang untuk bertindak atas nama pemilik hak cipta atau hak-hak lain yang diduga telah dilanggar.</li>
          </ol>
        </p>
      </li>

      <li>
        <b>K. TINDAKAN YANG KAMI ANGGAP PERLU</b>
        <p>Apabila Kami mengetahui atau mempunyai alasan yang cukup untuk menduga bahwa Anda telah melakukan tindakan asusila, pelanggaran, kejahatan atau tindakan lain yang bertentangan dengan Syarat dan Ketentuan ini dan/atau peraturan perundang-undangan yang berlaku, baik yang dirujuk dalam Syarat dan Ketentuan ini atau tidak, maka Kami berhak untuk dan dapat membekukan Akun, baik sementara atau permanen, atau menghentikan akses Anda terhadap Platform, melakukan pemeriksaan, menuntut ganti kerugian, melaporkan kepada pihak berwenang dan/atau mengambil tindakan lain yang kami anggap perlu, termasuk tindakan hukum pidana maupun perdata. </p>
        <p>Kami akan menindaklanjuti dengan melakukan investigasi dan/atau memfasilitasi Penyedia Layanan yang bersangkutan untuk melaporkan kepada pihak yang berwajib apabila Kami menerima Laporan adanya pelanggaran yang Anda lakukan atas Syarat dan Ketentuan ini ataupun pelanggaran terhadap peraturan perundang-undangan yang berlaku, sehubungan dengan pelecehan atau kekerasan verbal, termasuk namun tidak terbatas pada, atas fisik, jenis kelamin, suku, agama dan ras.</p>
      </li>

      <li>
        <b>L. MEMBUAT TAUTAN KE PLATFORM KAMI</b>
        <p>Anda dapat membuat tautan ke Platform Kami, sepanjang Anda melakukannya dengan cara yang adil dan legal serta tidak merusak reputasi Kami atau mengambil keuntungan darinya.</p>
        <p>Anda tidak diperbolehkan membuat tautan sedemikian rupa sehingga memberi kesan adanya suatu asosiasi, persetujuan, ataupun dukungan dari Kami ketika hal tersebut tidak benar adanya.</p>
        <p>Anda tidak diperbolehkan membuat tautan ke Platform Kami pada situs web manapun yang tidak dimiliki oleh Anda. </p>
        <p>Kami berhak mencabut izin pembuatan tautan tanpa pemberitahuan.</p>
        <p>Situs web yang Anda berikan tautan harus memenuhi segala aspek stAndar konten yang telah ditetapkan melalui Syarat dan Ketentuan Kami.</p>
        <p>Jika Anda ingin menggunakan konten pada Platform Kami selain melalui hal-hal yang diatur di atas, silakan hubungi: hello@ikalsmart.id.</p>
      </li>

      <li>
        <b>M. TANGGUNG JAWAB ANDA</b>
        <p>Anda bertanggung jawab penuh atas keputusan yang Anda buat untuk menggunakan atau mengakses Platform. Anda harus berperilaku dengan hormat dan tidak boleh terlibat dalam perilaku atau tindakan yang tidak sah, mengancam atau melecehkan ketika menggunakan Platform. Anda bertanggung jawab secara penuh atas setiap kerugian dan/atau klaim yang timbul dari penggunaan Platform melalui Akun Anda, baik oleh Anda atau pihak yang lain yang menggunakan Akun Anda, dengan cara yang bertentangan dengan Syarat dan Ketentuan ini, Kebijakan PRivasi atau peraturan perundang-undangan yang berlaku, termasuk namun tidak terbatas untuk tujuan anti pencucian uang, anti pendanaan terorisme, aktivitas kriminal, penipuan dalam bentuk apapun (termasuk namun tidak terbatas pada kegiatan phishing dan/atau social engineering), pelanggaran hak kekayaan intelektual, dan/atau aktivitas lain yang merugikan publik dan/atau pihak lain manapun atau yang dapat atau dianggap dapat merusak reputasi Kami. Kami tidak menjamin bahwa Platform Kami akan aman atau terbebas dari bug atau virus. Anda bertanggung jawab untuk mengatur teknologi informasi, program komputer, serta platform yang Anda gunakan untuk mengakses Platform Kami. Anda harus menggunakan perangkat lunak anti virus Anda sendiri.</p>
      </li>
      
      <li>
        <b>N. BATASAN TANGGUNG JAWAB KAMI</b>
        <p>Platform yang Kami sediakan adalah sebagaimana adanya dan kami tidak menyatakan atau menjamin bahwa keAndalan, ketepatan waktu, kualitas, kesesuaian, ketersediaan, akurasi, kelengkapan atau keamanan dari Platform dapat memenuhi kebutuhan dan akan sesuai dengan harapan Anda.</p>
        <p>
          Kami tidak bertanggung jawab kepada pengguna mana pun atas kerugian atau kerusakan, baik dalam bentuk kontrak, perbuatan melawan hukum (termasuk kelalaian), pelanggaran kewajiban berdasarkan undang-undang, atau sebaliknya, meskipun dapat diperkirakan, yang terjadi di bawah atau berhubungan dengan:
          <ol>
            <li>a. Penggunaan, atau ketidakmampuan untuk menggunakan Platform Kami; atau</li>
            <li>b. Penggunaan atau kepercayaan terhadap konten apapun yang ditampilkan pada Platform Kami.</li>
          </ol>
        </p>
        <br>
        <p>Kami hanya menyediakan Platform untuk penggunaan domestik dan pribadi. Anda setuju untuk tidak menggunakan Platform Kami untuk tujuan komersial atau bisnis apapun, dan kami tidak bertanggung jawab kepada Anda atas kerugian, kehilangan usaha, gangguan usaha, maupun hilangnya kesempatan bisnis.</p>
        <p>Kami tidak bertanggung jawab atas kerugian atau kerusakan yang disebabkan oleh virus, serangan Penolakan Layanan  secara Terdistribusi (Distributed Denial of Service/DDoS), maupun materi teknologi berbahaya lainnya yang dapat menginfeksi peralatan komputer, program komputer, data atau materi kepemilikan lainnya karena penggunaan maupun pengunduhan konten apapun dari Platform Kami maupun situs web lain yang terkait dengannya oleh Anda.</p>
        <p>Kami tidak berkewajiban untuk mengawasi akses atau penggunaan Anda terhadap Platform. Akan tetapi, Kami akan tetap melakukan pengawasan untuk memastikan kelancaran penggunaan Platform dan untuk memastikan kepatuhan Anda terhadap Syarat dan Ketentuan ini, peraturan perundang-undangan yang berlaku, putusan pengadilan, dan/atau ketentuan lembaga administratif atau badan pemerintahan lainnya.</p>
        <p>Kami tidak bertanggung jawab atas konten situs web yang terhubung dengan Platform Kami. Tautan semacam itu seharusnya tidak ditafsirkan sebagai bentuk dukungan Kami terhadap situs-situs tersebut. Kami tidak bertanggung jawab atas kerugian atau kerusakan apapun yang timbul dari penggunaan Anda terhadap situs-situs web tersebut.</p>
      </li>

      <li>
        <b>O. KEADAAN KAHAR (FORCE MAJEURE)</b>
        <p>Platform Kami dapat diinterupsi oleh kejadian di luar kewenangan atau kontrol Kami <b>(“Keadaan Kahar”)</b>, termasuk namun tidak terbatas pada bencana alam, gangguan listrik, gangguan telekomunikasi, kebijakan pemerintah, dan lain-lain. Anda setuju untuk membebaskan Kami dari setiap tuntutan dan tanggung jawab, jika Kami tidak dapat memfasilitasi Layanan, termasuk memenuhi instruksi yang Anda berikan melalui Platform, baik sebagian maupun seluruhnya, karena suatu Keadaan Kahar.</p>
      </li>

      <li>
        <b>P. HUKUM YANG BERLAKU</b>
        <p>Syarat dan Ketentuan ini diatur berdasarkan hukum Republik Indonesia. Setiap dan seluruh perselisihan yang timbul dari penggunaan Platform tunduk pada yurisdiksi eksklusif Pengadilan Negeri Jakarta Selatan.</p>
      </li>

      <li>
        <b>Q. KETENTUAN LAINNYA</b>
        <p>Anda mengerti dan setuju bahwa Syarat dan Ketentuan ini merupakan perjanjian dalam bentuk elektronik dan tindakan Anda menekan tombol 'daftar' saat pembukaan Akun atau tombol 'masuk' saat akan mengakses Akun Anda merupakan persetujuan aktif Anda untuk mengikatkan diri dalam perjanjian dengan Kami sehingga keberlakuan Syarat dan Ketentuan ini serta Kebijakan Privasi adalah sah dan mengikat secara hukum dan terus berlaku sepanjang penggunaan Platform oleh Anda.</p>
        <p>Kami dapat mengubah Syarat dan Ketentuan ini dari waktu ke waktu, perubahan mana akan diberitahukan kepada Anda dalam bentuk dan melalui sarana apapun, termasuk namun tidak terbatas pada pemberitahuan melalui surat elektronik; dan Anda setuju bahwa Anda bertanggung jawab untuk meninjau Syarat dan Ketentuan ini secara berkala. Penggunaan secara berkelanjutan oleh Anda atas layanan yang diberikan dalam Platform setelah perubahan dan/atau penambahan Syarat dan Ketentuan yang berlaku, akan dianggap sebagai persetujuan dan penerimaan Anda atas perubahan dan/atau penambahan tersebut. Anda dapat menyampaikan keberatan atas perubahan dan/atau penambahan atas Syarat dan Ketentuan yang berlaku yang dianggap merugikan Anda secara komersial dan material dalam jangka waktu 14 (empat belas) hari kalender sejak perubahan dan/atau penambahan tersebut dipublikasikan. Kami berhak untuk menghentikan akses Anda terhadap Platform dalam hal Anda berkeberatan atas perubahan dan/atau penambahan Syarat dan Ketentuan yang berlaku tersebut. </p>
        <p>Kami dapat memperbarui Platform dari waktu ke waktu serta mengubah kontennya kapan pun. Meskipun demikian, perlu diketahui bahwa Platform Kami dapat memiliki konten yang tidak diperbarui pada waktu tertentu, dan Kami tidak bertanggung jawab untuk memperbaruinya. Kami tidak menjamin bahwa Platform Kami, maupun konten yang terkandung di dalamnya, dapat bebas sepenuhnya dari kesalahan atau kelalaian.</p>
        <p>Anda tidak akan mengajukan tuntutan atau keberatan apapun terhadap keabsahan dari Syarat dan Ketentuan atau Kebijakan Privasi yang dibuat dalam bentuk elektronik.</p>
        <p>Anda tidak dapat mengalihkan hak Anda berdasarkan Syarat dan Ketentuan ini tanpa persetujuan tertulis sebelumnya dari Kami. Namun, Kami dapat mengalihkan hak Kami berdasarkan Syarat dan Ketentuan ini setiap saat kepada pihak lain tanpa perlu mendapatkan persetujuan terlebih dahulu dari atau memberikan pemberitahuan sebelumnya kepada Anda. </p>
        <p>Bila Anda tidak mematuhi atau melanggar ketentuan dalam Syarat dan Ketentuan ini, dan Kami tidak mengambil tindakan secara langsung, bukan berarti Kami mengesampingkan hak Kami untuk mengambil tindakan yang diperlukan di kemudian hari.</p>
        <p>Ketentuan ini tetap berlaku bahkan setelah pembekuan sementara, pembekuan permanen, penghapusan Platform atau setelah berakhirnya perjanjian ini antara Anda dan Kami. </p>
        <p>Jika salah satu dari ketentuan dalam Syarat dan Ketentuan ini tidak dapat diberlakukan, hal tersebut tidak akan memengaruhi ketentuan lainnya.</p>
      </li>

      <li>
        <b>R. CARA MENGHUBUNGI KAMI</b>
        <p>Untuk menghubungi Kami, silakan kirim surat elektronik ke hello@ikalsmart.id.</p>
      </li>

      <li>
        <p>Saya telah membaca dan mengerti seluruh Syarat dan Ketentuan ini dan konsekuensinya dan dengan ini menerima setiap hak, kewajiban, dan ketentuan yang diatur di dalamnya. </p>
      </li>
    </ol>

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
 p {
  margin-bottom: 28px;
 }
 ol {
  margin-left: 28px;
 }
</style>