<template>
    <nav
        class="sticky top-0 w-full flex flex-wrap items-center justify-between py-4 px-5 laptop:px-20 z-30 bg-white text-gray-500 hover:text-gray-700 focus:text-gray-700 shadow-lg navbar navbar-expand-lg navbar-light">
        <div class="w-full flex items-center justify-between">
            <router-link to="/"
                class="flex items-center text-gray-900 hover:text-gray-900 focus:text-gray-900 mt-2 lg:mt-0 mr-1">
                <img src="../../../public/assets/img/logo/main-logo.png" alt="main-logo.png" />
            </router-link>
            <div v-if="isLoggedIn" class="relative items-center flex flex-col">
                <div class="flex items-start mx-20">
                    <div class="hidden laptop:flex justify-start items-center bg-gray-100 rounded-lg w-[500px]">
                        <img src="../../../public/assets/img/icons/search.png" alt="search"
                            class="mx-4 h-4 w-4 bg-gray-100" />
                        <input @click="search" type="text" id="search-input"
                            placeholder="Cari kelas, mentor, atau topik menarik lainnya"
                            class="text-12 h-10 bg-gray-100 rounded-lg outline-none w-full" v-model="search" />
                    </div>
                </div>

                <!-- SEARCH RESULT -->
                <div v-if="is_searching" class="bg-white shadow text-black mt-12 w-[500px] max-h-[481px] overflow-scroll z-10 absolute p-6 pb-0 rounded-b-xl ">
                    <!-- No result -->
                    <div v-if="(!courses && !online_classes && !mentors)" class="mb-4">
                        <img src="../../../public/assets/img/empty-state-rounded-small.png" alt="" class="mx-auto mb-3">
                        <p class="text-16 font-bold">Pencarian tidak ditemukan</p>
                    </div>

                    <!-- Has result -->
                    <div v-else>
                        <!-- Courses -->
                        <div v-if="courses" class="flex flex-col items-start justify-start border-b mb-4">
                            <p class="font-semibold text-12 mb-4 ">Belajar Mandiri</p>
                            <div v-for="course in courses" :key="course.id" @click="viewDetailCourse(course.id)"
                                class="flex items-center justify-start text-black hover:cursor-pointer hover:bg-light-white w-full">
                                <div class="flex items-center justify-start mb-4 ">
                                    <div class="relative w-[61px] h-[61px] mr-4">
                                        <img class="rounded-xl h-full w-full object-cover" :src="course.thumbnail" @error="replaceCourseThumbnail" alt="course photo" />
                                    </div>
                                    <div class="text-14 font-medium">{{ course.name }}</div>
                                </div>
                            </div>
                        </div>
    
                        <!-- Online Class -->
                        <div v-if="online_classes" class="flex flex-col items-start justify-start border-b mb-4">
                            <p class="font-semibold text-12 mb-4 ">Kelas Online</p>
                            <div v-for="online_class in online_classes" :key="online_class.id" @click="viewDetailOnlineClass(online_class.id)"
                                class="flex items-center justify-start text-black hover:cursor-pointer hover:bg-light-white w-full">
                                <div class="flex items-center justify-start mb-4 ">
                                    <div class="relative w-[61px] h-[61px] mr-4">
                                        <img class="rounded-xl h-full w-full object-cover" :src="online_class.thumbnail" @error="replaceOnlineClassThumbnail" alt="online class photo" />
                                    </div>
                                    <div class="text-14 font-medium">{{ online_class.title }}</div>
                                </div>
                            </div>
                        </div>
    
                        <!-- Mentors -->
                        <div v-if="mentors" class="flex flex-col items-start justify-start border-b mb-4">
                        <p class="font-semibold text-12 mb-4 ">Mentor</p>
                            <div v-for="mentor in mentors" :key="mentor.id" @click="viewDetailMentor(mentor)"
                                class="flex items-center justify-start text-black hover:cursor-pointer hover:bg-light-white w-full">
                                <div class="flex items-center justify-start mb-4 ">
                                    <div class="relative w-[61px] h-[61px] mr-4">
                                        <img class="rounded-xl h-full w-full object-cover" :src="mentor.photo" @error="replaceGeneralThumbnail" alt="mentor photo" />
                                    </div>
                                    <div class="text-14 font-medium">{{ mentor.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="flex items-start mx-20">
                <div class="flex w-full justify-center items-center">
                    <div class="flex">
                        <router-link class="mx-4" to="/">Masterclass</router-link>
                    </div>
                    <div class="flex">
                        <router-link class="mx-4" to="/">Workplace Learning</router-link>
                    </div>
                    <div class="flex">
                        <router-link class="mx-4" to="/">Campus Learning</router-link>
                    </div>
                    <div class="flex">
                        <router-link class="mx-4" to="/">Job Connector</router-link>
                    </div>
                </div>
            </div>

            <!-- Right elements -->
            <div class="flex items-center relative">
                <button v-if="isLoggedIn"
                    class="navbar-toggler text-gray-500 border-0 hover:shadow-none hover:no-underline py-2 px-2.5 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none focus:no-underline"
                    type="button" aria-expanded="false" aria-label="Toggle navigation" data-bs-toggle="offcanvas"
                    data-bs-target="#headerOffcanvas" aria-controls="headerOffcanvas">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" class="w-6" role="img"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path fill="currentColor"
                            d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
                    </svg>
                </button>
                <div v-if="isLoggedIn" class="collapse navbar-collapse items-center" id="navbarSupportedContent">
                    <div class="flex">
                        <!-- temporary hidden -->
                        <!-- <img src="../../../public/assets/img/icons/notification.png" alt="notification"
                            class="h-6 w-6 my-auto mx-3" /> -->

                        <!-- Dropdown -->
                        <div class="flex justify-center border-l-2">
                            <div>
                                <div class="dropdown relative">
                                    <button
                                        class="dropdown-toggle px-6 py-2.5 font-medium text-xs leading-tight rounded transition duration-150 ease-in-out flex items-center whitespace-nowrap"
                                        type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        {{ profile.name }}
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                            data-icon="caret-down" class="w-2 ml-2" role="img"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                            <path fill="currentColor"
                                                d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" />
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu min-w-max absolute hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 right-0 bg-clip-padding border-none"
                                        aria-labelledby="dropdownMenuButton1" style="width: 289px;">
                                        <li @click="logout">
                                            <a class="dropdown-item text-16 py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-red-500 hover:bg-gray-100 hover:text-red"
                                                href="#">Keluar</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <img :src="profile.photo" @click="viewProfilePage" alt="mentor_2"
                            class="w-8 h-8 rounded-full hover:cursor-pointer" />
                    </div>
                </div>

                <div v-else class="flex justify-center items-center">
                    <router-link to="/login">
                        <button
                            class="btn rounded font-semibold bg-white text-primary border border-primary">Masuk</button>
                    </router-link>
                    <router-link to="/register">
                        <button
                            class="btn rounded font-semibold bg-primary text-white border border-primary ml-4">Daftar</button>
                    </router-link>
                </div>

                <!-- offcanvas -->
                <div class="offcanvas offcanvas-end fixed laptop:hidden bottom-0 flex flex-col max-w-screen bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 right-0 border-none w-full"
                    tabindex="-1" id="headerOffcanvas" aria-labelledby="headerOffcanvasLabel">
                    <div class="offcanvas-header flex items-center justify-end ">
                        <span data-bs-dismiss="offcanvas" class="px-5 py-4">
                            <i class="fa-solid fa-xmark fa-2xl"></i>
                        </span>
                    </div>
                    <div class="offcanvas-body flex-grow p-4 overflow-y-auto">
                        <div class="flex items-center justify-start mb-4">
                            <img :src="profile.photo" alt="mentor_2" class="w-14 h-14 rounded-full mr-4" />
                            <p class="font-bold text-16">{{ profile.name }}</p>
                        </div>
                        <ul class="relative fitur-educazone">
                            <li class="relative" data-bs-dismiss="offcanvas">
                                <router-link to="/"
                                    class="flex items-center text-16 py-5 px-4 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out"
                                    href="#!" active-class="text-primary">
                                    <img src="../../../public/assets/img/icons/fitur_educazone/belajar-mandiri.png"
                                        alt="belajar-mandiri" class="mr-4 ">
                                    <span>Belajar Mandiri</span>
                                </router-link>
                            </li>
                            <!-- temporary hidden -->
                            <!-- <li class="relative" data-bs-dismiss="offcanvas">
                                <router-link to="/online-class"
                                    class="flex items-center text-16 py-5 px-4 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out"
                                    href="#!" active-class="text-primary">
                                    <img src="../../../public/assets/img/icons/fitur_educazone/kelas-online.png"
                                        alt="kelas-online" class="mr-4 ">
                                    <span>Kelas Online</span>
                                </router-link>
                            </li> -->
                            <li class="relative" data-bs-dismiss="offcanvas">
                                <router-link to="/mentor"
                                    class="flex items-center text-16 py-5 px-4 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out"
                                    href="#!" active-class="text-primary">
                                    <img src="../../../public/assets/img/icons/fitur_educazone/mentor.png" alt="mentor"
                                        class="mr-4 ">
                                    <span>Tanya Mentor</span>
                                </router-link>
                            </li>
                            <li class="relative" data-bs-dismiss="offcanvas">
                                <router-link to="/ngajar-asik"
                                    class="flex items-center text-16 py-5 px-4 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out"
                                    href="#!" active-class="text-primary">
                                        <img src="../../../public/assets/img/icons/fitur_educazone/ngajar-asik.png" alt="ngajar-asik" class="mr-4 ">
                                    <span>Ngajar Asik</span>
                                </router-link>
                            </li>
                            <!-- temporary hidden -->
                            <!-- <li class="relative" data-bs-dismiss="offcanvas">
                                <router-link to="/ngajar-asik" class="flex items-center text-16 py-5 px-4 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out" href="#!" active-class="text-primary">
                                    <img src="../../../public/assets/img/icons/fitur_educazone/ngajar-asik.png" alt="ngajar-asik" class="mr-4 ">
                                    <span>Ngajar Asik</span>
                                </router-link>
                            </li> -->

                            <hr class="my-6 ">

                            <li class="relative" data-bs-dismiss="offcanvas">
                                <router-link to="/about-us"
                                    class="flex items-center text-16 py-5 px-4 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out"
                                    href="#!" active-class="text-primary">
                                    <img src="../../../public/assets/img/icons/header_tentang_kami.svg"
                                        alt="belajar-mandiri" class="mr-4 ">
                                    <span>Tentang Kami</span>
                                </router-link>
                            </li>
                            <li class="relative" data-bs-dismiss="offcanvas">
                                <router-link to="/kebijakan-privasi"
                                    class="flex items-center text-16 py-5 px-4 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out"
                                    href="#!" active-class="text-primary">
                                    <img src="../../../public/assets/img/icons/header_kebijakan_privasi.svg"
                                        alt="kelas-online" class="mr-4 ">
                                    <span>Kebijakan Privasi</span>
                                </router-link>
                            </li>
                            <li class="relative" data-bs-dismiss="offcanvas">
                                <div to="" @click="downloadApp"
                                    class="flex items-center text-16 py-5 px-4 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out"
                                    href="#!" active-class="text-primary">
                                    <img src="../../../public/assets/img/icons/header_unduh_aplikasi.svg" alt="mentor"
                                        class="mr-4 ">
                                    <span>Unduh Aplikasi</span>
                                </div>
                            </li>
                            <li class="relative" data-bs-dismiss="offcanvas">
                                <router-link to="/pusat-bantuan"
                                    class="flex items-center text-16 py-5 px-4 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out"
                                    href="#!" active-class="text-primary">
                                    <img src="../../../public/assets/img/icons/header_pusat_bantuan.svg" alt="mentor"
                                        class="mr-4 ">
                                    <span>Pusat Bantuan</span>
                                </router-link>
                            </li>
                            <li class="relative" data-bs-dismiss="offcanvas">
                                <div @click="logout"
                                    class="flex items-center text-16 py-5 px-4 overflow-hidden text-red-500 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out"
                                    href="#!" active-class="text-primary">
                                    <img src="../../../public/assets/img/icons/header_logout.svg" alt="mentor"
                                        class="mr-4 ">
                                    <span>Keluar</span>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <!-- Right elements -->
        </div>
    </nav>
</template>

<script>
export default {
    data() {
        return {
            isLoggedIn: this.$store.getters.isLoggedIn,
            is_searching: false,
            profile: this.$store.state.user,
            search: '',

            courses: '',
            online_classes: '',
            mentors: '',

            filtered_courses: '',
            filtered_online_classes: '',
            filtered_mentors: '',
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('logout')
            this.$router.push('/login')
        },
        downloadApp() {
            const link = 'https://play.google.com/store/apps/details?id=com.gurumerderkaapp&hl=in&gl=US'
            window.open(`${link}`, '_blank');
        },
        viewProfilePage() {
            this.$router.push('/profile')
        },
        getSearchData() {
            this.is_searching = true
            this.axios.get(`search?keyword=${this.search}`).then(response => {
                this.courses = response.data.data.courses
                this.online_classes = response.data.data.online_classes
                this.mentors = response.data.data.mentors
            }).catch(error => {
                console.log(error.response);
            })
        },
        viewDetailCourse(course_id) {
            this.$router.push(`/belajar-mandiri/${course_id}`).then(() => {
                this.$router.go()
            })
        },
        viewDetailOnlineClass(onlien_class_id) {
            this.$router.push(`/online-class/${onlien_class_id}`).then(() => {
                this.$router.go()
            })
        },
        viewDetailMentor(mentor) {
            this.$router.push(`/mentor/${mentor.user_id}`).then(() => {
                this.$router.go()
            })
        },
        replaceCourseThumbnail(e) {
            e.target.src = require('../../../public/assets/img/icons/default-course.png')
        },
        replaceOnlineClassThumbnail(e) {
            e.target.src = require('../../../public/assets/img/icons/default-online-class.png')
        },
        replaceGeneralThumbnail(e) {
            e.target.src = require('../../../public/assets/img/icons/default-general.png')
        },

    },
    mounted() {
        this.profile = this.$store.state.user;
        document.addEventListener('click', (e) => {
            if (e.target.id != 'search-input') {
                this.is_searching = false
            }
        })
    },
    watch: {
        search() {
            if (this.search.length >= 3) {
                this.is_searching = true
                this.getSearchData();
            } else {
                this.is_searching = false
            }
        }
    },
}
</script>

<style scoped>
.btn {
    min-width: 90px;
    height: 40px;
}

ul.fitur-educazone li .router-link-exact-active {
    font-weight: bold;
    background: rgba(49, 63, 110, 0.2);
    color: #313F6E;
}

ul.fitur-educazone li .router-link-exact-active::after {
    content: "";
    margin-right: -0.1rem;
    position: absolute;
    right: -10px;
    width: 5px;
    height: 30px;
    background: #313F6E;
    border-radius: 15px;
}
</style>