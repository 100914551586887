<template>
    <div class="border mb-2">
        <h2 class="accordion-header mb-0" id="headingOne">
            <button class="accordion-button relative flex items-center w-full py-5 px-3 text-base text-gray-800 text-left bg-white border-t rounded-none transition focus:outline-none"
                type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse-${sub_course.id}`"
                aria-expanded="true" :aria-controls="`collapse-${sub_course.id}`">
                <div class="flex justify-start items-center ">
                    <div class="mr-4 font-bold">{{ sub_course.order }}.</div>
                    <div>
                        <p class="font-bold text-16 mb-2">{{ sub_course.name }}</p>
                        <p class="text-12 text-gray-500"><span v-if="sub_course_detail.videos">{{sub_course_detail.videos.length}} Video </span><span v-if="sub_course_detail.modules">| {{getTotalPDF(sub_course_detail.modules)}} PDF | {{getTotalPPT(sub_course_detail.modules)}} Modul</span></p>
                    </div>
                </div>
            </button>
        </h2>

        <!-- Moduls -->
        <div :id="`collapse-${sub_course.id}`" class="accordion-collapse collapse show" aria-labelledby="headingOne">
            <div class="accordion-body py-4 px-5">

                <!-- VIDEO -->
                <div v-for="(video, index) in sub_course_detail.videos" :key="video.id" class="flex justify-between items-center bg-white rounded-lg border text-left py-3 px-5 mb-2"
                :class="isLocked ? 'bg-soft-disabled' : ''">
                    <div class="flex items-center" :class="isCourseAvailable ? 'text-black' : 'text-gray-500'">
                        <div class="text-16 mr-4">{{ index + 1 }}.</div>
                        <div>
                            <p class="text-16 mb-2 capitalize">{{ video.name }}</p>
                            <p class="text-12 text-gray-500">Video</p>
                        </div>
                    </div>
                    <div>
                        <img v-if="isCourseAvailable && !isLocked" :data-bs-toggle="'modal'" :data-bs-target="`#modal-video`" @click="triggerVideo(video.id)" src="../../public/assets/img/icons/icon_play-blue.svg" alt="icon_play" class="cursor-pointer">
                        <img v-else src="../../public/assets/img/icons/icon_play.png" alt="icon_play" class="cursor-default">
                    </div>

                    <div
                        class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                        :id="`modal-video`" tabindex="-1" aria-labelledby="exampleModalCenterTitle"
                        aria-modal="true" role="dialog">
                        <div class="modal-dialog modal-dialog-centered modal-xl relative w-auto pointer-events-none">
                            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                                <div class="modal-body relative p-4">
                                    <video id="my-player" class="video-js vjs-default-skin w-full" controls></video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- MODUL -->
                <div v-for="(modul, index) in sub_course_detail.modules" :key="modul.id">
                    <div class="flex justify-between items-center bg-white rounded-lg border text-left py-3 px-5 mb-2 "
                        :class="isLocked ? 'bg-soft-disabled' : ''">
                        <div class="flex items-center" :class="isCourseAvailable ? 'text-black' : 'text-gray-500'">
                            <div class="text-16 mr-4">{{ sub_course_detail.modules.length + index}}.</div>
                            <div>
                                <p class="text-16 mb-2">{{ modul.name }}</p>
                                <p class="text-12 text-gray-500">Modul <span class="uppercase">{{modul.type}}</span></p>
                            </div>
                        </div>
                        <div>
                            <a v-if="isCourseAvailable && !isLocked" @click="openModule(modul.file)" class="cursor-pointer">
                                <img src="../../public/assets/img/icons/icon_play-blue.svg" alt="icon_play">
                            </a>
                            <a v-else>
                                <img src="../../public/assets/img/icons/icon_play.png" alt="icon_play">
                            </a>
                        </div>
                    </div>
                </div>

                <div v-if="quiz" class="my-6">
                    <p class="text-18 text-black font-bold mb-4">Kuis Pengayaan Materi <span class="capitalize">{{sub_course_detail.name}}</span></p>
                    <button v-if="isCourseAvailable && quiz && !isQuizDone && !isLocked" @click="doSubCourseQuiz(quiz.id)"
                        class="flex justify-between items-center bg-blue-100 rounded-lg border text-left p-5 mb-2 w-full cursor-pointer">
                        <p class="text-18 font-bold text-black">Mulai Kerjakan Kuis</p>
                    </button>
                    <button v-else-if="isCourseAvailable && isQuizDone"
                        class="flex justify-between items-center bg-blue-100 rounded-lg border text-left p-5 mb-2 w-full cursor-default">
                        <p class="text-18 font-bold text-black">Kuis Selesai</p>
                    </button>
                    <button v-else
                        class="flex justify-between items-center bg-soft-disabled rounded-lg border text-left p-5 mb-2 w-full cursor-default">
                        <p class="text-18 font-bold text-gray-500">Mulai Kerjakan Kuis</p>
                        <img src="../../public/assets/img/icons/lock-dark.png" alt="lock-dark">
                    </button>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import videojs from '../../public/assets/vendor/videojs/video.js'
export default {
    props: {
        sub_course: {
            type: Object,
            required: true
        },
        isCourseAvailable: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            sub_course_detail: {},
            resolutions: [],
            list_resolutions: [],
            videojs: '',
            detail_video: '',
            quiz: '',
        }
    },
    methods: {
        getSubCourseDetail() {
            this.axios.get(`course/sub-course?id=${this.sub_course.id}`).then(response => {
                this.sub_course_detail = response.data.data
                this.quiz = this.sub_course_detail.quiz
            }).catch(error => {
                console.log(error.response);
            })
        },
        doSubCourseQuiz(quiz_id) {
            this.$router.push({ 
                name: "Do Quiz", 
                params: { 
                    quiz_id, 
                    quiz_type: 'sub_course' 
                } 
            });
        },
        triggerVideo(video_id) {
            if (!this.detail_video) {
                this.axios.get(`course/sub-course/video?id=${video_id}`).then(response => {
                    this.detail_video = response.data.data;
                    this.list_resolutions = response.data.data.video_resolutions;
                    if (!this.videojs) {
                        this.videojs = videojs("my-player", {
                            controls: true,
                            plugins: {
                                videoJsResolutionSwitcher: {
                                    default: "high",
                                    dynamicLabel: true
                                }
                            }
                        });
                    }
                    this.setupResolution(this.list_resolutions);
                }).catch(error => {
                    console.log(error);
                });
            }
        },
        setupResolution(list_resolutions) {
            var player = this.videojs;
            list_resolutions.forEach(resolution => {
                this.resolutions.push({
                    src: resolution.file_path,
                    type: "video/mp4",
                    label: resolution.resolution
                });
            });
            player.updateSrc(this.resolutions);
            player.on("resolutionchange", function () {
                console.info("Source changed to %s", player.src());
            });
            $('#modal-video').on('hide.bs.modal', function (e) {
                player.pause();
            })
        },
        getTotalPDF(modules) {
            var total_pdf = 0
            modules.forEach(module => {
                if (module.type == "pdf") {
                    total_pdf += 1;
                }
            });
            return total_pdf;
        },
        getTotalPPT(modules) {
            var total_ppt = 0;
            modules.forEach(module => {
                if (module.type == "ppt" || module.type == "pptx") {
                    total_ppt += 1;
                }
            });
            return total_ppt;
        },
        openModule(module_url) {
            this.$store.commit('setModuleUrl', module_url);
            this.$router.push({name: "View Course Module"});
        },
    },
    mounted() {
        this.getSubCourseDetail()
    },
    computed: {
        isSeen() {
            return this.sub_course.status != 'not seen'
        },
        isLocked() {
            return this.sub_course.status == 'lock'
        },
        isPublished() {
            return this.sub_course.is_publish
        },
        isQuizDone() {
            return this.sub_course_detail.is_quiz_done
        },
    },
}
</script>

<style>

</style>