<template>
    <div>
        <div class="bg-white border rounded-lg text-center mx-auto py-4 mb-8" style="width:600px">
            <div class="w-full mb-3 px-20">
                <progress class="w-full" :value="parseInt(question_index)+1" :max="questions.length"></progress>
            </div>
            <p class="text-primary text-16 mb-6">Soal <span class="font-bold">{{parseInt(question_index)+1}}</span> / {{questions.length}}</p>
            <p class="text-16 font-bold px-10 mb-10">{{question.question}}</p>
        </div>

        <div class="flex flex-col items-center justify-start text-left w-full px-12 mx-auto" style="width:600px">
            <div class="options_section flex flex-col items-center justify-start text-left mb-8 w-full px-12 mx-auto">
                <label for="answer-a" class="answer-field mb-2 w-full">
                    <input v-model="answer" type="radio" :value="question.option_a" id="answer-a" class="hidden form-check-input"/>
                    <span class="rounded-lg border border-gray-300 bg-white form-check-label inline-block text-gray-800 font-bold w-full py-4 mb-2 px-4 cursor-pointer">{{question.option_a ? question.option_a : '-'}}</span>
                </label>
                <label for="answer-b" class="answer-field mb-2 w-full">
                    <input v-model="answer" type="radio" :value="question.option_b" id="answer-b" class="hidden form-check-input"/>
                    <span class="rounded-lg border border-gray-300 bg-white form-check-label inline-block text-gray-800 font-bold w-full py-4 mb-2 px-4 cursor-pointer">{{question.option_b ? question.option_b : '-'}}</span>
                </label>
                <label for="answer-c" class="answer-field mb-2 w-full">
                    <input v-model="answer" type="radio" :value="question.option_c" id="answer-c" class="hidden form-check-input"/>
                    <span class="rounded-lg border border-gray-300 bg-white form-check-label inline-block text-gray-800 font-bold w-full py-4 mb-2 px-4 cursor-pointer">{{question.option_c ? question.option_c : '-'}}</span>
                </label>
                <label for="answer-d" class="answer-field mb-2 w-full">
                    <input v-model="answer" type="radio" :value="question.option_d" id="answer-d" class="hidden form-check-input"/>
                    <span class="rounded-lg border border-gray-300 bg-white form-check-label inline-block text-gray-800 font-bold w-full py-4 mb-2 px-4 cursor-pointer">{{question.option_d ? question.option_d : '-'}}</span>
                </label>
                <label for="answer-e" class="answer-field mb-2 w-full">
                    <input v-model="answer" type="radio" :value="question.option_e" id="answer-e" class="hidden form-check-input"/>
                    <span class="rounded-lg border border-gray-300 bg-white form-check-label inline-block text-gray-800 font-bold w-full py-4 mb-2 px-4 cursor-pointer">{{question.option_e ? question.option_e : '-'}}</span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['question_id', 'question_index'],
    data() {
        return {
            answer: '',
            quiz_id: this.$route.params.quiz_id,
            questions: [],
            question: {},
            current_question: 1,
            // max_question: questions.length
        }
    },
    watch: {
        question_id() {
            this.getQuestions()
        }
    },
    methods: {
        getQuestions() {
            console.log('getQuestion');
            this.axios.get(`quiz/list-questions?id=${this.quiz_id}`).then(response => {
                this.questions = response.data.data;
                this.question =  this.questions.filter(question => question.id == this.question_id)[0];
            }).catch(error => {
                console.log(error.response);
            })
        },
    },
    created() {
        this.getQuestions()
    }
}
</script>

<style scoped>
progress[value] {
    /* width: 100%; */
    height: 12px;
}

progress[value]::-webkit-progress-bar {
    border-radius: 10px;
    background: #DEDEDE;
}

progress[value]::-webkit-progress-value {
    border-radius: 10px;
    background-color: #313F6E;
}

.form-check-input:checked {
    /* background-color: red; */
    display: none;
}

.options_section input:checked + .form-check-label {
  background-color: #1AA0E826;
  border: 1px solid #313F6E;
}
</style>