<template>
    <div class="h-full">
        <div class="bg-white rounded-lg shadow-lg text-left col-span-3 p-8 h-full">
            <!-- Title -->
            <div class="text-left mb-8 ">
                <p class="text-24 font-bold">Daftar Transaksi </p>
            </div>
            <!-- Tab Content -->
            <ul class="nav nav-tabs grid grid-cols-2 laptop:flex flex-row list-none border-b pl-0 mb-8 " id="tabs-tab"
                role="tablist">
                <li class="col-span-1 nav-item" role="presentation">
                    <a href="#list-transaksi" class="
                        nav-link
                        selected:text-primary
                        block
                        text-center
                        font-semibold
                        text-14
                        laptop:text-18
                        leading-tight
                        border-x-0 border-t-0 border-b-2 border-transparent
                        laptop:px-6
                        py-3
                        rounded-t-xl
                        hover:border-transparent
                        focus:border-transparent
                        active:font-bold
                        active
                    " id="list-transaksi-tab" data-bs-toggle="pill" data-bs-target="#list-transaksi" role="tab"
                        aria-controls="list-transaksi" aria-selected="true" ref="tab_1">List Transaksi</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a href="#menunggu-pembayaran" class="
                        nav-link
                        block
                        font-medium
                        text-center
                        text-14
                        laptop:text-18
                        leading-tight
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-0
                        laptop:px-6
                        py-3
                        rounded-t-xl
                        hover:border-transparent
                        focus:border-transparent
                    " id="menunggu-pembayaran-tab" data-bs-toggle="pill" data-bs-target="#menunggu-pembayaran"
                        role="tab" aria-controls="menunggu-pembayaran" aria-selected="false" ref="tab_2">Menunggu
                        Pembayaran</a>
                </li>
            </ul>

            <div class="tab-content overflow-auto" id="tabs-tabContent">
                <!-- List Transaksi -->
                <div class="tab-pane fade show active mb-8" id="list-transaksi" role="tabpanel"
                    aria-labelledby="list-transaksi-tab">
                    <div class="grid grid-cols-1">
                        <!-- No Transaction -->
                        <div v-if="transactions.length == 0" class="mx-auto py-auto text-center align-middle">
                            <div class="mb-8">
                                <img src="../../../../public/assets/img/empty-state.png" alt="empty-state" class="mx-auto">
                                <p class="font-bold text-3xl mb-2 text-center">
                                    Belum Ada Pembayaran
                                </p>
                                <p class="text-16">Anda belum memulai transaksi. <br>
                                    Yuk, mulai transaksi sekarang
                                </p>
                            </div>
                            <button @click="redirectToListCourse"
                                class="bg-primary text-16 font-semibold text-center text-white w-full py-4"
                                data-mdb-ripple="true" data-mdb-ripple-color="blue">Beli Kelas</button>
                        </div>

                        <!-- Has Transaction -->
                        <div v-else class="bg-white rounded w-full laptop:p-0">
                            <!-- Transaction List Cards -->
                            <TransactionCard v-for="transaction in transactions" :key="transaction.id"
                                :transaction="transaction" />
                        </div>
                    </div>
                </div>

                <!-- Menunggu Pembayaran -->
                <div class="tab-pane fade show mb-8" id="menunggu-pembayaran" role="tabpanel"
                    aria-labelledby="menunggu-pembayaran-tab">
                    <div class="flex">
                        <!-- No waiting payment -->
                        <div v-if="waiting_payments.length == 0" class="mx-auto text-center align-middle">
                            <div class="mb-8">
                                <img src="../../../../public/assets/img/empty-state.png" alt="empty-state" class="mx-auto">
                                <p class="font-bold text-3xl mb-2 text-center">
                                    Belum Ada Pembayaran
                                </p>
                                <p class="text-16">Anda belum memulai transaksi. <br>
                                    Yuk, mulai transaksi sekarang
                                </p>
                            </div>
                            <button @click="redirectToListCourse"
                                class="bg-primary text-16 font-semibold text-center text-white w-full py-4"
                                data-mdb-ripple="true" data-mdb-ripple-color="blue">Beli Kelas</button>
                        </div>

                        <!-- Has waiting payment -->
                        <div v-else class="bg-white rounded w-full laptop:p-0">
                            <!-- Waiting Transaction List Cards -->
                            <TransactionCard v-for="transaction in waiting_payments" :key="transaction.id"
                                :transaction="transaction" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import TransactionCard from './TransactionCard.vue'
export default {
    components: {
        TransactionCard
    },
    data() {
        return {
            waiting_payments: [],
            transactions: [],
            page: 1,
            limit: 10,
        }
    },
    methods: {
        getTransactions() {
            this.axios.get('transaction').then(response => {
                var res = response.data.data
                if (res) {
                    this.transactions = res.filter(transaction => {
                        return transaction.payment_status != 'waiting'
                    })
                    this.waiting_payments = res.filter(transaction => {
                        return transaction.payment_status == 'waiting'
                    })
                }
            }).catch(error => {
                console.log(error.response);
            })
        },
        redirectToListCourse() {
            this.$router.push({ name: 'Home' })
        }
    },
    mounted() {
        this.getTransactions()
    }
}
</script>


<style scoped>
ul li a.active {
    font-weight: bold;
    color: #313F6E !important;
    border-bottom: 5px solid #313F6E !important;
}

.select-mentor {
    text-overflow: ellipsis;
}

.select-mentor.router-link-exact-active {
    font-weight: bold;
    color: #313F6E;
    background: rgba(49, 63, 110, 0.2);
}

.select-mentor.router-link-exact-active::after {
    display: none;
    content: "";
    margin-right: -0.1rem;
    position: absolute;
    right: -10px;
    width: 5px;
    height: 30px;
    background: #313F6E;
    border-radius: 15px;
}</style>