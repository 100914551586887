<template>
    <div>
        <!-- Before Login -->
        <main v-if="!isLoggedIn" class="mt-10">
            <!-- BANNER SECTION-->
            <div class="flex items-center justify-between text-center laptop:text-left mx-10 laptop:mx-20 mb-20">
                <div>
                    <p class="font-bold text-40 mb-4 ">Langkah Mudah Bangun Karir Profesional Kamu</p>
                    <p class="font-normal text-18 mb-9">Tingkatkan kemampuan profesional kamu secara mudah dan di mana
                        saja dengan modul dan mentor terhandal</p>
                    <button @click="mulaiBelajar" class="bg-primary text-white font-semibold px-20 py-3 rounded">Mulai Belajar Sekarang</button>
                </div>
                <div>
                    <img src="../../../public/assets/img/banner-img.png" alt="banner-img">
                </div>
            </div>

            <!-- SERVICE -->
            <div class="bg-primary text-left text-white px-20 py-14 w-full">
                <div class="flex items-center justify-between">
                    <p class="text-32 font-bold">Temukan solusi belajar yang berkelanjutan di sini!</p>
                    <div class="flex">
                        <button @click="previousService">
                            <img src="../../../public/assets/img/icons/carousel-arrow-left-white.png"
                                alt="carousel-arrow-left-white" class="mx-2">
                        </button>
                        <button @click="nextService">
                            <img src="../../../public/assets/img/icons/carousel-arrow-right-white.png"
                                alt="carousel-arrow-right-white" class="mx-2">
                        </button>
                    </div>
                </div>
                <p class="text-14 mb-10">Pelajari kemampuan profesinal kamu secara mudah dan di mana saja dengan modul
                    dan mentor andalan</p>

                <!-- card -->
                <Splide class="splide" ref="ServiceSplide" :options="splide_options" aria-label="Educazone Services">
                    <SplideSlide v-for="(service, index) in services" :key="index">
                        <Card :title="service.title" :desc="service.desc" :image="service.image" />
                    </SplideSlide>
                </Splide>

            </div>

            <!-- KEUNGGULAN EDUCAZONE -->
            <div class="bg-white text-left py-20">
                <div class="grid grid-cols-1 text-center laptop:grid-cols-2">
                    <div class="relative laptop:text-left laptop:px-28 px-5 mb-10">
                        <p class="text-primary text-32 font-bold mb-10">Mengapa IKALSMART?</p>
                        <p class="text-18 laptop:text-16 laptop:w-[470px]">
                            <b>IKALSMART</b> adalah platform lebih dari sekedar platform pembelajaran, namun memiliki
                            visi untuk menjadi platform pembelajaran yang lebih berkelanjutan.
                            <br><br>
                            Sebagai platform yang mendukung sustainable learning di Indonesia, <b>IKALSMART</b>
                            menyediakan berbagai produk seperti pembelajaran untuk pengembangan kemampuan profesi,
                            kampus digital, pengembangan karir karyawan, penghubung antara pencari kerja dengan
                            perusahaan ternama di Indonesia, dan layanan penyewaan studio profesional.
                        </p>
                    </div>
                    <div class="laptop:pr-40 text-center laptop:text-left">
                        <p class="text-primary text-32 font-bold mb-10">Nilai lebih belajar di IKALSMART</p>
                        <div class="flex flex-col laptop:grid grid-rows-3 gap-4 text-left">
                            <div class="flex items-start justify-start mb-9">
                                <img src="../../../public/assets/img/icons/pembelajaran-hybrid.png"
                                    alt="pembelajaran-hybrid" class="mr-6 ">
                                <div class="flex flex-col">
                                    <p class="text-24 font-bold mb-4">Pembelajaran Hybrid</p>
                                    <p class="text-14">Media belajar berbentuk video berkualitas tinggi. Terdapat juga
                                        kelas pembelajaran bersama Mentor secara langsung via online</p>
                                </div>
                            </div>
                            <div class="flex items-start justify-start mb-9">
                                <img src="../../../public/assets/img/icons/materi-pembelajaran-efektif.png"
                                    alt="materi-pembelajaran-efektif" class="mr-6 ">
                                <div class="flex flex-col">
                                    <p class="text-24 font-bold mb-4">Materi Pembelajaran Efektif</p>
                                    <p class="text-14">Materi pelatihan yang sistematis yang dilengkapi dengan contoh
                                        aplikasi praktis. Materi telah disesuaikan dengan kebutuhan sertifikasi
                                        kompetensi di Indonesia.</p>
                                </div>
                            </div>
                            <div class="flex items-start justify-start mb-9">
                                <img src="../../../public/assets/img/icons/waktu-pembelajaran-efisien.png"
                                    alt="waktu-pembelajaran-efisien" class="mr-6 ">
                                <div class="flex flex-col">
                                    <p class="text-24 font-bold mb-4">Waktu Pembelajaran yang Efisien</p>
                                    <p class="text-14">Waktu dan tempat pelatihan yang fleksibel dengan biaya
                                        terjangkau.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- EDUCAZONE BLOG -->
            <div class="text-left p-20 bg-[#F1F6FE]">
                <div class="flex items-center justify-between">
                    <p class="text-32 font-bold mb-4">IKALSMART blog</p>
                    <div class="flex">
                        <button @click="previousBlog">
                            <img src="../../../public/assets/img/icons/carousel-arrow-left-black.png"
                                alt="carousel-arrow-left-black" class="mx-2 ">
                        </button>
                        <button @click="nextBlog">
                            <img src="../../../public/assets/img/icons/carousel-arrow-right-black.png"
                                alt="carousel-arrow-right-black" class="mx-2 ">
                        </button>
                    </div>
                </div>
                <p class="text-14 mb-10">Simak Berita, Tips, dan Trik Seputar Pendidikan di IKALSMART blog</p>

                <!-- card -->
                <Splide class="splide" ref="BlogSplide" :options="splide_options" aria-label="Educazone Services">
                    <SplideSlide v-for="(blog, index) in blogs" :key="index">
                        <Card :title="blog.title" :desc="blog.desc" :image="blog.image" />
                    </SplideSlide>
                </Splide>
            </div>
        </main>

        <!-- After Login -->
        <main v-else class="h-full mt-10">
            <div class="bg-white rounded-lg shadow-lg text-left col-span-3 pt-0 laptop:pt-8 p-8 min-h-screen">
                <!-- Title -->
                <div class="text-left mb-8 ">
                    <p class="text-24 font-bold">Pilihan Belajar Mandiri </p>
                    <p class="text-14 laptop:text-16">Tingkatkan kemampuan Anda dengan belajar secara mandiri di
                        IKALSMART</p>
                </div>

                <!-- Tab Content - Temporary hidden-->
                <!-- <ul class="hidden nav nav-tabs laptop:flex flex-col laptop:flex-row flex-wrap list-none border-b pl-0 mb-8 " id="tabs-tab"
                    role="tablist">
                    <li class="nav-item" role="presentation">
                        <a href="#ragam-materi-belajar" class="
                nav-link
                block
                font-medium
                text-18
                leading-tight
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
                active
              " id="ragam-materi-belajar-tab" data-bs-toggle="pill" data-bs-target="#ragam-materi-belajar" role="tab"
                            aria-controls="ragam-materi-belajar" aria-selected="true" data-mdb-ripple="true"
                            data-mdb-ripple-color="gray">Pilihan Materi Belajar</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a href="#materi-yang-dipilih" class="
                nav-link
                block
                font-medium
                text-18
                leading-tight
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
              " id="materi-yang-dipilih-tab" data-bs-toggle="pill" data-bs-target="#materi-yang-dipilih" role="tab"
                            aria-controls="materi-yang-dipilih" aria-selected="false" data-mdb-ripple="true"
                            data-mdb-ripple-color="gray">Materi Yang Anda Pilih</a>
                    </li>
                </ul> -->

                <div class="tab-content overflow-auto" id="tabs-tabContent">
                    <!-- Class Level Section -->
                    <!-- temporary hidden -->
                    <!-- <div
                        class="category-section grid grid-cols-1 laptop:flex items-center justify-start mb-4 laptop:mb-8 ">
                        <p class="font-bold mr-4 col-span-1 mb-4 laptop:mb-0">Level Kelas</p>
                        <div
                            class="flex max-w-screen-tablet laptop:max-w-screen-laptop overflow-auto laptop:overflow-visible p-0">
                            <label for="all_level" class="mr-4">
                                <input v-model="level" type="radio" value="all_level" id="all_level"
                                    class="hidden form-check-input" />
                                <span
                                    class="flex rounded-full border border-primary bg-white form-check-label text-primary font-bold w-full py-2 px-4 cursor-pointer min-w-max">
                                    Semua Level Pembelajaran
                                </span>
                            </label>
                            <label for="pemula" class="mr-4">
                                <input v-model="level" type="radio" value="pemula" id="pemula"
                                    class="hidden form-check-input" />
                                <span
                                    class="flex rounded-full border border-primary bg-white form-check-label text-primary font-bold w-full py-2 px-4 cursor-pointer min-w-max">
                                    Guru Pembelajar
                                </span>
                            </label>
                            <label for="ahli" class="mr-4">
                                <input v-model="level" type="radio" value="ahli" id="ahli"
                                    class="hidden form-check-input" />
                                <span
                                    class="flex rounded-full border border-primary bg-white form-check-label text-primary font-bold w-full py-2 px-4 cursor-pointer min-w-max">
                                    Guru Ahli
                                </span>
                            </label>
                            <label for="master" class="mr-4">
                                <input v-model="level" type="radio" value="master" id="master"
                                    class="hidden form-check-input" />
                                <span
                                    class="flex rounded-full border border-primary bg-white form-check-label text-primary font-bold w-full py-2 px-4 cursor-pointer min-w-max">
                                    Guru Master
                                </span>
                            </label>
                        </div>
                    </div> -->

                    <!-- Pilihan Materi Belajar -->
                    <div class="tab-pane fade show active mb-8" id="ragam-materi-belajar" role="tabpanel"
                        aria-labelledby="ragam-materi-belajar-tab">
                        <div
                            class="flex flex-col items-center justify-center laptop:items-start laptop:grid grid-cols-3 gap-10">
                            <!-- card -->
                            <div @click="selectCourse(belajar_mandiri.id)" v-for="belajar_mandiri in filtered_courses"
                                :key="belajar_mandiri.id"
                                class="bg-white rounded-lg shadow-lg cursor-pointer max-w-screen-tablet h-full">
                                <img :src="belajar_mandiri.thumbnail" alt="" class="rounded-t-lg w-full"
                                    style="height:135px">
                                <div class="text-left p-5 ">
                                    <div class="flex justify-start items-center mb-5">
                                        <p class="text-16 font-bold text-primary">{{this.$filters.toCurrency(belajar_mandiri.token_price)}}</p>
                                    </div>
                                    <p class="font-bold text-18 mb-4 ">{{ belajar_mandiri.name }}</p>
                                    <div class="flex items-center justify-between">
                                        <div class="flex items-center">
                                            <img src="../../../public/assets/img/icons/icon_play_small.png"
                                                alt="icon_play_small" class="h-5 w-5 flex-none">
                                            <p class="ml-1 text-gray text-12">{{ belajar_mandiri.total_sub_course }}
                                                Video
                                                Pembelajaran</p>
                                        </div>
                                        <div class="flex items-center">
                                            <img src="../../../public/assets/img/icons/icon_time_small.png"
                                                alt="icon_time_small" class="flex-none">
                                            <p class="ml-1 text-gray text-12">
                                                {{ $filters.getCourseDuration(belajar_mandiri.total_duration) }}</p>
                                        </div>
                                    </div>
                                    <div class="flex justify-start items-center my-5 ">
                                        <img :src="belajar_mandiri.mentor ? belajar_mandiri.mentor.photo : ''"
                                            alt="mentor_photo" class="rounded-full mr-4 h-10 w-10">
                                        <p class="font-bold text-16 capitalize">{{ belajar_mandiri.mentor ?
                                        belajar_mandiri.mentor.name : '-'
                                        }}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- Materi Yang Anda Pilih -->
                    <div class="tab-pane fade show mb-8" id="materi-yang-dipilih" role="tabpanel"
                        aria-labelledby="materi-yang-dipilih-tab">
                        <div class="flex">
                            <div class="mx-auto text-center align-middle">
                                <div class="mb-16 ">
                                    <img src="../../../public/assets/img/empty-state-rounded.png" alt="empty-state-rounded"
                                        class="mx-auto">
                                    <p class="font-bold text-3xl mb-2">Belum Ada Materi</p>
                                    <p class="text-16">Anda belum mempunyai Materi Belajar Mandiri</p>
                                </div>
                                <button @click="toggleChooseCourseTab"
                                    class="bg-primary text-16 font-semibold text-center text-white w-full py-4"
                                    data-mdb-ripple="true" data-mdb-ripple-color="blue">Pilih Materi</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    </div>
</template>

<script>
import Card from './CardComponent.vue'
export default {
    components: { Card },
    data() {
        return {
            isLoggedIn: this.$store.getters.isLoggedIn,
            page: 1,
            limit: 10,
            top_courses: [],
            filtered_courses: [],
            courses: [],
            top_online_class: [],
            level: 'all_level',
            label_colors: {
                'Belum Dimulai': 'bg-gray-400',
                'Sedang Berlangsung': 'bg-warning',
                'Selesai': "bg-success"
            },
            splide_options: {
                type: 'loop',
                perPage: 4,
                perMove: 1,
                arrows: false,
                pagination: false
                // gap   : '1rem',
            },
            ServiceSplide: null,
            BlogSplide: null,
            services: [
                {
                    title: 'IKALSMART Masterclass',
                    desc: 'Belajar mengembangan segala kemampuan potensial dari para seniman, pemimpin, dan ikon paling inspiratif di Indonesia',
                    image: 'services/service_1.png'
                },
                {
                    title: 'IKALSMART Workplace',
                    desc: 'Mengembangkan kapabilitas karyawan  dengan kursus terbaik dengan topik yang mereka butuhkan agar terus berkembang',
                    image: 'services/service_2.png'
                },
                {
                    title: 'IKALSMART Campus',
                    desc: 'Program yang memperluas koneksi pekerjaan untuk meraih karir di perusahaan terpercaya baik di Indonesia dan luar negeri',
                    image: 'services/service_3.png'
                },
                {
                    title: 'Job Connector',
                    desc: 'Program yang memperluas koneksi pekerjaan untuk meraih karir di perusahaan terpercaya baik di Indonesia dan luar negeri',
                    image: 'services/service_4.png'
                },
                {
                    title: 'IKALSMART Studio',
                    desc: 'Solusi bagi kamu yang ingin memiliki kualitas produksi video terbaik secara mudah dan terjangkau',
                    image: 'services/service_5.png'
                },
            ],
            blogs: [
                {
                    title: 'Tryout UTBK Gratis: Yuk, Pemanasan UTBK 2023!',
                    desc: 'Ngomongin tentang merdeka, saat ini IKALSMART sedang mengadakan Festival Merdeka Belajar, lho! Di Festival Merdeka Belajar, IKALSMART ingin ...',
                    image: 'blogs/blog_1.png',
                },
                {
                    title: 'Harga terbaru layanan pembelajaran IKALSMART!',
                    desc: 'Ngomongin tentang merdeka, saat ini IKALSMART sedang mengadakan Festival Merdeka Belajar, lho! Di Festival Merdeka Belajar, IKALSMART ingin ...',
                    image: 'blogs/blog_1.png',
                },
                {
                    title: 'Tryout UTBK Gratis: Yuk, Pemanasan UTBK 2023!',
                    desc: 'Ngomongin tentang merdeka, saat ini IKALSMART sedang mengadakan Festival Merdeka Belajar, lho! Di Festival Merdeka Belajar, IKALSMART ingin ...',
                    image: 'blogs/blog_1.png',
                },
                {
                    title: 'Tryout UTBK Gratis: Yuk, Pemanasan UTBK 2023!',
                    desc: 'Ngomongin tentang merdeka, saat ini IKALSMART sedang mengadakan Festival Merdeka Belajar, lho! Di Festival Merdeka Belajar, IKALSMART ingin ...',
                    image: 'blogs/blog_1.png',
                },
                {
                    title: 'Tryout UTBK Gratis: Yuk, Pemanasan UTBK 2023!',
                    desc: 'Ngomongin tentang merdeka, saat ini IKALSMART sedang mengadakan Festival Merdeka Belajar, lho! Di Festival Merdeka Belajar, IKALSMART ingin ...',
                    image: 'blogs/blog_1.png',
                },
            ]
        }
    },
    methods: {
        getTopCourse() {
            this.axios.get(`course/top-4`).then(response => {
                this.top_courses = response.data.data
            }).catch(error => {
                console.log(error.response)
            })
        },
        getCourse() {
            this.axios.get(`course/list`).then(response => {
                this.courses = response.data.data
                this.filtered_courses = this.courses
            }).catch(error => {
                console.log(error.response)
            })
        },
        getTopOnlineClass() {
            this.axios.get('online-class/top-4').then(response => {
                this.top_online_class = response.data.data
            }).catch(error => {
                console.log(error.response);
            })
        },
        selectCourse(course_id) {
            this.$router.push({
                name: 'Detail Belajar Mandiri',
                params: {
                    course_id
                }
            })
        },
        online_class_status(status) {
            return this.label_colors[status]
        },
        toggleChooseCourseTab() {
            $('#ragam-materi-belajar-tab').toggleClass('active')
            $('#ragam-materi-belajar').toggleClass('active')
            $('#ragam-materi-belajar').toggleClass('show')

            $('#materi-yang-dipilih-tab').toggleClass('active')
            $('#materi-yang-dipilih').toggleClass('active')
            $('#materi-yang-dipilih').toggleClass('show')
        },
        setActive() {
            var carousel_item = document.getElementsByClassName('carousel-item')
            if (carousel_item.length > 0) {
                carousel_item[0].classList.add('active')
            }
        },
        nextService() {
            this.ServiceSplide.go('>');
        },
        previousService() {
            this.ServiceSplide.go('<');
        },
        nextBlog() {
            this.BlogSplide.go('>');
        },
        previousBlog() {
            this.BlogSplide.go('<');
        },
        mulaiBelajar() {
            if (!this.isLoggedIn) {
                this.$router.push('/login');
            }
        }
    },
    mounted() {
        if (this.isLoggedIn) {
            this.getCourse()
        } else {
            this.getTopCourse()
            this.getTopOnlineClass()
        }
        // this.setActive()
        this.ServiceSplide = this.$refs.ServiceSplide;
        this.BlogSplide = this.$refs.BlogSplide;
        // this.splide.on('move', function (newIndex, oldIndex, destIndex) {
        //     console.log(newIndex, oldIndex, destIndex);
        // });
    },
    watch: {
        level() {
            this.filtered_courses = this.level == 'all_level' ? this.courses : this.courses.filter((course) => course.level == this.level)
        }
    }
}
</script>

<style scoped>
::-webkit-scrollbar-thumb {
    display: none;
}

.welcome-banner {
    background: linear-gradient(90deg, #313F6E 0%, #5B6CA5 24.69%, #596795 70.8%, #293D80 100%);
}

.kelebihan-educazone-section {
    background: rgba(111, 169, 238, 0.1);
}

ul li a.active {
    font-weight: bold;
    color: #313F6E !important;
    border-bottom: 5px solid #313F6E !important;
}

.category-section input:checked+.form-check-label {
    background-color: #313F6E;
    color: white;
    border: 1px solid #313F6E;
}


/* Arrow animation */
@keyframes bounce {

    0%,
    100% {
        transform: translateX(-25%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }

    50% {
        transform: none;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

.animate-bounce {
    animation: bounce 1s infinite;
}
</style>