<template>
    <div class="text-left px-20 mb-20 mt-10" v-if="!isLoggedIn">
        <div class="bg-white rounded shadow-md" style="min-height: 843px;">
            <div class="grid grid-cols-3 bg-gray-100 mb-20" style="min-height: 358px">
                <div class="col-span-2 bg-image relative">
                    <button class="absolute bottom-0 -mb-5 ml-10 flex justify-center items-center text-white bg-gray-400 rounded py-2 px-3"><span class="rounded-full bg-white h-2 w-2 mr-2"></span> BELUM DIMULAI</button>
                </div>
                <div class="flex flex-col justify-between p-10">
                    <div>
                        <div>
                            <p class="font-bold text-3xl">Project Management Framework </p>
                        </div>
                        <div>
                            <p class="font-bold text-18 mt-4">Deka Solomon</p>
                        </div>
                        <div class="flex items-center mt-2">
                            <img src="../../public/assets/img/icons/black-clock.png" alt="black-clock" class="w-4 h-4 flex-none">
                            <p class="ml-1 text-12">19.00 - 20.00 | 23 September 2020</p>
                        </div>
                    </div>
                    <button class="flex justify-center align-middle bg-gray-500 text-white text-14 py-4">Gabung Kelas <img class="ml-1" src="../../public/assets/img/icons/lock.png" alt="lock"></button>
                </div>
            </div>
            <div class="grid grid-cols-5 px-10 mb-8">
                <div class="col-span-3">
                    <p class="text-24 font-bold mb-4">Tentang Kelas</p>
                    <p class="text-16">Service Design is a collaborative process which helps to innovate or improve services to make them more useful, usable, desirable and efficient for customers as well as effective for client and their organisations. It is a holistic, multi-disciplinary, integrative field. - Stefan Moritz.</p>
                </div>
            </div>
            <div class="grid grid-cols-5 px-10 mb-8">
                <div class="col-span-3">
                    <p class="text-24 font-bold mb-7">Petunjuk Mengikuti Kelas</p>
                    <p class="text-16 mb-8">Ikuti petunjuk ini untuk mengikuti kelas:</p>
                    <div class="text-16 leading-6">
                        <p>1. Pastikan aplikasi Zoom Anda aktif</p>
                        <p>2. Klik tombol Gabung Kelas untuk mulai</p>
                        <p>3. Tombol Gabung Kelas akan aktif beberapa saat sebelum kelas dimulai</p>
                        <p>4. Tombol Gabung Kelas akan non aktif saat kelas sudah selesai</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="h-full mt-10">
        <div class="bg-white rounded-lg shadow-lg text-left col-span-3 p-0 laptop:p-8 h-full">
            <!-- laptop:px-20 -->
            <div class="text-left mb-20">
                <div class="bg-white rounded laptop:shadow-md">
                    <div class="grid grid-cols-1 laptop:grid-cols-3 bg-white laptop:bg-gray-100 mb-10 min-h-[271px] shadow-md laptop:shadow-none rounded-xl laptop:rounded-none  px-4 laptop:px-0">
                        <div class="flex mb-4 laptop:hidden">
                            <span @click="backToOnlineClass">
                                <i class="fa-solid fa-angle-left fa-2xl"></i>
                            </span>
                        </div>
                        <div class="col-span-2 relative mb-6 laptop:mb-0">
                            <img :src="online_class_detail.thumbnail" alt="" class="rounded-xl laptop:rounded-none flex-none w-full h-full">
                        </div>
                        <div class="flex flex-col justify-between laptop:p-6">
                            <div>
                                <button class="flex justify-center items-center text-white text-12 font-medium uppercase rounded py-2 px-3 mb-7" :class="online_class_status(online_class_detail.status)">
                                    <span class="rounded-full bg-white h-2 w-2 mr-2"></span> 
                                    {{online_class_detail.status}}
                                </button>
                            </div>
                            <p class="font-bold text-24 leading-7 mb-3">{{online_class_detail.title}} </p>
                            <div class="flex items-center justify-start mb-7 laptop:mb-0">
                                <img src="../../public/assets/img/icons/icon_time_small.png" alt="icon_time_small" class="flex-none mr-1">
                                <p class="text-12 ">{{convertTime(online_class_detail.start_at)}} - {{convertTime(online_class_detail.finish_at)}} | {{convertDate(online_class_detail.finish_at)}}</p>
                            </div>
                            <!-- mentor mobile section -->
                            <div class="laptop:hidden">
                                <div class="flex justify-start items-center mb-6">
                                    <img :src="online_class_detail.mentor_photo" alt="teacher-token" height="40" width="40" class="mr-4">
                                    <div>
                                        <p class="font-bold text-14">{{online_class_detail.mentor_name}}</p>
                                        <p class="text-12">{{online_class_detail.mentor_title}}</p>
                                    </div>
                                </div>
                                <p class="font-bold text-18 text-[#DB3E0B] mb-6">Gratis</p>
                                <div class="flex w-full mb-4">
                                    <button v-if="online_class_detail.status == 'Belum Dimulai'" class="flex items-center justify-center bg-disabled text-center text-white text-14 font-semibold py-3 rounded-lg w-full">Gabung Kelas <img src="../../public/assets/img/icons/lock.png" alt="" class="ml-1 "></button>
                                    <button v-if="online_class_detail.status == 'Sedang Berlangsung'" @click="joinClass(online_class_detail.link)" class="flex items-center justify-center bg-primary text-center text-white text-14 font-semibold py-3 rounded-lg w-full">Gabung Kelas <img src="../../public/assets/img/icons/play-white.svg" alt="" class="ml-1 "></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grid laptop:grid-cols-3 grid-col-1 mb-8 h-full" >
                        <div class="col-span-2 px-4 laptop:px-10 laptop:mb-8">
                            <p class="text-24 font-bold mb-4">Tentang Kelas</p>
                            <p class="text-16 mb-10">{{online_class_detail.about}}</p>

                            <p class="text-24 font-bold mb-7">Petunjuk Mengikuti Kelas</p>
                            <p class="text-16 mb-8">Ikuti petunjuk ini untuk mengikuti kelas:</p>
                            <div class="text-16 leading-6">
                                <p>1. Pastikan aplikasi Zoom Anda aktif</p>
                                <p>2. Klik tombol Gabung Kelas untuk mulai</p>
                                <p>3. Tombol Gabung Kelas akan aktif beberapa saat sebelum kelas dimulai</p>
                                <p>4. Tombol Gabung Kelas akan non aktif saat kelas sudah selesai</p>
                            </div>
                        </div>
                        <div class="hidden laptop:flex flex-col col-span-1">
                            <div v-if="online_class_detail.status != 'Selesai'" class="bg-white rounded-lg border shadow text-left py-5 px-3 ">
                                <div class="flex justify-center items-center mb-4">
                                    <img :src="online_class_detail.mentor_photo" alt="teacher-token" height="40" width="40" class="mr-2">
                                    <p class="font-bold text-16">{{online_class_detail.mentor_name}}</p>
                                </div>
                                <div class="flex">
                                    <button v-if="online_class_detail.status == 'Belum Dimulai'" class="flex items-center justify-center bg-disabled text-center text-white text-14 font-semibold py-3 rounded-lg w-full">Gabung Kelas <img src="../../public/assets/img/icons/lock.png" alt="" class="ml-1 "></button>
                                    <button v-if="online_class_detail.status == 'Sedang Berlangsung'" @click="joinClass(online_class_detail.link)" class="flex items-center justify-center bg-primary text-center text-white text-14 font-semibold py-3 rounded-lg w-full">Gabung Kelas <img src="../../public/assets/img/icons/play-white.svg" alt="" class="ml-1 "></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isLoggedIn: this.$store.getters.isLoggedIn,
            online_class_id: this.$route.params.id,
            online_class_detail: {},
            label_colors: {
                'Belum Dimulai': 'bg-gray-400',
                'Sedang Berlangsung': 'bg-warning',
                'Selesai': "bg-success"
            },
        }
    },
    methods: {
        getOnlineClassDetail() {
            const body = {
                id: this.online_class_id
            }
            this.axios.post('online-class/detail', body).then(response => {
                this.online_class_detail = response.data.data
            }).catch(error => {
                console.log(error.response)
            })
        },
        joinClass(link) {
            window.open(`${link}`, '_blank');
        },
        convertTime(date){
            const time = new Date(date);
            const hours = time.getHours();
            const minutes = time.getMinutes();
            return hours + ':' + minutes;
        },
        convertDate(date){
            const list_month = ["Januari", "Februaryi", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
            const time = new Date(date);
            const day = time.getDate();
            const month = list_month[time.getMonth()];
            const year = time.getFullYear();
            return day + ' ' + month + ' ' + year;
        },
        online_class_status(status) {
            return this.label_colors[status]
        },
        backToOnlineClass() {
            this.$router.push({name: 'List Kelas Online'})
        }
    },
    mounted() {
        this.getRouteParams
    },
    computed: {
        getRouteParams() {
            this.online_class_id = this.$route.params.id;
            this.getOnlineClassDetail()
        },
    }
}
</script>

<style scoped>
    .bg-image {
        background-image: url('../../public/assets/img/kelas-online/kelas-online-5.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
</style>

