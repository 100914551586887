<template>
    <div class="container mx-auto">
        <div class="grid grid-cols-1 laptop:grid-cols-2  min-h-screen">
            <div class="hidden laptop:flex flex-col items-center justify-center ">
                <img class="mx-auto" src="../../public/assets/img/login-banner.png" alt />
                <p class="font-bold mb-2 text-24">
                    Selamat datang di IKALSMART!
                </p>
            </div>
            <div class="flex items-center justify-center">
                <div class="mx-auto">
                    <form novalidate @submit.prevent="login" class="align-left w-full px-10 laptop:w-6/12 laptop:px-0 mx-auto text-left mb-3">
                        <router-link to="/">
                            <img class="my-5 mx-auto" src="../../public/assets/img/logo/main-logo.png" alt="main-logo" />
                        </router-link>
                        <p class="font-bold mb-6 text-24">Masuk</p>
                        <div class="text-left mb-6">
                            <label for="email-field" class="block text-sm font-bold text-slate-700 mb-3">Email</label>
                            <input v-model="email" ref="email-field" id="email-field" type="email" class="input-field w-full py-2" placeholder="example@email.com"/>
                        </div>
                        <div class="text-left mb-8">
                            <label for="password-field" class="block text-sm font-bold text-slate-700 mb-3">Kata Sandi</label>
                            <input @keyup.enter="login" v-model="password" id="password-field" :type="showPassword ? 'text' : 'password'" class="input-field w-full py-2" placeholder="Masukkan kata sandi" required/>
                            <!-- eye icon -->
                            <span class="eye-icon" v-if="!showPassword" @click="showPassword = !showPassword"><i class="fa fa-eye" aria-hidden="true"></i></span>
                            <span class="eye-icon" v-else-if="showPassword" @click="showPassword = !showPassword"><i class="fa fa-eye-slash" aria-hidden="true"></i></span>
                        </div>
                        <p class="text-red-500 text-center mb-4 px-5" v-if="message">{{message}}</p>
                        <button type="submit" class="flex items-center justify-center bg-primary rounded text-center font-bold w-full text-white py-2">
                            <div v-if="processing" class="spinner-border animate-spin inline-block w-5 h-5 border-4 rounded-full text-white mr-2" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div v-else>Masuk</div>
                        </button>
                    </form>

                    <p class="mx-aut font-bold mb-3"><router-link to="/forget-password">Lupa kata sandi?</router-link></p>

                    <div class="text-center mx-auto laptop:w-1/2 my-4">
                        <p class="mx-auto mb-6">Tidak punya Akun? <span class="font-bold text-primary"><router-link to="/register">Daftar</router-link></span> </p>
                        <p>Dengan masuk atau mendaftar, Anda telah menyetujui <router-link to="terms-condition" class="font-bold text-primary">ketentuan layanan</router-link> dan <router-link to="privacy-policy" class="font-bold text-primary">kebijakan kami</router-link>.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Carousel from '@/components/Carousel.vue'
export default {
    components: {
        Carousel
    },
    data() {
        return {
            processing: false,
            showPassword: false,
            email: '',
            password: '',
            message: '',
            login_clicked: false,
        }
    },
    methods: {
        login() {
            if (!this.email || !this.password) {
                this.message = "Email dan Kata Sandi wajib diisi"
            }
            else if (!this.isValidEmail) {
                this.message = "Email tidak valid"
            } else {
                this.login_clicked = true
                this.processing = true
                const user = {
                    email: this.email,
                    password: this.password,
                    system_id: 1 //temporary
                }
                this.$store.dispatch('login', user).then(response => {
                    this.getProfile()
                    this.message = response.message
                    this.processing = false
                    return
                }).catch(error => {
                    this.message = error.response.data.message
                    this.processing = false
                })
            }
        },
        getProfile() {
            this.$store.dispatch('getProfile').then(response => {
                this.$router.push('/')
            }).catch(error => {
                console.log(error.response);
            })
        }
    },
    mounted() {
        this.$refs['email-field'].focus()
    },
    computed: {
        isValidEmail() {
            // check if email is valid
            if (this.email) {
                const email = this.email
                const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                if (!emailRegex.test(email)) {
                    this.message = 'Email tidak valid'
                    return false
                } else {
                    this.message = ''
                    return true
                }
            }
        }
    }
};
</script>

<style scoped>
.input-field {
    border: none;
    border-bottom: 2px solid rgba(37, 40, 43, 0.33);
}
.input-field:focus {
    outline: none;
    border-bottom: 2px solid #313F6E;
}
.eye-icon {
	float: right;
    /* margin-left: -25px; */
    margin-top: -32px;
    margin-right: 5px;
    position: relative;
    z-index: 2;
}
</style>