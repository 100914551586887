<template>
  <div class="hidden laptop:flex flex-col mt-10 ml-12 mr-6 h-full" style="width:380px" v-if="isLoggedIn">
          <!-- Teacher Profile Card -->
    <div class="bg-white rounded-lg shadow-lg mb-5">
      <div class="relative py-8">
        <img src="../../../public/assets/img/bg-teacher-card.png" alt="bg-teacher-card" class="absolute top-0 w-full">
        
        <div @click="viewProfilePage" class="relative w-20 h-20 mx-auto mb-2 hover:cursor-pointer">
          <img class="rounded-full h-full w-full object-cover" :src="profile.photo" alt="user photo"/>
        </div>

        <p class="text-16 font-semibold mb-2 mx-auto">{{profile.name}}</p>
        <!-- <div class="flex justify-center items-center align-middle"> -->
          <!-- <img src="../../../public/assets/img/icons/teacher_level.png" alt="teacher_level" class="mr-2"> -->
          <!-- <span class="text-12" style="line-height:0">{{profile.school_name}}</span> -->
        <!-- </div> -->
      </div>
    </div>

    <!-- Bottom Card -->
    <div class="bg-white rounded-lg shadow-lg text-left py-11 px-6 h-full">
      <!-- Fitur Educazone Card -->
      <p class="uppercase font-bold text-12 text-gray-500 tracking-widest mb-4 px-3">FITUR EDUCAZONE</p>
      <ul class="relative fitur-educazone">
        <li class="relative">
          <router-link to="/" class="flex items-center text-16 py-4 px-3 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out" href="#!" active-class="text-primary">
            <img src="../../../public/assets/img/icons/fitur_educazone/belajar-mandiri.png" alt="belajar-mandiri" class="mr-4 ">
            <span>Belajar Mandiri</span>
          </router-link>
        </li>
        <!-- temporary hidden -->
        <li class="relative">
          <router-link to="/online-class" class="flex items-center text-16 py-4 px-3 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out" href="#!" active-class="text-primary">
            <img src="../../../public/assets/img/icons/fitur_educazone/kelas-online.png" alt="kelas-online" class="mr-4 ">
            <span>Kelas Online</span>
          </router-link>
        </li>
        <li class="relative">
          <router-link to="/mentor" class="flex items-center text-16 py-4 px-3 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out" href="#!" active-class="text-primary">
            <img src="../../../public/assets/img/icons/fitur_educazone/mentor.png" alt="mentor" class="mr-4 ">
            <span>Tanya Mentor</span>
          </router-link>
        </li>
        <li class="relative">
          <router-link to="/ngajar-asik" class="flex items-center text-16 py-4 px-3 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out" href="#!" active-class="text-primary">
            <img src="../../../public/assets/img/icons/fitur_educazone/ngajar-asik.png" alt="ngajar-asik" class="mr-4 ">
            <span>Ngajar Asik</span>
          </router-link>
        </li>
        
        <!-- temporary hidden-->
        <!-- <li class="relative">
          <router-link to="/gurupreneur" class="flex items-center text-16 py-4 px-3 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out" href="#!" active-class="text-primary">
            <img src="../../../public/assets/img/icons/fitur_educazone/gurupreneur.png" alt="gurupreneur" class="mr-4 ">
            <span>Gurupreneur</span>
          </router-link>
        </li>
        <li class="relative">
          <router-link to="/bantu-mereka" class="flex items-center text-16 py-4 px-3 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out" href="#!" active-class="text-primary">
            <img src="../../../public/assets/img/icons/fitur_educazone/bantu-mereka.png" alt="bantu-mereka" class="mr-4 ">
            <span>Bantu Mereka</span>
          </router-link>
        </li>
        <li class="relative">
          <router-link to="/cek-sertifikat" class="flex items-center text-16 py-4 px-3 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out" href="#!" active-class="text-primary">
            <img src="../../../public/assets/img/icons/fitur_educazone/cek-sertifikat.png" alt="cek-sertifikat" class="mr-4 ">
            <span>Cek Sertifikat</span>
          </router-link>
        </li>
        <li class="relative">
          <router-link to="/statistik" class="flex items-center text-16 py-4 px-3 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out" href="#!" active-class="text-primary">
            <img src="../../../public/assets/img/icons/fitur_educazone/statistik.png" alt="statistik" class="mr-4 ">
            <span>Statistik</span>
          </router-link>
        </li> -->
      </ul>

      <hr class="my-10 ">

      <p class="font-bold text-14 text-gray-500 tracking-widest mb-4 px-3">Educazone</p>
      <ul class="relative px-3">
        <li class="relative">
          <router-link to="/about-us" class="flex items-center text-16 py-2 overflow-hidden text-gray-500 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out" href="#!" data-mdb-ripple="true" data-mdb-ripple-color="blue" active-class="text-primary">
            Tentang Kami
          </router-link>
        </li>
        <li class="relative">
          <router-link to="/kebijakan-privasi" class="flex items-center text-16 py-2 overflow-hidden text-gray-500 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out" href="#!" data-mdb-ripple="true" data-mdb-ripple-color="blue" active-class="text-primary">
            Kebijakan Privasi
          </router-link>
        </li>
        <li class="relative">
          <a href="https://play.google.com/store/apps/details?id=com.educazonemobile" target="_blank" class="flex items-center text-16 py-2 overflow-hidden text-gray-500 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="blue" active-class="text-primary">
          Unduh Aplikasi
          </a>
        </li>
        <li class="relative">
          <router-link to="/pusat-bantuan" class="flex items-center text-16 py-2 overflow-hidden text-gray-500 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out" href="#!" data-mdb-ripple="true" data-mdb-ripple-color="blue" active-class="text-primary">
            Pusat Bantuan
          </router-link>
        </li>
      </ul>

      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoggedIn: this.$store.getters.isLoggedIn,
      profile: '',
    }
  },
  methods: {
    getProfile() {
      this.profile = this.$store.state.user
    },
    viewProfilePage() {
      this.$router.push('/profile')
    }
  },
  mounted() {
    this.getProfile()
  }
}
</script>

<style scoped>
ul.fitur-educazone li .router-link-exact-active {
  font-weight: bold;
  /* background: rgba(49, 63, 110, 0.2); */
  background: rgba(49, 63, 110, 0.2);
  color: #313F6E;
}
ul.fitur-educazone li .router-link-exact-active::after {
  content: "";
  margin-right: -0.1rem;
  position: absolute;
  right: -10px;
  width: 5px;
  height: 30px;
  background: #313F6E;
  border-radius: 15px;
}
</style>