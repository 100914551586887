import { createApp, VueElement } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store/index'

import './tailwind.css'
import 'tw-elements'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/tailwind.css'
import 'jquery/src/jquery'
import 'jquery'

// video resolution switcher
import '../public/assets/vendor/videojs/video.css'
import '../public/assets/vendor/video-resolution-switcher/videojs-resolution-switcher.css'
import '../public/assets/vendor/video-resolution-switcher/videojs-resolution-switcher.js'

// splidejs
import VueSplide from '@splidejs/vue-splide';
// Default theme
import '@splidejs/vue-splide/dist/css/splide.min.css';

axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? 'https://dev-api.educazone.id/api/v1/' : 'https://api.educazone.id/api/v1/';
axios.defaults.headers.common['Authorization'] = localStorage.getItem('Authorization')
const app = createApp(App)

app.config.globalProperties.$filters = {
    getCourseDuration(total_duration) {
        var hour = Math.floor(total_duration / 60);
        var minute = total_duration % 60;
        return `${hour ? hour : 0} jam ${minute ? minute : 0} menit`
    },
    toCurrency(value) {
        return  new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0
        }).format(value)
    },
    convertTime(date){
        const time = new Date(date);
        const hours = time.getHours();
        const minutes = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
        return hours + ':' + minutes;
    },
    convertDate(date){
        const list_month = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        const time = new Date(date);
        const day = time.getDate();
        const month = list_month[time.getMonth()];
        const year = time.getFullYear();
        return day + ' ' + month + ' ' + year;
    },
    convertDateWithDay(date) {
        const list_day = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
        const time = new Date(date);
        const daystring = list_day[time.getDay()];
        return daystring + ', ' + this.convertDate(date);
    },
    getTransactionStatus(transaction_status) {
        switch (transaction_status) {
            // waiting, unpaid, paid, expired
            case 'waiting':
                return 'Menunggu Pembayaran'
            case 'paid':
                return 'Pembayaran Berhasil'
            case 'unpaid':
                return 'Gagal'
            case 'expired':
                // return 'Kadaluarsa'
                return 'Gagal'
            default:
                return 'Tidak Diketahui'
        }
    },
    getTransactionLabelColor(transaction_status) {
        switch (transaction_status) {
            case 'waiting':
                return 'bg-yellow-500'
            case 'paid':
                return 'bg-green-500'
            case 'unpaid':
                return 'bg-red-500'
            case 'expired':
                return 'bg-red-500'
            default:
                return 'bg-gray-500'
        }
    },
}

// if (localStorage.getItem('Authorization')) {
//     axios.interceptors.response.use(undefined, function (error) {
//         if (error) {
//             const originalRequest = error.config;
//             if (error.response.status === 401 && !originalRequest._retry) {
//                 originalRequest._retry = true;
//                 console.log('token expired');
//                 store.dispatch('logout')
//                 router.push('/login')
//             }
//         }
//     })
// }

app.use(store)
    .use(createStore)
    .use(router)
    .use(VueAxios, axios)
    .use(VueSplide)
    .mount('#app')
