<template>
    <div class="relative ">
        <div id="certificate" class="flex justify-between bg-white rounded w-screen max-w-screen overflow-hidden h-2/6 laptop:h-full p-3 laptop:p-7 -z-10 absolute">
            <img src="../../public/assets/img/certificate-img.png" alt="certificate-img" class="mr-5 laptop:mr-28 h-full">
            <div class="flex flex-col justify-between items-start text-left laptop:py-10 w-full">
                <div>
                    <p class="flex text-24 laptop:text-63 mt-7 laptop:mt-28">Certified &nbsp;<span class="font-bold">User</span></p>
                    <p class="font-extrabold text-24 laptop:text-48 laptop:mt-12 capitalize" v-if="quiz_user.user">{{quiz_user.user.teacher.name}}</p>
                    <p class="laptop:mt-6 text-10 laptop:text-18">for successfully completing the examination <span class="capitalize font-semibold" v-if="course">{{course.name}}</span> with grade of : </p>
                    <p class="font-semibold text-24 laptop:text-48 mt-3">{{certificate.accumulative_score}} / 100</p>
                </div>
                <div>
                    <p class="font-semibold text-10 laptop:text-18 uppercase">Certification code : {{certificate.code}}</p>
                    <p class="mt-3 laptop:mt-5 text-10 laptop:text-18 font-semibold ">{{$filters.convertDate(certificate.created_at)}}</p>
                    <img class="mt-5" width="150px" height="150px" src="../../public/assets/img/certificate-barcode.png" alt="certificate-barcode">
                </div>
            </div>
            <div class="flex flex-col justify-between laptop:py-10 w-1/3">
                <div class="relative flex w-full">
                    <div class="flex justify-end relative items-center ">
                        <div class="text-12 laptop:text-36 font-bold mt-0 mr-9 laptop:mr-24"><span class="text-primary">Educazone</span></div>
                        <div class="bg-blue-200 rounded absolute mt-2 laptop:mt-5 mr-2 laptop:mr-6 h-[20px] laptop:h-[70px] w-[20px] laptop:w-[70px]"></div>
                        <div class="bg-primary rounded absolute mt-0 h-[20px] laptop:h-[70px] w-[20px] laptop:w-[70px]"></div>
                    </div>
                </div>
                <div class="flex flex-col justify-between space-y-5">
                    <div class="flex justify-center">
                        <img src="../../public/assets/img/certificate-signature.png" alt="certificate-signature" class="w-2/3">
                    </div>
                    <div class="text-center text-10 laptop:text-18">
                        <hr class="font-bold border-black mb-2 laptop:mb-4 ">
                        <p>Ardharikza</p>
                        <p>CEO Educazone</p>
                    </div>
                </div>
            </div>
        </div>
        

        <div class="flex flex-col items-center justify-center bg-black absolute z-10 w-full h-full py-10">
            <img ref="image_tag" id="image_tag" :src="image_url" class="w-full laptop:w-3/5">
        </div>
        <div class="flex items-center justify-between bg-white rounded w-full p-3 absolute z-10 bottom-0">
            <button @click="backToCourse(course_id)" class="flex items-center justify-center bg-primary rounded text-center font-bold text-white text-sm py-2 px-6 ">Kembali</button>
            <button @click="downloadCertificate()" class="flex items-center justify-center bg-primary rounded text-center font-bold text-white text-sm py-2 px-6 ">Unduh Sertifikat</button>
        </div>
    </div>
</template>

<script>
import domtoimage from 'dom-to-image'
export default {
    data() {
        return {
            certificate: {},
            course: {},
            quiz_user: {},
            image_url: '',
            certificate_id: this.$route.params.certificate_id,
            course_id: this.$route.params.course_id,

            pdfOptions: {
                image: {
                    type: "jpeg",
                    quality: 1,
                },
                html2canvas: { scale: 5 },
                jsPDF: {
                    unit: "mm",
                    orientation: "l",
                },
            },
            exportFilename: "certificate.pdf",
        };
    },
    methods: {
        getCertificateData() {
            this.axios.get(`certificate?id=${this.certificate_id}`).then(response => {
                this.certificate = response.data.data.certificate;
                this.course = response.data.data.course;
                this.quiz_user = this.certificate.quiz_user;
                console.log(this.quiz_user);
                if (this.certificate) {
                    this.createImage();
                }
            }).catch(error => {
                console.log(error.response);
            });
        },
        downloadCertificate() {
            let link = document.createElement('a')
            link.download = `${this.quiz_user.user.teacher.name}-${this.course.name}-Certificate.png`
            link.href = this.image_url
            link.click()
        },
        backToCourse(course_id) {
            this.$router.push({ name: 'Detail Belajar Mandiri', params: { course_id } });
        },
        createImage() {
            this.image_url = domtoimage
            .toJpeg(document.getElementById('certificate'), {
                quality: 0.95
            }).then(dataUrl => {
                this.image_url = dataUrl;
            })
        },
    },
    mounted() {
        this.getCertificateData();
    },
}
</script>

<style>
</style>