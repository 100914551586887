<template>
    <div class="relative bg-white text-center rounded-lg shadow-lg col-span-3 p-5 laptop:p-8 mt-10 px-auto h-full">
        <!-- top banner -->
        <div class="absolute left-0 top-0 w-full bg-top -z-10 rounded-t-lg"></div>
        <img src="../../public/assets/img/banner-bg.png" alt="" class="absolute left-0">
        <div class="relative bg-white text-center rounded-lg shadow-lg col-span-3 p-8 px-auto laptop:mx-16">
                <div v-if="quiz_status == 'not_started'" class="flex flex-col laptop:mx-52">
                    <p class="uppercase text-16 font-bold">SIAP-SIAP YA!</p>
                    <img src="../../public/assets/img/transaction-success.png" alt="" class="mx-auto" style="width: 362px;">
                    <p class="text-16 mb-6">Anda akan mengerjakan kuis sebanyak <span class="text-red-500 font-bold">{{questions.length}} soal untuk mendapatkan sertifikat. </span>
                    Anda hanya bisa mengerjakan kuis ini dalam <span class="font-bold text-red-500">1 kali kesempatan.</span></p>
                    <button v-if="questions.length > 0" @click="startQuiz" class="bg-primary text-white font-semibold text-center text-14 uppercase rounded-md py-4 mb-2 hover:bg-primary" data-mdb-ripple="true" data-mdb-ripple-color="blue">Mulai</button>
                    <button v-else class="bg-soft-disabled text-white font-semibold text-center text-14 uppercase rounded-md py-4 mb-2 cursor-default">Mulai</button>
                    <button @click="backToCourse" class="bg-white border border-primary text-primary font-semibold text-center text-14 uppercase rounded-md py-4 mb-2 hover:bg-primary hover:text-white transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="blue" >KEMBALI KE MATERI PEMBELAJARAN</button>
                </div>
                <div v-else-if="quiz_status == 'on_going'" class="flex flex-col px-auto">
                    <div class="bg-white border rounded-lg text-center mx-auto py-4 mb-8 w-full laptop:w-[600px]">
                        <div class="w-full mb-3 px-5 laptop:px-20">
                            <progress class="w-full" :value="parseInt(question_index)+1" :max="questions.length"></progress>
                        </div>
                        <p class="text-primary text-16 mb-6">Soal <span class="font-bold">{{parseInt(question_index)+1}}</span> / {{questions.length}}</p>
                        <p class="text-16 font-bold px-10 mb-10">{{questions[question_index].question}}</p>
                    </div>

                    <div class="flex flex-col items-center justify-start text-left mx-auto  w-full laptop:px-12 laptop:w-[600px]">
                        <div class="ex1 flex flex-col items-center justify-start text-left mb-8 w-full mx-auto laptop:px-12">
                            <label for="answer-a" class="answer-field mb-2 w-full">
                                <input v-model="answer" type="radio" value="a" id="answer-a" class="hidden form-check-input"/>
                                <span class="rounded-lg border border-gray-300 bg-white form-check-label inline-block text-gray-800 font-bold w-full py-4 mb-2 px-4 cursor-pointer">{{questions[question_index].option_a ? questions[question_index].option_a : '-'}}</span>
                            </label>
                            <label for="answer-b" class="answer-field mb-2 w-full">
                                <input v-model="answer" type="radio" value="b" id="answer-b" class="hidden form-check-input"/>
                                <span class="rounded-lg border border-gray-300 bg-white form-check-label inline-block text-gray-800 font-bold w-full py-4 mb-2 px-4 cursor-pointer">{{questions[question_index].option_b ? questions[question_index].option_b : '-'}}</span>
                            </label>
                            <label for="answer-c" class="answer-field mb-2 w-full">
                                <input v-model="answer" type="radio" value="c" id="answer-c" class="hidden form-check-input"/>
                                <span class="rounded-lg border border-gray-300 bg-white form-check-label inline-block text-gray-800 font-bold w-full py-4 mb-2 px-4 cursor-pointer">{{questions[question_index].option_c ? questions[question_index].option_c : '-'}}</span>
                            </label>
                            <label for="answer-d" class="answer-field mb-2 w-full">
                                <input v-model="answer" type="radio" value="d" id="answer-d" class="hidden form-check-input"/>
                                <span class="rounded-lg border border-gray-300 bg-white form-check-label inline-block text-gray-800 font-bold w-full py-4 mb-2 px-4 cursor-pointer">{{questions[question_index].option_d ? questions[question_index].option_d : '-'}}</span>
                            </label>
                            <label for="answer-e" class="answer-field mb-2 w-full">
                                <input v-model="answer" type="radio" value="e" id="answer-e" class="hidden form-check-input"/>
                                <span class="rounded-lg border border-gray-300 bg-white form-check-label inline-block text-gray-800 font-bold w-full py-4 mb-2 px-4 cursor-pointer">{{questions[question_index].option_e ? questions[question_index].option_e : '-'}}</span>
                            </label>
                        </div>
                    </div>

                    <!-- <QuizQuestion :question="question"></QuizQuestion> -->
                    <div class="flex justify-between items-center w-full ">
                        <button @click="previousQuestion" class="text-white text-center font-semibold text-14 rounded-lg py-3 px-5 tablet:px-11" :class="prevButtonClass">Sebelumnya</button>
                        <button v-if="this.question_index+1 < this.questions.length" @click="nextQuestion" class="text-white text-center font-semibold text-14 rounded-lg py-3 px-5 tablet:px-11"  :class="nextButtonClass">Selanjutnya</button>
                        <button v-else @click="submitAnswer" :disabled="!this.answer" class="text-white text-center font-semibold text-14 rounded-lg py-3 px-5 tablet:px-11" :class="submitButtonClass">Kumpulkan</button>
                    </div>
                </div>

                <div v-else-if="quiz_status == 'done'" class="flex flex-col px-auto py-8">
                    <div class="flex flex-col items-center mx-auto" style="width: 328px;">
                        <div class="h-32 w-32 rounded-full bg-white border-8 border-blue-200 mb-6">
                            <div class="flex flex-col justify-center items-center h-28 w-28 rounded-full bg-white border-4 border-blue-300">
                                <p class="uppercase font-bold text-12">SKOR ANDA</p>
                                <p class="text-primary font-bold text-32">{{removeDigit(result.nilai)}}</p>
                            </div>
                        </div>
                        <div v-if="passed" class="flex flex-col items-center font-bold mb-14">
                            <p class="text-24">Selamat</p>
                            <p class="text-14 mb-5">Selamat Anda telah menyelesaikan Kuis Pengayaan Submateri</p>
                            <img src="../../public/assets/img/trophy.png" alt="trophy">
                        </div>
                        <div v-else class="flex flex-col items-center font-bold mb-14">
                            <p class="text-24">Maaf</p>
                            <p class="text-14 mb-5">Anda harus meraih skor minimal 80 untuk lulus dan mendapatkan sertifikat</p>
                            <img src="../../public/assets/img/sad.png" alt="sad">
                        </div>
                        <button @click="backToCourse" class="bg-primary uppercase rounded text-14 font-semibold text-white py-4 w-full">KEMBALI KE MATERI PEMBELAJARAN</button>
                        <!-- <button v-if="passed && quiz_type == 'course'" data-bs-toggle="modal" data-bs-target="#modal-certificate" class="bg-primary uppercase rounded text-14 font-semibold text-white py-4 w-full">UNDUH SERTIFIKAT</button>
                        <button v-else @click="backToCourse" class="bg-primary uppercase rounded text-14 font-semibold text-white py-4 w-full">KEMBALI KE MATERI PEMBELAJARAN</button> -->
                    </div>
                </div>
                
                <!-- CERTIFICATE MODAL-->
                <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="modal-certificate" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
                    <div class="modal-dialog modal-dialog-centered modal-lg relative w-auto pointer-events-none">
                        <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                            <div class="modal-body relative p-4">
                                <div id="carouselExampleIndicators" class="carousel slide relative" data-bs-ride="carousel">
                                    <div class="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
                                        <button
                                        type="button"
                                        data-bs-target="#carouselExampleIndicators"
                                        data-bs-slide-to="0"
                                        class="active"
                                        aria-current="true"
                                        aria-label="Slide 1"
                                        ></button>
                                        <button
                                        type="button"
                                        data-bs-target="#carouselExampleIndicators"
                                        data-bs-slide-to="1"
                                        aria-label="Slide 2"
                                        ></button>
                                    </div>
                                    <div class="carousel-inner relative w-full overflow-hidden">
                                        <div class="carousel-item active float-left w-full">
                                            <img
                                                src="../../public/assets/img/certificate/certificate-front.png"
                                                class="block w-full"
                                                alt="certificate-front"
                                            />
                                        </div>
                                        <div class="carousel-item float-left w-full">
                                            <img
                                                src="../../public/assets/img/certificate/certificate-back.png"
                                                class="block w-full"
                                                alt="certificate-back"
                                            />
                                        </div>
                                    </div>
                                    <button
                                        class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
                                        type="button"
                                        data-bs-target="#carouselExampleIndicators"
                                        data-bs-slide="prev"
                                    >
                                        <span class="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button
                                        class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                                        type="button"
                                        data-bs-target="#carouselExampleIndicators"
                                        data-bs-slide="next"
                                    >
                                        <span class="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                                <a href="../../public/assets/img/certificate/certificate-front.png" download>
                                    <button class="bg-primary text-white text-14 text-center font-semibold rounded w-1/2 py-4">Download Certificate</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
import QuizQuestion from "./QuizQuestion.vue";
export default {
    components: { QuizQuestion },
    data() {
        return {
            quiz_status: "not_started",
            answer: '',
            answers: [],
            passed: false,
            course_id: this.$route.params.course_id,
            quiz_id: this.$route.params.quiz_id,
            quiz_type: this.$route.params.type,
            questions: [],
            question: {},
            question_index: 0,
            // prevButtonClass: 'bg-disabled',
            // nextButtonClass: 'bg-primary',
            result: {}
        };
    },
    methods: {
        getQuestions() {
            this.axios.get(`quiz/list-questions?id=${this.quiz_id}`).then(response => {
                if (response.data.data) {
                    this.questions = response.data.data;
                    this.getEachQuestion()
                }
            }).catch(error => {
                console.log(error);
            })
        },
        getEachQuestion() {
            this.question = this.questions[this.question_index];
        },
        startQuiz() {
            this.quiz_status = 'on_going'
        },
        previousQuestion() {
            if (this.question_index > 0) {
                this.question_index--
            } else {
                this.question_index = 0
            }
        },
        nextQuestion() {
            if (this.answer) {
                this.addAnswer()
            }
            if (this.question_index+1 < this.questions.length) {
                this.question_index++
            }

        },
        submitAnswer() {
            if (this.answer) {
                this.addAnswer()
            }
            const body = {
                quiz_id: this.quiz_id,
                answers: this.answers
            }
            this.axios.post('quiz/submit-answer', body).then(response => {
                this.result = response.data.data
                this.result.nilai >= 80 ? this.passed = true : this.passed = false
                this.quiz_status = 'done'
            }).catch(error => {
                console.log(error.response);
            })
        },
        addAnswer() {
            this.answers.push({
                question_id: this.questions[this.question_index].id,
                answer: this.answer
            })
            this.answer = ''
            console.log(this.answers);
        },
        removeDigit(number) {
            return number ? number : 0
        },
        backToCourse() {
            this.$router.push(`/belajar-mandiri/${this.course_id}`)
        }
    },
    computed: {
        score() {
            if (this.quiz_status == "done") {
                if (this.passed) {
                    return 100;
                }
                else {
                    return 0;
                }
            }
        },
        prevButtonClass() {
            if (this.question_index > 0) {
                return 'bg-primary'
            } else {
                return 'bg-disabled'
            }
        },
        nextButtonClass() {
            return 'bg-primary cursor-default'
        },
        submitButtonClass() {
            return this.answer ? 'bg-success' : 'bg-disabled'
        }
    },
    mounted() {
        this.getQuestions();
        if (this.question_index+1 >= this.questions.length) {
        } else if (this.question_index < 0) {
            localStorage.setItem('question_index', 0);
            this.question_index = 0
        }

    }
}
</script>

<style scoped>
    .bg-top {
        background: linear-gradient(90deg, #313F6E 0%, #5B6CA5 24.69%, #596795 70.8%, #293D80 100%);
        height: 250px;
    }

    progress[value] {
    /* width: 100%; */
    height: 12px;
    }

    progress[value]::-webkit-progress-bar {
        border-radius: 10px;
        background: #DEDEDE;
    }

    progress[value]::-webkit-progress-value {
        border-radius: 10px;
        background-color: #313F6E;
    }

    .ex1 input:checked + .form-check-label {
        background-color: #1AA0E826;
        border: 1px solid #313F6E;
    }
</style>