<template>
  <div class="flex flex-col justify-between h-screen">
    <router-view name="Header" /> 
    <div class="flex">
      <router-view name="Sidebar"/>
      <div class="w-full relative">
        <router-view name="TopBanner"/>
        <router-view class="min-h-screen relative" />
      </div>
    </div>
    <router-view name="Footer" />
  </div>
</template>

<style>
#app {
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* background: #F8FAFF; */
}
* {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(168, 167, 167);
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lightslategrey;
}
ul.fitur-educazone li a:hover {
  font-weight: bold;
  transition: ease-in-out 0.3s;
  background: rgba(49, 63, 110, 0.2);
}

input {
    -webkit-user-select: text; /* Chrome, Opera, Safari */
    -moz-user-select: text; /* Firefox 2+ */
    -ms-user-select: text; /* IE 10+ */
    user-select: text; /* Standard syntax */
}
input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

</style>
