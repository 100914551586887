<template>
    <div class="flex flex-col justify-start">
        <!-- banner background -->
        <div class="absolute bg-top -z-10 laptop:w-full hidden laptop:flex laptop:h-auto"></div>
        <!-- <img src="../../public/assets/img/bg-blue-gradient-sm.png" alt="bg-blue-gradient-sm" class="absolute -top-10 -z-10 laptop:w-full hidden laptop:flex laptop:h-auto"> -->
        <!-- banner content -->
        <div class="relative grid grid-cols-1 laptop:grid-cols-2 z-20 px-5 laptop:px-20">
            <!-- text content -->
            <div class="text-left flex flex-col justify-center items-start z-20 py-9 laptop:py-0">
                <p class="text-white text-32 laptop:text-48 font-bold mb-3 ">Tentang Kami</p>
                <p class="text-white text-16 laptop:text-18">Hadir untuk meningkatkan kualitas dan kemampuan guru di Indonesia.</p>
            </div>
            <!-- image content -->
            <img src="../../public/assets/img/about-us-img.png" alt="about-us-img" class="mt-16 hidden laptop:flex">
            <div class="absolute top-0 h-full w-full laptop:hidden -z-30" style="background: linear-gradient(90deg, #313F6E 0%, #5B6CA5 24.69%, #596795 70.8%, #293D80 100%);"></div>
        </div>

        <!-- Content -->
        <div class="px-5 laptop:px-20 text-left mb-24">
            <p class="text-16 laptop:text-18 mt-10 mb-14 "><b>IKALSMART</b> adalah platform pembelajaran untuk para guru Indonesia yang ingin menambah dan mengembangkan kompetensi keahliannya. Dengan proses pembelajaran yang mudah, para guru bisa menambah keahlian mereka di manapun dan kapanpun. </p>

            <!-- Kelebihan -->
            <div class="flex flex-col mb-16">
                <div class="flex flex-col laptop:flex-row justify-start items-center mb-6 laptop:mb-10">
                    <div class="relative laptop:mr-14 mb-4 laptop:mb-0">
                        <img src="../../public/assets/img/icons/pembelajaran-hybrid-lg.png" alt="pembelajaran-hybrid-lg" class="w-[100px] laptop:w-full">
                    </div>
                    <div>
                        <p class="text-primary font-bold text-18 laptop:text-24 text-center laptop:text-left mb-2 laptop:mb-0">Pembelajaran Hybrid</p>
                        <p class="text-16 laptop:text-18 text-center laptop:text-left">Media belajar berbentuk video berkualitas tinggi. Terdapat juga kelas pembelajaran bersama Mentor secara langsung via online. </p>
                    </div>
                </div>

                <div class="flex flex-col laptop:flex-row-reverse justify-start items-center mb-6 laptop:mb-10">
                    <div class="relative laptop:ml-14 mb-4 laptop:mb-0">
                        <img src="../../public/assets/img/icons/materi-pembelajaran-efektif-lg.png" alt="materi-pembelajaran-efektif-lg" class="w-[100px] laptop:w-full">
                    </div>
                    <div>
                        <p class="text-primary font-bold text-18 laptop:text-24 text-center laptop:text-right mb-2 laptop:mb-0">Materi Pembelajaran Efektif</p>
                        <p class="text-16 laptop:text-18 text-center laptop:text-right">Materi pelatihan yang sistematis yang dilengkapi dengan contoh aplikasi praktis. Materi telah disesuaikan dengan kebutuhan sertifikasi kompetensi guru di Indonesia. </p>
                    </div>
                </div>
                
                <div class="flex flex-col laptop:flex-row justify-start items-center mb-6 laptop:mb-10">
                    <div class="relative laptop:mr-14 mb-4 laptop:mb-0">
                        <img src="../../public/assets/img/icons/waktu-pembelajaran-efisien-lg.png" alt="waktu-pembelajaran-efisien-lg" class="w-[100px] laptop:w-full">
                    </div>
                    <div>
                        <p class="text-primary font-bold text-18 laptop:text-24 text-center laptop:text-left mb-2 laptop:mb-0">Waktu Pembelajaran yang Efisien</p>
                        <p class="text-16 laptop:text-18 text-center laptop:text-left">Waktu dan tempat pelatihan yang fleksibel dengan biaya terjangkau.</p>
                    </div>
                </div>
            </div>

            <!-- Pengembang -->
            <div class="flex flex-col">
                <p class="font-bold text-24 laptop:text-32 text-center laptop:text-left mb-7">Pengembang <span class="text-primary">IKALSMART</span></p>
                <p class="text-16 mb-7 text-center laptop:text-left"><span class="font-bold text-primary">IKALSMART</span> dikembangkan oleh tim yang relevan dan berpengalaman untuk membangun sebuah teknologi pendidikan yang andal, terpercaya, dan berorientasi kepada pengguna.</p>

                <!-- Direksi -->
                <div class="mb-10 ">
                    <p class="font-semibold text-24 mb-8 text-center laptop:text-left">Direksi</p>
                    <div class="grid grid-cols-2 tablet:grid-cols-3 laptop:grid-cols-6 gap-3 laptop:gap-6">
                        <div class="flex flex-col items-center text-center">
                            <img src="../../public/assets/img/pengembang/direksi/direksi_1.png" alt="direksi_1" class="mb-5 w-[100px] laptop:w-full">
                            <p class="font-bold text-14 laptop:text-16 mb-3">Ardhariksa Kurniullah</p>
                            <p class="text-14">Presiden Direktur</p>
                        </div>
                        <div class="flex flex-col items-center text-center">
                            <img src="../../public/assets/img/pengembang/direksi/direksi_2.png" alt="direksi_2" class="mb-5 w-[100px] laptop:w-full">
                            <p class="font-bold text-14 laptop:text-16 mb-3">M. Hatta Zakaria</p>
                            <p class="text-14">VP Product</p>
                        </div>
                        <div class="flex flex-col items-center text-center">
                            <img src="../../public/assets/img/pengembang/direksi/direksi_3.png" alt="direksi_3" class="mb-5 w-[100px] laptop:w-full">
                            <p class="font-bold text-14 laptop:text-16 mb-3">Firman Alamsyah</p>
                            <p class="text-14">VP Development</p>
                        </div>
                        <div class="flex flex-col items-center text-center">
                            <img src="../../public/assets/img/pengembang/direksi/direksi_4.png" alt="direksi_4" class="mb-5 w-[100px] laptop:w-full">
                            <p class="font-bold text-14 laptop:text-16 mb-3">Dedy Rahmat</p>
                            <p class="text-14">VP Engineer</p>
                        </div>
                        <div class="flex flex-col items-center text-center">
                            <img src="../../public/assets/img/pengembang/direksi/direksi_5.png" alt="direksi_5" class="mb-5 w-[100px] laptop:w-full">
                            <p class="font-bold text-14 laptop:text-16 mb-3">Yani Rahman Y.</p>
                            <p class="text-14">VP Operasional</p>
                        </div>
                        <div class="flex flex-col items-center text-center">
                            <img src="../../public/assets/img/pengembang/direksi/direksi_6.png" alt="direksi_6" class="mb-5 w-[100px] laptop:w-full">
                            <p class="font-bold text-14 laptop:text-16 mb-3">Moh. Abdul Aziz</p>
                            <p class="text-14">VP Sales & Marketing</p>
                        </div>
                    </div>
                </div>

                <!-- Komisaris -->
                <div class="mb-10 ">
                    <p class="font-semibold text-24 mb-8 text-center laptop:text-left">Komisaris</p>
                    <div class="grid grid-cols-2 tablet:grid-cols-3 laptop:grid-cols-6 gap-3 laptop:gap-6">
                        <!-- Komisaris 1 -->
                        <div class="flex flex-col items-center text-center">
                            <img src="../../public/assets/img/pengembang/komisaris/komisaris_1.png" alt="komisaris_1" class="mb-5 w-[100px] laptop:w-full">
                            <p class="font-bold text-14 laptop:text-16 mb-3">Dr. Aris Adi Laksono</p>
                            <p class="text-14">Komisaris Utama</p>
                        </div>
                        <!-- Komisaris 2 -->
                        <div class="flex flex-col items-center text-center">
                            <img src="../../public/assets/img/pengembang/komisaris/komisaris_2.png" alt="komisaris_2" class="mb-5 w-[100px] laptop:w-full">
                            <p class="font-bold text-14 laptop:text-16 mb-3">M. Habibur Rochman</p>
                            <p class="text-14">Komisaris</p>
                        </div>
                        <!-- Komisaris 3 -->
                        <div class="flex flex-col items-center text-center">
                            <img src="../../public/assets/img/pengembang/komisaris/komisaris_3.png" alt="komisaris_3" class="mb-5 w-[100px] laptop:w-full">
                            <p class="font-bold text-14 laptop:text-16 mb-3">Matsani AR.</p>
                            <p class="text-14">Komisaris</p>
                        </div>
                    </div>
                </div>
                
                <!-- Dewan Pakar & Adviser -->
                <div class="mb-10 ">
                    <p class="font-semibold text-24 mb-8 text-center laptop:text-left ">Dewan Pakar & Adviser</p>
                    <div class="grid grid-cols-2 tablet:grid-cols-3 laptop:grid-cols-6 gap-3 laptop:gap-6">
                        <!-- dewan 1 -->
                        <div class="flex flex-col items-center text-center">
                            <img src="../../public/assets/img/pengembang/dewan/dewan_1.png" alt="dewan_1" class="mb-5 w-[100px] laptop:w-full">
                            <p class="font-bold text-14 laptop:text-16 mb-3">Dr. H. Saepuloh</p>
                            <p class="text-14">Dewan Pakar</p>
                        </div>
                        <!-- dewan 2 -->
                        <div class="flex flex-col items-center text-center">
                            <img src="../../public/assets/img/pengembang/dewan/dewan_2.png" alt="dewan_2" class="mb-5 w-[100px] laptop:w-full">
                            <p class="font-bold text-14 laptop:text-16 mb-3">Dr. Affan Hasnan</p>
                            <p class="text-14">Dewan Pakar</p>
                        </div>
                        <!-- dewan 3 -->
                        <div class="flex flex-col items-center text-center">
                            <img src="../../public/assets/img/pengembang/dewan/dewan_3.png" alt="dewan_3" class="mb-5 w-[100px] laptop:w-full">
                            <p class="font-bold text-14 laptop:text-16 mb-3">Dr. Eng Fadly Usman</p>
                            <p class="text-14">Dewan Pakar</p>
                        </div>
                        <!-- dewan 4 -->
                        <div class="flex flex-col items-center text-center">
                            <img src="../../public/assets/img/pengembang/dewan/dewan_4.png" alt="dewan_4" class="mb-5 w-[100px] laptop:w-full">
                            <p class="font-bold text-14 laptop:text-16 mb-3">H. Lewa Karma</p>
                            <p class="text-14">Dewan Pakar</p>
                        </div>
                        <!-- dewan 5 -->
                        <div class="flex flex-col items-center text-center">
                            <img src="../../public/assets/img/pengembang/dewan/dewan_5.png" alt="dewan_5" class="mb-5 w-[100px] laptop:w-full">
                            <p class="font-bold text-14 laptop:text-16 mb-3">Achmad Zuhri</p>
                            <p class="text-14">Dewan Pakar</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.bg-top {
    background: linear-gradient(90deg, #313F6E 0%, #5B6CA5 24.69%, #596795 70.8%, #293D80 100%);
    height: 280px;
}

</style>