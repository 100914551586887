<template>
    <div class="container mx-auto">
        <div class="grid grid-cols-1 min-h-screen">
            <div class="my-auto mx-auto" style="width: 345px">
                <div class="mx-auto">
                    <form @submit.prevent="send" class="align-left mx-auto text-left">
                        <img class="mb-20" src="../../public/assets/img/logo/main-logo.png" alt="main-logo" />
                        <p class="font-bold mb-6 text-24">Verifikasi</p>

                        <div class="text-left mb-8">
                            <label for="password-field" class="block text-sm font-bold text-slate-700 mb-3">Verifikasi via {{ getConfirmationMethod() }}</label>
                            <p class="text-12 mb-4">Masukkan kode verifikasi yang telah dikirim melalui {{ confirmation_method == 'phone' ? `Whatsapp ke nomor ${registered_user.phone}` : `Email ke ${registered_user.email}` }} </p>
                            <div class="grid grid-cols-5 mb-4">
                                <input type="text" class="bg-gray-300 text-center text-lg rounded shadow-xl font-bold w-12 h-12" maxlength="1" min="0" pattern="[0-9]+" placeholder="-" ref="otp1" @keyup="$refs.otp2.focus()" v-model="otp1">
                                <input type="text" class="bg-gray-300 text-center text-lg rounded shadow-xl font-bold w-12 h-12" maxlength="1" min="0" pattern="[0-9]+" placeholder="-" ref="otp2" @keyup="$refs.otp3.focus()" @keyup.left="$refs.otp1.focus()" v-model="otp2">
                                <input type="text" class="bg-gray-300 text-center text-lg rounded shadow-xl font-bold w-12 h-12" maxlength="1" min="0" pattern="[0-9]+" placeholder="-" ref="otp3" @keyup="$refs.otp4.focus()" @keyup.left="$refs.otp2.focus()" v-model="otp3">
                                <input type="text" class="bg-gray-300 text-center text-lg rounded shadow-xl font-bold w-12 h-12" maxlength="1" min="0" pattern="[0-9]+" placeholder="-" ref="otp4" @keyup="$refs.confirm.focus()" @keyup.left="$refs.otp3.focus()" v-model="otp4">
                            </div>
                            <p class="font-bold">{{countDownTimer}}</p>
                            <p v-if="timeout" class="font-bold mb-4">
                                Belum dapat kode? <span @click="resendCode" class="text-primary cursor-pointer">Kirim Ulang!</span>
                            </p>
                            <p v-else class="text-red-500 mb-4">{{message}}</p>
                        </div>
                        <button type="submit" :disabled="(otp1 + otp2 + otp3 + otp4).length != 4" :class="(otp1 + otp2 + otp3 + otp4).length != 4 ? 'bg-disabled' : 'bg-primary'" class="rounded text-center font-bold w-full text-white py-2" ref="confirm">Konfirmasi</button>
                    </form>
                    <div class="text-center mx-auto my-4">
                        <!-- <p class="mx-auto mb-6 font-bold text-primary"><router-link to="/login">Kembali ke halaman Masuk</router-link></p> -->
                        <p class="mx-auto mb-6">Pastikan {{ confirmation_method == 'phone' ? 'nomor handphone' : 'email' }} Anda sudah benar. <span class="font-bold text-primary"><router-link to="/register">Ubah di sini!</router-link></span> </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // button_bg: 'bg-disabled',
            message: '',
            otp1: '',
            otp2: '',
            otp3: '',
            otp4: '',

            name: this.$store.state.registered_user.name,
            phone: this.$store.state.registered_user.phone,
            email: this.$store.state.registered_user.email,
            password: this.$store.state.registered_user.password,
            confirmation_method: this.$store.state.registered_user.confirmation_method,

            message: '',
            timer: 90, // 1 menit 60 detik
            countDownTimer: '',
            timeout: false,
            registered_user: this.$store.state.registered_user,
        }
    },
    methods: {
        send() {
            if (this.otp1 && this.otp2 && this.otp3 && this.otp4) {
                this.button_bg = 'bg-primary'
            }
            const data = {
                otp: this.otp1 + this.otp2 + this.otp3 + this.otp4,
                email: this.email,
            }
            this.axios.post('auth/activate', data).then(response => {
                console.log(response.data);
                this.$router.push('/login')
            }).catch(error => {
                console.log(error.response);
                this.message = 'Maaf, kode verifikasi salah'
            })
        },
        countDown() {
            if (this.timer > 0) {
                setTimeout(() => {
                    this.timer--
                    this.countDownTimer = this.formatTime(this.timer)
                    this.countDown()
                }, 1000)
            } else {
                this.timeout = true
            }
        },
        resendCode() {
            const user = {
                name: this.name,
                phone: this.phone,
                email: this.email,
                password: this.password,
                confirmation_method: this.confirmation_method,
            }
            this.$store.dispatch('register', user).then(response => {
                console.log(response.data);
            }).catch(error => {
                console.log(error.response);
            })
            this.countDownTimer = ''
            this.timer = 90
            this.timeout = false
            // window.location.reload()
            this.countDown()
        },
        formatTime(time) {
            let minutes = Math.floor(time / 60)
            let seconds = time - minutes * 60
            if (seconds < 10) {
                seconds = '0' + seconds
            }
            return minutes + ':' + seconds
        },
        getConfirmationMethod() {
            return this.confirmation_method == 'phone' ? 'Nomor Handphone' : 'Email'
        }
    },
    mounted() {
        this.$refs.otp1.focus();
        this.countDown()
    },
};
</script>

<style scoped>
.input-field {
    border: none;
    border-bottom: 2px solid rgba(37, 40, 43, 0.33);
}
.input-field:focus {
    outline: none;
    border-bottom: 2px solid #0099DC;
}
input:invalid {
    /* border: 3px solid red; */
    outline-color: red;
}
</style>