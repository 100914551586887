<template>
    <div class="h-full mt-10">
        <div class="bg-white rounded-lg shadow-lg text-left col-span-3 p-0 laptop:p-8 h-full">
            <div class="text-left mb-20">
                <div class="bg-white rounded laptop:shadow-md">
                    <div class="grid grid-cols-1 laptop:grid-cols-3 bg-white laptop:bg-gray-100 mb-8 shadow-md laptop:shadow-none rounded-xl laptop:rounded-none px-4 laptop:px-0" style="min-height:271px">
                        <div class="flex mb-4 laptop:hidden">
                            <span @click="backToHome">
                                <i class="fa-solid fa-angle-left fa-2xl"></i>
                            </span>
                        </div>
                        <div class="col-span-2 relative mb-4 laptop:mb-0 max-h-[180px] laptop:max-h-full  overflow-hidden">
                            <img :src="course_detail.thumbnail" alt="" class="rounded-xl laptop:rounded-none flex-none w-full h-full">
                        </div>
                        <div class="flex flex-col justify-between laptop:p-6">
                            <div>
                                <p class="font-bold text-24 leading-7 mb-3 capitalize">{{ course_detail.name }} </p>
                            </div>
                            <!-- Course Mentor -->
                            <div class="flex justify-start items-center ">
                                <img :src="mentor.photo" alt="mentor photo" class="rounded-full mr-4 h-10 w-10">
                                <p class="font-bold text-16 capitalize">{{ mentor.name }}</p>
                            </div>
                            <hr v-if="!isCourseAvailable" class="mt-8 laptop:mt-0 laptop:hidden">
                            <div class="flex flex-col my-4 laptop:my-0 laptop:hidden">
                                <div v-if="haveToBuyCourse" class="flex justify-start items-center mb-5">
                                    <span class="text-18 text-primary font-bold m-0" style="line-height:0">{{this.$filters.toCurrency(course_detail.token_price)}}</span>
                                </div>
                                <button v-if="haveToBuyCourse" @click="checkoutCourse" class="bg-primary text-center text-white text-14 font-semibold py-3 rounded-lg w-full">
                                    Beli Sekarang
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-1 laptop:grid-cols-3 mb-8 h-full mx-4 laptop:mx-0" >
                        <div class="col-span-2 laptop:px-10">
                            <p class="text-18 font-bold mb-4">Daftar Sub Materi</p>
                            <p class="text-14 laptop:text-16 text-gray-500 mb-4">{{ course_detail.total_sub_course ? course_detail.total_sub_course : '0' }} Video Pembelajaran | {{ this.$filters.getCourseDuration(course_detail.total_duration) }}</p>
                            <div class="accordion" id="accordionExample">
                                <SubCourseComponent v-for="sub_course in sub_courses" :key="sub_course.id" :sub_course="sub_course" :isCourseAvailable="isCourseAvailable" :index="sub_course.order" />
                                
                                <!-- Final Quiz Section-->
                                <div class="my-10 ">
                                    <p class="text-18 font-bold mb-4">Kuis Akhir Pembelajaran <span class="capitalize">{{course_detail.name}}</span></p>
                                    <button v-if="isCourseAvailable && !course_user.is_quiz_done && course_detail.quiz && course_user.is_quiz_open" @click="doCourseQuiz(course_detail.quiz.id)"
                                        class="flex justify-between items-center bg-blue-100 rounded-lg border text-left py-7 px-5 mb-2 w-full  cursor-pointer">
                                        <p class="text-18 font-bold text-black">Mulai Kerjakan Kuis</p>
                                    </button>
                                    <button v-else-if="isCourseAvailable && course_user.is_quiz_done"
                                        class="flex justify-between items-center bg-primary rounded-lg border text-left py-7 px-5 mb-2 w-full  cursor-default">
                                        <div class="flex items-center justify-start">
                                            <img src="../../public/assets/img/icons/icon_check_circle.svg" alt="" class="mr-3 ">
                                            <p class="text-18 font-bold text-white">Kuis Selesai</p>
                                        </div>
                                        <p class="text-18 font-bold text-white">Nilai : {{removeDigit(certificate.score)}}</p>
                                    </button>
                                    <button v-else
                                        class="flex justify-between items-center bg-soft-disabled rounded-lg border text-left py-7 px-5 mb-2 w-full cursor-default">
                                        <p class="text-18 font-bold text-gray-500">Mulai Kerjakan Kuis</p>
                                        <img src="../../public/assets/img/icons/lock-dark.png" alt="lock-dark">
                                    </button>
                                </div>

                                <!-- Certificate Section -->
                                <div class="my-10 ">
                                    <p class="text-18 font-bold mb-4">Sertifikat</p>
                                     <button v-if="isCourseAvailable && certificate && course_user.is_certified" @click="getCertificate(certificate.id)"
                                        class="flex justify-between items-center bg-primary rounded-lg border text-left py-7 px-5 mb-2 w-full  cursor-pointer">
                                        <p class="text-18 font-bold text-white">Lihat Sertifikat Penyelesaian Materi</p>
                                    </button>
                                    <button v-else
                                        class="flex justify-between items-center bg-soft-disabled rounded-lg border text-left py-7 px-5 mb-2 w-full cursor-default">
                                        <p class="text-18 font-bold text-gray-500">Sertifikat Penyelesaian Materi</p>
                                        <img src="../../public/assets/img/icons/lock-dark.png" alt="lock-dark">
                                    </button>
                                    <p class="text-14 text-gray-500">Sertifikat bisa Anda dapatkan setelah menyelesaikan pembelajaran dan kuis akhir pembelajaran.</p>
                                </div>

                            </div>
                        </div>

                        <!-- right section -->
                        <!-- <div class="hidden laptop:flex laptop:flex-col laptop:mx-3">
                            <div class="bg-white rounded-lg border shadow text-left py-5 px-3 ">
                                <div v-if="isCourseAvailable" class="flex justify-start items-center mb-4">
                                    <img :src="mentor.photo" alt="mentor photo" class="rounded-full mr-4 h-10 w-10">
                                    <p class="font-bold text-16 capitalize">{{ mentor.name }}</p>
                                </div>
                                <div v-else-if="haveToBuyCourse" class="flex justify-start items-center mb-5">
                                    <img src="../../public/assets/img/icons/teacher-token.png" alt="course-token" class="mr-2">
                                    <span class="text-14 text-yellow-300 font-bold m-0" style="line-height:0">{{course_detail.token_price}} Token</span>
                                </div>

                                <div class="flex my-4 laptop:my-0">
                                    <button v-if="isCourseAvailable" @click="chatMentor(mentor)" class="bg-white text-center text-primary border border-primary text-14 font-semibold py-3 rounded-lg w-full">
                                        Chat Mentor
                                    </button>
                                    <button v-else-if="haveToBuyCourse" @click="checkoutCourse" class="bg-primary text-center text-white text-14 font-semibold py-3 rounded-lg w-full">
                                        Beli Sekarang
                                    </button>
                                </div>
                            </div>
                        </div> -->

                        <div v-if="haveToBuyCourse" class="hidden laptop:flex laptop:flex-col laptop:mx-3">
                            <div class="bg-white rounded-lg border shadow text-left py-5 px-3 ">
                                <div class="flex justify-start items-center mb-5">
                                    <span class="text-14 text-primary font-bold m-0" style="line-height:0">{{this.$filters.toCurrency(course_detail.token_price)}}</span>
                                </div>

                                <div class="flex my-4 laptop:my-0">
                                    <button @click="checkoutCourse" class="bg-primary text-center text-white text-14 font-semibold py-3 rounded-lg w-full">
                                        Beli Sekarang
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- Toast-->
        <div v-show="showToast" class="absolute flex space-x-2 justify-center inset-x-1/3 top-14">
            <div :class="isToastSuccess ? 'bg-green-100' : 'bg-red-100'"
                class="shadow-lg mx-auto w-auto px-5 max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block"
                id="static-example" role="alert" aria-live="assertive" aria-atomic="true" data-mdb-autohide="false">
                <div class="flex items-center justify-center">
                    <img v-if="isToastSuccess" src="../../public/assets/img/icons/icon_success-standard-solid.png"
                        alt="icon success">
                    <img v-else src="../../public/assets/img/icons/icon_triangle-danger-f.png" alt="icon error">
                    <div class="p-3 rounded-b-lg break-words"
                        :class="isToastSuccess ? 'bg-green-100 text-green-500' : 'bg-red-100 text-red-500'">
                        <span v-if="isToastSuccess">
                            Pembayaran Berhasil
                        </span>
                        <span v-else>
                            Anda sudah membeli kursus ini, silahkan lihat riwayat transaksi Anda
                        </span>
                    </div>
                    <img v-if="isToastSuccess" @click="toggleToast()"
                        src="../../public/assets/img/icons/close_24px_success.png" alt="close_24px_success"
                        data-mdb-dismiss="toast" class="cursor-pointer ">
                    <img v-else @click="toggleToast()" src="../../public/assets/img/icons/close_24px.png"
                        alt="close_24px" data-mdb-dismiss="toast" class="cursor-pointer ">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SubCourseComponent from '../components/SubCourseComponent.vue';
export default {
    components: {
        SubCourseComponent
    },
    data() {
        return {
            course_id: this.$route.params.course_id,
            course_detail: "",
            type: "belajar-mandiri",
            mentor: "",
            transaction_id: "",
            sub_courses: [],
            detail_video: "",
            course_user: '',
            certificate: '',
            
            showToast: false,
            isToastSuccess: false,


        };
    },
    methods: {
        getDetailCourse() {
            var body = {
                id: this.course_id
            };
            this.axios.post("course/detail", body).then(response => {
                this.course_detail = response.data.data;
                this.sub_courses = this.course_detail.sub_courses;
                this.mentor = this.course_detail.mentor;
                this.course_user = this.course_detail.course_user ? this.course_detail.course_user : {}
                this.certificate = this.course_detail.certificate
            }).catch(error => {
                console.log(error);
            });
        },
        checkoutCourse() {
            const body = {
                course_id: this.course_id
            }

            this.axios.post('course/checkout', body).then(response => {
                if (response.data) {
                    var transaction_id = response.data.data.created_transaction_id
                    this.$router.push({ name: "Checkout", params: { id: this.course_id, type: this.type, transaction_id } });
                } else {
                    this.showToast = true
                    this.toastResponse()
                    this.toggleErrorToast();    
                }
            }).catch(error => {
                console.log(error);
                this.toggleErrorToast();
            })
        },
        doCourseQuiz(quiz_id) {
            this.$router.push({ name: "Do Quiz", params: { quiz_id, quiz_type: 'course' } });
        },
        chatMentor(mentor) {
            this.$router.push({ name: "Chat Mentor History", params: { mentor_id: mentor.user_id, from_course: true, mentor_data: JSON.stringify(mentor)} });
        },
        getCertificate(certificate_id) {
            this.$router.push({ name: "Download Certificate", params: { certificate_id, course_id: this.course_id } });
        },
        backToHome() {
            this.$router.push({name: 'Home'});
        },

        toastResponse() {
            // scroll to top
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            setInterval(() => {
                this.showToast = false
            }, 5000);
        },
        toggleToast() {
            this.showToast = !this.showToast
        },
        toggleSuccessToast() {
            this.isToastSuccess = true
            this.toggleToast()
            setInterval(() => {
                this.returnToMainPage();
            }, 2000);
        },
        toggleErrorToast() {
            this.isToastSuccess = false
            this.toggleToast()
        },
        removeDigit(number) {
            return number ? number : 0
        },
    },
    mounted() {
        this.getRouteParams
    },
    computed: {
        getRouteParams() {
            this.course_id = this.$route.params.course_id;
            this.getDetailCourse()
        },
        isCourseAvailable() {
            return this.course_detail.status == 'available';
        },
        haveToBuyCourse() {
            return !this.isCourseAvailable;
        },
    }
}
</script>