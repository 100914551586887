import { createStore } from 'vuex'
import axios from 'axios'
import createPersistedState from "vuex-persistedstate";
import router from '../router'

export default createStore({
	plugins: [createPersistedState()],
	state: {
		status: '',
		token: localStorage.getItem('Authorization') || '',
		user: {},
		registered_user: {},
		hasCheckoutItem: false,
		role_id: '',
		confirmation_method: '',
		course_module_url: '',
	},
	mutations: {
		auth_request(state) {
			state.status = 'loading'
		},
		auth_success(state, token) {
			state.status = 'success'
			state.token = token
		},
		userDetail(state, user) {
			state.user = user.profile
			state.email = user.email
			state.role_id = user.role_id
		},
		auth_error(state) {
			state.status = 'error'
		},
		userRegisterData(state, user) {
			state.registered_user = user
		},
		confirmation_method(state, confirmation_method) {
			state.confirmation_method = confirmation_method
		},
		logout(state) {
			state.status = ''
			state.token = ''
			state.user = []
			state.role_id = ''
		},
		setModuleUrl(state, course_module_url) {
			state.course_module_url = course_module_url
		},
	},
	actions: {
		keepUserRegisterData({ commit }, user) {
			commit('userRegisterData', user)
			router.push({name: 'Choose Verification'})
		},
		register({ commit }, user) {
			return new Promise((resolve, reject) => {
				commit('auth_request')
				axios.post('auth/register', user).then(response => {
					commit('userRegisterData', user)
					resolve(response)
				}).catch(error => {
					reject(error)
				})
			})
		},
		
		login({ commit }, user) {
			return new Promise((resolve, reject) => {
				commit('auth_request')
				axios.post('auth/login', user).then(response => {
					if (response.data.success) {
						const token = response.data.data.access_token
						localStorage.setItem('Authorization', token)
						axios.defaults.headers.common['Authorization'] = localStorage.getItem('Authorization')
						commit('auth_success', token)
					}
					resolve(response)
				})
				.catch(err => {
					commit('auth_error')
					localStorage.removeItem('Authorization')
					reject(err)
				})
			})
		},
		getProfile({ commit }) {
			return new Promise((resolve, reject) => {
				axios.get('user/profile').then(response => {
					if (response.data.success) {
						const userData = response.data.data
						commit('userDetail', userData)
					}
					resolve(response)
				})
				.catch(err => {
					reject(err.response)
				})
			})
		},
		logout({ commit }) {
			return new Promise((resolve, reject) => {
				commit('logout')
				localStorage.removeItem('Authorization')
				delete axios.defaults.headers.common['Authorization']
				resolve()
			})
		},
		setViewCourseModule({ commit }, course_module_url) {
			commit('setModuleUrl', course_module_url)
		}
	},
	getters: {
		isLoggedIn: state => !!state.token,
		authStatus: state => state.status,
		userDetail: state => state,
		userRoleId: state => state.role_id,
		getCourseModuleUrl: state => state.course_module_url,
	}
})