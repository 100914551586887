<template>
    <div class="flex flex-col items-start justify-start h-full">
    <!-- <div class="shadow rounded-lg mx-auto h-auto p-7 bg-light-white w-full my-0 laptop:my-auto laptop:w-[529px]"> -->
        <div class="bg-white rounded-md mt-16 mb-24 px-7 py-9 shadow-lg laptop:mx-auto ">
            <div class="flex justify-between items-center">
                <p class="text-left font-bold text-24 mb-7 ">Detail Transaksi</p>
                <p class="text-right font-bold text-12 mb-7">
                </p>
            </div>
            <div class="flex items-center justify-between mb-5">
                <div class="mr-24 ">
                    <span class="text-14 font-semibold mr-4">Belajar Mandiri</span>
                    <span class="text-12 font-normal">{{this.$filters.convertDateWithDay(transaction.transaction_date)}}</span>
                </div>
                <div :class="this.$filters.getTransactionLabelColor(transaction.payment_status)" class="px-2 py-1 rounded-md text-white text-12 font-medium"> 
                    {{ this.$filters.getTransactionStatus(transaction.payment_status) }}
                </div>
            </div>
            <div v-if="transaction.payment_status != 'paid'" class="flex items-center justify-start mb-10">
                <p class="text-14 mr-4">Kadaluarsa pada pukul <span class="text-danger font-semibold">{{ transaction_expired }}</span></p>
            </div>

            <div class="flex items-center justify-start">
                <div class="flex items-start">
                    <img class="h-[69px] mr-4" :src="transaction.course_thumbnail" alt="">
                </div>
                <p class="text-18">{{transaction.course}}</p>
            </div>

            <hr class="my-6 ">

            <p class="text-left font-semibold text-16 mb-5">Detail Pembayaran</p>
            <div class="flex items-center justify-between">
                <p class="text-14 font-medium">Metode Pembayaran</p>
                <span v-if="transaction.payment_method_image">
                    <img :src="transaction.payment_method_image" alt="payment_method_image" class="w-[64px]">
                </span>
                <span v-else>
                    -
                </span>
            </div>

            <hr class="my-4">

            <div class="flex items-center justify-between">
                <p class="text-14 font-medium">Harga Pembayaran</p>
                <p class="text-14 font-semibold">{{this.$filters.toCurrency(transaction.payment_amount)}}</p>
            </div>

            <hr class="my-4">

            <div v-if="transaction.payment_promo" class="flex items-center justify-between">
                <p class="text-14 font-medium">Promo</p>
                <p class="text-14 font-semibold">-</p>
                <!-- <p class="text-14 font-semibold">-{{this.$filters.toCurrency(transaction.payment_amount)}}</p> -->
            </div>

            <hr class="my-4" v-if="transaction.payment_promo">

            <div class="flex items-center justify-between">
                <p class="text-14 font-bold text-primary">Total Pembayaran</p>
                <p class="text-16 font-bold">{{this.$filters.toCurrency(transaction.payment_amount)}}</p>
            </div>

            <hr class="mt-4 mb-7 ">

            <div v-if="transaction.payment_status == 'waiting'" class="flex items-center justify-between">
                <button  @click="backToTransactionHistory()" class="bg-white border border-primary rounded-md py-3 text-primary w-full mr-3">
                    <p class="text-16 font-bold">Kembali</p>
                </button>
                <button  @click="redirectToPaymentGateway(transaction.payment_url)" class="bg-primary rounded-md py-3 text-white w-full ml-3">
                    <p class="text-16 font-bold">Bayar Sekarang</p>
                </button>
            </div>
            <button v-else-if="transaction.payment_status == 'expired' || transaction.payment_status == 'unpaid'" @click="reBuyCourse" class="bg-primary rounded-md py-3 px-12 text-white w-[326px]">
                <p class="text-16 font-bold">COBA LAGI</p>
            </button>
            <button v-else @click="goToCourse" class="bg-primary rounded-md py-3 px-12 text-white w-[326px]">
                <p class="text-16 font-bold">Lihat Materi</p>
            </button>

            
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            transaction_id: this.$route.params.transaction_id, 
            transaction: '',
        }
    },
    methods: {
        getTransactionDetail() {
            this.axios.get(`transaction/${this.transaction_id}`).then(response => {
                this.transaction = response.data.data
            }).catch(error => {
                console.log(error)
            })
        },
        redirectToPaymentGateway(payment_url) {
            if (payment_url) {
                window.location.href = payment_url
            } else {
                this.$router.push({ name: 'Checkout', params: { type: 'belajar-mandiri', id: this.transaction.course_id, transaction_id: this.transaction.transaction_id } })
            }
        },
        goToCourse() {
            this.$router.push({ name: 'Detail Belajar Mandiri', params: { course_id: this.transaction.course_id } })
        },
        reBuyCourse() {
            const body = {
                course_id: this.transaction.course_id,
            }
            this.axios.post('course/checkout', body).then(response => {
                const created_transaction_id = response.data.data.created_transaction_id
                this.$router.push({
                    name: 'Checkout',
                    params: { 
                        type: 'belajar-mandiri',
                        id: this.transaction.course_id,
                        transaction_id: created_transaction_id,
                    }
                })
            }).catch(error => {
                console.log(error.response);
            })
        },
        backToTransactionHistory() {
            this.$router.push({ name: 'Transaction History' })
        },
    },
    mounted() {
        this.getTransactionDetail()
    },
    computed: {
        transaction_expired() {
            return this.$filters.convertTime(this.transaction.payment_expired_at)
        }
    }
}
</script>

<style>

</style>