<template>
    <div class="relative bg-white rounded-lg shadow-lg text-left col-span-3 p-8 mb-5 mt-10 min-h-screen">

        <!-- Toast-->
        <div v-show="showToast" class="absolute flex space-x-2 justify-center inset-x-1/3 top-0">
            <div :class="isToastSuccess ? 'bg-green-100' : 'bg-red-100'" class="shadow-lg mx-auto w-auto px-5 max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block" id="static-example" role="alert" aria-live="assertive" aria-atomic="true" data-mdb-autohide="false">
                <div class="flex items-center justify-center">
                    <img v-if="isToastSuccess" src="../../../public/assets/img/icons/icon_success-standard-solid.png" alt="icon success">
                    <img v-else src="../../../public/assets/img/icons/icon_triangle-danger-f.png" alt="icon error">
                    <div class="p-3 rounded-b-lg break-words" :class="isToastSuccess ? 'bg-green-100 text-green-500' : 'bg-red-100 text-red-500'">
                        {{toast_message}}
                    </div>
                    <img v-if="isToastSuccess" @click="toggleToast()" src="../../../public/assets/img/icons/close_24px_success.png" alt="close_24px_success" data-mdb-dismiss="toast" class="cursor-pointer ">
                    <img v-else @click="toggleToast()" src="../../../public/assets/img/icons/close_24px.png" alt="close_24px" data-mdb-dismiss="toast" class="cursor-pointer ">
                </div>
            </div>
        </div>

        <p class="text-24 font-bold mb-9">Profil Saya </p>
        <div class="relative flex justify-center rounded-full mb-8 w-28 h-28 object-cover">
            <div @click="$refs.file.click()" class="flex flex-col items-center justify-center rounded-full transition duration-300 ease-in-out z-20 opacity-0 hover:bg-black hover:opacity-50 w-full h-full cursor-pointer">
                <input type="file" accept="image/*"  class="hidden" ref="file" @change="uploadImage($event)">
                <img src="../../../public/assets/img/icons/icon_camera.png" alt="icon_camera">
                <p class="text-12 font-bold text-white">Ubah Foto Profil</p>
            </div>
            <div class="absolute z-10 w-28 h-28">
                <img class="rounded-full h-full w-full object-cover" :src="preview_pic" />
            </div>
        </div>

        <!-- temporary hidden -->
        <!-- <div class="mb-8">
            <label for="teacher-level-field" class="block text-sm font-bold text-slate-700 mb-3">Level Guru</label>
            <input v-model="teacher_level" id="teacher-level-field" type="text" class="input-field capitalize w-full py-2" />
        </div> -->

        <div class="mb-8">
            <label for="teacher-level-field" class="block text-sm font-bold text-slate-700 mb-3">Nama Lengkap</label>
            <input v-model="name_form" id="teacher-level-field" type="text" class="capitalize w-full py-2" :class="name_form == '' ? 'border-b-2 border-red-500 focus:outline-none' : 'input-field'" />
            <p v-if="!name_form" class="text-14  text-red-500 ">Nama Lengkap tidak boleh kosong</p>
        </div>
        <div class="mb-8">
            <label for="email-field" class="block text-sm font-bold text-slate-700 mb-3">Email</label>
            <input v-model="email" id="email-field" type="email" class="input-field w-full py-2" disabled/>
        </div>
        <div class="mb-8">
            <label for="phone-field" class="block text-sm font-bold text-slate-700 mb-3">Nomor Handphone</label>
            <input v-model="phone_form" id="phone-field" type="text" class="input-field w-full py-2" disabled/>
        </div>
        <div class="mb-8">
            <label for="phone-field" class="block text-sm font-bold text-slate-700 mb-3">Alamat</label>
            <input v-model="address_form" id="phone-field" type="text" class="input-field w-full py-2" />
        </div>
        <div class="mb-8">
            <label for="phone-field" class="block text-sm font-bold text-slate-700 mb-3">Nama Sekolah</label>
            <input v-model="school_name_form" id="phone-field" type="text" class="input-field w-full py-2" />
        </div>
        <div v-show="isFormChanged" class="flex justify-end items-center">
            <button @click="resetForm" class="text-primary font-medium text-14 hover:text-white rounded border border-primary hover:bg-primary py-3 px-5 mt-2 mr-2">Batal</button>
            <button @click="saveProfile" class="text-white font-medium text-14 rounded bg-primary py-3 px-5 mt-2">Simpan</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            profile: this.$store.getters.userDetail.user,
            email: this.$store.getters.userDetail.email,
            profile_pic: '',
            preview_pic: '',
            // teacher_level: '-', // temporary hidden
            showToast: false,
            isToastSuccess: false,
            toast_message: 'Informasi akun Anda telah diperbarui',
            name_form: '',
            phone_form: '',
            address_form: '',
            school_name_form: '',
        }
    },
    methods: {
        getProfile() {
            this.$store.dispatch('getProfile')
            setInterval(() => {
                this.$router.go()
            }, 2000);
        },
        triggerFileInput() {
            document.getElementById('profile-pic-input').click()
        },
        uploadImage() {
            this.profile_pic = this.$refs.file.files[0];
            this.preview_pic = URL.createObjectURL(this.profile_pic);
        },
        saveProfile() {
            if (!this.name_form) {
                this.toastResponse()
                this.toggleErrorToast('Nama Lengkap tidak boleh kosong')
                return
            }
            // alamat
            this.address_form = this.address_form ? this.address_form : '-'
            // nama sekolah
            this.school_name_form = this.school_name_form ? this.school_name_form : '-'

            const formData = new FormData();
            formData.append('name', this.name_form);
            formData.append('address', this.address_form);
            formData.append('school_name', this.school_name_form);
            formData.append('fileUpload', this.profile_pic);

            this.toastResponse()
            this.axios.post('user/profile', formData).then(response => {
                this.toggleSuccessToast()
                this.getProfile()
            }).catch(error => {
                const error_messsage = 'Informasi akun Anda gagal diperbarui. <br> Silahkan coba lagi'
                this.toggleErrorToast(error_messsage)
                console.log(error.response)
            })
        },
        toastResponse() {
            // scroll to top
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            setInterval(() => {
                this.showToast = false
            }, 5000);
        },
        toggleToast() {
            this.showToast = !this.showToast
        },
        toggleSuccessToast() {
            this.isToastSuccess = true
            this.toast_message = 'Informasi akun Anda telah diperbarui'
            this.toggleToast()
        },
        toggleErrorToast(str) {
            this.isToastSuccess = false
            var parser = new DOMParser();
            var message = parser.parseFromString(str, 'text/html');

            this.toast_message = message.body.innerHTML
            this.toggleToast()
        },
        resetForm() {
            this.name_form = this.profile.name
            this.address_form = this.profile.address
            this.school_name_form = this.profile.school_name
            this.preview_pic = this.profile.photo
            this.phone_form = this.profile.phone
        }
        
    },
    mounted() {
        this.name_form = this.profile.name
        this.address_form = this.profile.address
        this.school_name_form = this.profile.school_name
        this.preview_pic = this.profile.photo
        this.phone_form = this.profile.phone
    },
    computed: {
        isFormChanged() {
            return this.name_form != this.profile.name || this.address_form != this.profile.address || this.school_name_form != this.profile.school_name || this.preview_pic != this.profile.photo
        }
    }
}
</script>

<style scoped>
.input-field {
    border: none;
    border-bottom: 2px solid #F2F2F2;
}
.input-field:focus {
    outline: none;
    border-bottom: 2px solid #313F6E;
}
</style>

