<template>
    <footer class="text-white bg-primary px-20 py-16" v-if="(!isLoggedIn || this.$route.name == 'Transaction Detail')">
        <div class="grid grid-cols-8 gap-6 ">
            <div class="col-span-3">
                <div class="flex flex-col text-left items-start justify-start">
                    <p class="text-40 font-bold mb-6">IKALSMART</p>
                    <p class="text-14">IKALSMART adalah wujud kontribusi untuk kemajuan pendidikan indonesia yang lebih
                        berkelanjutan. Tersedia produk dan layanan pendidikan yang beragam dengan memanfaatkan
                        kecanggihan teknologi. Eduucazone siap mengembangkan kompetensi kamu secara digital.</p>
                </div>
            </div>
            <div class="col-span-2 flex flex-col items-start">
                <router-link to="/about-us" class="mb-6 text-14">Tentang Kami</router-link>
                <router-link to="/" class="mb-6 text-14">IKALSMART Masterclass</router-link>
                <router-link to="/" class="mb-6 text-14">IKALSMART Workplace</router-link>
                <router-link to="/" class="mb-6 text-14">IKALSMART Campus</router-link>
                <router-link to="/" class="mb-6 text-14">Job Connector</router-link>
                <router-link to="/" class="mb-6 text-14">IKALSMART Studio</router-link>
            </div>
            <div class="col-span-3 flex flex-col items-start">
                <router-link to="/pusat-bantuan" class="mb-6 text-14">Pusat Bantuan</router-link>
                <router-link to="/terms-condition" class="mb-6 text-14">Syarat dan Ketentuan</router-link>
                <router-link to="/privacy-policy" class="mb-6 text-14">Kebijakan Privasi</router-link>
                <router-link to="/" class="mb-6 text-14">Temukan Kami</router-link>
                <div class="font-bold">
                    <p class="mb-4 text-left">Hubungi Kami</p>
                    <div class="flex justify-start items-center mb-4">
                        <img class="mr-3" src="../../../public/assets/img/icons/contact-us-mail.png" alt="contact us via mail">
                        <p><a href="mailto:csikalsmart@gmail.com">ikalsmart45@gmail.com</a></p>
                    </div>
                    <div class="flex justify-start items-center mb-4">
                        <img class="mr-3" src="../../../public/assets/img/icons/contact-us-phone.png" alt="contact us via phone">
                        <p>+62821 1029 2017</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col items-start justify-start">
            <p class="font-bold text-14 mb-6">Dapatkan kami juga di</p>
            <div class="flex">
                <button @click="downloadAndroidApp" class="flex items-center justify-center px-5 py-3 rounded bg-[#4758AC] mr-5">
                    <img class="mr-1" src="../../../public/assets/img/icons/google-play.png" alt="google-play"><span
                        class="text-14 font-semibold">Download Android</span>
                </button>
                <button class="flex items-center justify-center px-5 py-3 rounded bg-[#4758AC]">
                    <img class="mr-1" src="../../../public/assets/img/icons/apple.png" alt="apple"><span
                        class="text-14 font-semibold">Download IOS</span>
                </button>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            isLoggedIn: this.$store.getters.isLoggedIn,
        }
    },
    methods: {
        downloadAndroidApp() {
            window.open('https://play.google.com/store/apps/details?id=com.educazonemobile', '_blank')
        },
    }
}
</script>