<template>
    <div class="text-left px-20 mb-40 mt-10" v-if="!isLoggedIn">
        <div class="bg-white rounded shadow-lg p-14" style="min-height: 843px;">
            <p class="text-4xl font-bold mb-2">Mentor</p>
            <p class="text-16 mb-10">Kembangankan keahlian Anda dengan belajar langsung dari Mentor Educazone yang
                handal</p>

            <div class="grid grid-cols-4">
                <div class="text-center mb-16">
                    <div class="mb-4">
                        <img class="mx-auto rounded-full" src="../../public/assets/img/mentor/mentor_2.png"
                            alt="mentor_2">
                    </div>
                    <p class="text-black text-18 font-medium mb-1">Deka Solomon</p>
                    <p class="text-primary font-bold text-14 mb-1">Senior Product Designer</p>
                    <div class="flex items-center justify-center font-bold text-14 mb-3">
                        <p class="text-14 font-bold mb-3">Free</p>
                    </div>
                    <button
                        class="text-primary font-medium text-14 hover:text-white rounded border border-primary hover:bg-primary p-1 mt-2 w-[130px]">Chat
                        Mentor</button>
                </div>
            </div>
        </div>
    </div>
    <div class="h-full" v-else>
        <div class="bg-white rounded-lg shadow-lg text-left col-span-3 p-8 h-full">
            <!-- Title -->
            <div class="text-left mb-8 ">
                <p class="text-24 font-bold">Mentor </p>
                <p class="text-16">Kembangkan keahlian Anda dengan belajar langsung dari Mentor educazone yang handal
                </p>
            </div>
            <!-- Tab Content -->
            <ul class="nav nav-tabs grid grid-cols-2 laptop:flex flex-row list-none border-b pl-0 mb-8 " id="tabs-tab"
                role="tablist">
                <li class="col-span-1 nav-item" role="presentation">
                    <a href="#ragam-materi-belajar" class="
                        nav-link
                        selected:text-primary
                        block
                        text-center
                        font-medium
                        text-14
                        laptop:text-18
                        leading-tight
                        border-x-0 border-t-0 border-b-2 border-transparent
                        laptop:px-6
                        py-3
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent
                        active
                    " id="ragam-materi-belajar-tab" data-bs-toggle="pill" data-bs-target="#ragam-materi-belajar"
                        role="tab" aria-controls="ragam-materi-belajar" aria-selected="true" data-mdb-ripple="true"
                        data-mdb-ripple-color="gray" ref="tab_1">Daftar Mentor</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a href="#materi-yang-dipilih" class="
                        nav-link
                        block
                        font-medium
                        text-center
                        text-14
                        laptop:text-18
                        leading-tight
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-0
                        laptop:px-6
                        py-3
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent
                    " id="materi-yang-dipilih-tab" data-bs-toggle="pill" data-bs-target="#materi-yang-dipilih"
                        role="tab" aria-controls="materi-yang-dipilih" aria-selected="false" data-mdb-ripple="true"
                        data-mdb-ripple-color="gray" ref="tab_2">Percakapan Sebelumnya</a>
                </li>
            </ul>

            <div class="tab-content overflow-auto" id="tabs-tabContent">
                <!-- Daftar Mentor -->
                <div class="tab-pane fade show active mb-8" id="ragam-materi-belajar" role="tabpanel"
                    aria-labelledby="ragam-materi-belajar-tab">
                    <div class="grid grid-cols-2 laptop:grid-cols-4">
                        <div class="text-center mb-16" v-for="mentor in mentors" :key="mentor.id">
                            <div class="mb-4">
                                <img class="mx-auto rounded-full h-28  w-28" :src="mentor.photo" :alt="mentor.name"
                                    loading="lazy">
                            </div>
                            <p class="text-black text-18 font-medium mb-1">{{ mentor.name }}</p>
                            <p class="text-primary font-bold text-14 mb-1">{{ mentor.profession }}</p>
                            <div class="flex items-center justify-center mb-3">
                                <p class="text-14 font-bold ">Free</p>
                            </div>
                            <button @click="chatMentor(mentor)"
                                class="text-primary font-medium text-14 hover:text-white rounded border border-primary hover:bg-primary p-1 mt-2 w-[130px]">Chat
                                Mentor</button>
                        </div>
                    </div>
                </div>

                <!-- Percakapan Sebelumnya -->
                <div class="tab-pane fade show mb-8" id="materi-yang-dipilih" role="tabpanel"
                    aria-labelledby="materi-yang-dipilih-tab">
                    <div class="flex">
                        <div v-if="!chat_history && !is_new_mentor" class="mx-auto text-center align-middle">
                            <div class="mb-8">
                                <img src="../../public/assets/img/empty-state.png" alt="empty-state" class="mx-auto">
                                <p class="font-bold text-3xl mb-2 text-center">
                                    Belum Ada Percakapan
                                </p>
                                <p class="text-16">Anda belum mempunyai percakapan dengan Mentor. <br>
                                    Yuk, mulai percakapan dengan Mentor
                                </p>
                            </div>
                            <button @click="switchTab"
                                class="bg-primary text-16 font-semibold text-center text-white w-full py-4"
                                data-mdb-ripple="true" data-mdb-ripple-color="blue">Mulai Percakapan</button>
                        </div>
                        <div v-else class="bg-white rounded w-full laptop:p-0">
                            <div class="grid grid-cols-1 laptop:grid-cols-5 h-screen">
                                <div class="col-span-1 laptop:col-span-2">
                                    <p class="hidden laptop:flex font-bold text-18 mb-8">Chat Mentor</p>
                                    <router-link v-if="is_new_mentor" @click="selectMentor(new_mentor)"
                                        :to="`/mentor/${new_mentor.user_id}`"
                                        class="laptop:select-mentor flex items-center text-16 py-4 px-3 h-12 text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out mb-4">
                                        <img :src="new_mentor.photo" alt="mentor photo"
                                            class="rounded-full mr-4 w-14 laptop:h-10 laptop:w-10">
                                        <p class="font-bold text-16 capitalize">{{ new_mentor.name }}</p>
                                    </router-link>
                                    <router-link @click="selectMentor(history.mentor)" v-for="history in chat_history"
                                        :key="history._id" :to="`/mentor/${history.mentor_id}`"
                                        class="select-mentor flex items-center border-b-2 laptop:border-b-0 text-16 py-4 px-3 laptop:h-12 text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out mb-0 laptop:mb-4">
                                        <img :src="history.mentor.photo" alt="mentor photo"
                                            class="rounded-full mr-4 w-14 laptop:h-10 laptop:w-10">
                                        <div class="flex flex-col items-start justify-center w-full">
                                            <div class="flex items-center justify-between w-full">
                                                <p class="font-bold text-16 capitalize">{{ history.mentor.name }}</p>
                                                <p class="text-12">{{ formatChatTime(history.last_updated) }}</p>
                                            </div>
                                            <p class="text-14">{{ history.last_message }}</p>
                                        </div>
                                    </router-link>
                                </div>

                                <!-- Chat Window -->
                                <div
                                    class="col-span-3 hidden laptop:flex flex-col items-start justify-between bg-gray-50 rounded h-full w-full overflow-scroll">
                                    <div v-if="Object.keys(selected_mentor).length"
                                        class="flex items-center justify-start border-b-2 p-6 w-full">
                                        <img class="rounded-full w-16 " :src="selected_mentor.photo" alt="">
                                        <div class="ml-4">
                                            <p class="font-bold text-16 capitalize">{{ selected_mentor.name }}</p>
                                            <p class="capitalize ">{{ selected_mentor.profession }}</p>
                                        </div>
                                    </div>
                                    <div class="flex flex-col w-full overflow-scroll h-full" id="chat-window">
                                        <div v-for="chat in chats" :key="chat._id" class="flex flex-col p-6 w-1/2"
                                            :class="chat.to == mentor_id ? 'self-end items-end' : 'self-start items-start'">
                                            <div class="text-left text-white bg-primary rounded-3xl p-4"
                                                :class="chat.to == mentor_id ? 'rounded-br-none' : 'rounded-bl-none'">
                                                {{ chat.content }}
                                            </div>
                                            <p class="text-14 text-gray-500 mt-2 ">
                                                {{ this.$filters.convertTime(chat.created_at) }} . Terkirim</p>
                                        </div>
                                    </div>
                                    <div v-if="Object.keys(selected_mentor).length" class="flex w-full px-4">
                                        <div class="flex justify-between bg-white rounded-t-2xl w-full">
                                            <input @keyup.enter="sendMessage" v-model="message" type="text"
                                                placeholder="Tulis pesanmu di sini..."
                                                class="text-16 p-7 w-full outline-none">
                                            <div class="flex items-center justify-center">
                                                <img @click="sendMessage"
                                                    src="../../public/assets/img/icons/icon_send.png" alt="icon_send"
                                                    class="mr-5 h-1/3 cursor-pointer">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Offcanvas -->
                                <div id="chatOffcanvas"
                                    class="offcanvas offcanvas-end fixed bottom-0 h-full flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 right-0 border-none w-full"
                                    tabindex="-1" aria-labelledby="chatOffcanvasLabel">
                                    <div
                                        class="flex flex-col justify-between col-span-3 bg-gray-50 rounded h-full w-full">
                                        <div class="bg-white shadow flex items-center justify-start py-2">
                                            <span data-bs-dismiss="offcanvas" class="px-5 py-4">
                                                <i class="fa-solid fa-angle-left fa-2xl"></i>
                                            </span>
                                            <div v-if="Object.keys(selected_mentor).length">
                                                <p class="font-bold text-16 capitalize">{{ selected_mentor.name }}</p>
                                                <p class="capitalize ">{{ selected_mentor.profession }}</p>
                                            </div>
                                        </div>
                                        <div
                                            class="flex flex-col justify-start h-full w-full overflow-scroll max-h-full">
                                            <div v-for="chat in chats" :key="chat._id" class="flex flex-col p-6 w-1/2"
                                                :class="chat.to == mentor_id ? 'self-end items-end' : 'self-start items-start'">
                                                <div class="text-left text-white bg-primary rounded-3xl p-4"
                                                    :class="chat.to == mentor_id ? 'rounded-br-none' : 'rounded-bl-none'">
                                                    {{ chat.content }}
                                                </div>
                                                <p class="text-14 text-gray-500 mt-2 ">
                                                    {{ this.$filters.convertTime(chat.created_at) }} . Terkirim</p>
                                            </div>
                                        </div>
                                        <div v-if="Object.keys(selected_mentor).length" class="flex w-full px-4">
                                            <div class="flex justify-between bg-white rounded-t-2xl w-full">
                                                <input @keyup.enter="sendMessage" v-model="message" type="text"
                                                    placeholder="Tulis pesanmu di sini..."
                                                    class="text-16 p-7 w-full outline-none">
                                                <div class="flex items-center justify-center">
                                                    <img @click="sendMessage"
                                                        src="../../public/assets/img/icons/icon_send.png"
                                                        alt="icon_send" class="mr-5 h-1/3 cursor-pointer">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import io from 'socket.io-client';

export default {
    data() {
        return {
            isLoggedIn: this.$store.getters.isLoggedIn,
            user: this.$store.getters.userDetail,
            mentor_id: '',
            mentors: [],
            selected_mentor: {},
            page: 1,
            limit: 10,
            keyword: '',
            chat_history: [],
            chats: [],
            message: '',
            is_new_mentor: false,
            new_mentor: {},
            socket: null
        }
    },
    methods: {
        getMentors() {
            this.axios.get(`mentor/list?page=${this.page}&limit=${this.limit}&keyword=${this.keyword}`).then(response => {
                this.mentors = response.data.data
            }).catch(error => {
                console.log(error.response);
            })
        },
        getMentorChatHistory() {
            this.axios.get('chat/teacher-chat-history').then(response => {
                this.chat_history = response.data.data
                this.getMentorDataFromCoursePage()
            }).catch(error => {
                console.log(error.response);
            })
        },
        selectMentor(mentor) {
            this.chats = []
            this.selected_mentor = mentor
            this.mentor_id = mentor.user_id
            this.getChats()
            if (window.innerWidth < 1024) {
                $('#chatOffcanvas').offcanvas('show')
            }
        },
        getChats() {
            this.axios.get(`chat/user?user_id=${this.mentor_id}`).then(response => {
                if (response.data.data) {
                    this.chats = response.data.data.reverse()
                    const last_message = this.chats[this.chats.length-1] //cannot use chats[0] because it's reversed

                    // update chat history last message
                    this.chat_history.filter(history => {
                        return history.mentor_id == this.mentor_id
                    })[0].last_message = last_message.content;
                }
            }).catch(error => {
                console.log(error.response);
            })
        },
        chatMentor(mentor) {
            this.switchTab()
            this.$router.push(`/mentor/${mentor.user_id}`)
            this.mentor_id = mentor.user_id
            this.selectMentor(mentor)
            this.checkIfChatWithNewMentor(mentor)
        },
        checkIfChatWithNewMentor(mentor) {
            var filtered_history
            if (this.chat_history) {
                filtered_history = this.chat_history.filter(history => {
                    return history.mentor_id == mentor.user_id
                });
            }
            if (filtered_history && filtered_history.length > 0) {
                this.is_new_mentor = false;
            } else {
                this.is_new_mentor = true;
                this.$router.push(`/mentor/${mentor.user_id}`)
            }
            this.new_mentor = mentor
        },
        sendMessage() {
            const body = {
                user_id: this.mentor_id,
                type: 'text',
                content: this.message
            }
            this.message = ''
            this.axios.post('chat/send-message', body).then(response => {
                this.getChats()
            }).catch(error => {
                console.log(error.response);
            })
        },
        switchTab() {
            if ($('#ragam-materi-belajar-tab').hasClass('active')) { // if tab 1 is active

                const element = document.getElementById("chat-window");
                if (element) {
                    console.log(element);
                    // scroll to bottom
                    element.scrollTop = element.scrollHeight - element.clientHeight;
                    element.scrollIntoView(false);
                }

                this.$refs.tab_2.click()
            } else {
                this.$refs.tab_1.click()
            }
        },
        getMentorDataFromCoursePage() {
            if (this.$route.params.from_course) {
                this.switchTab()
                this.checkIfChatWithNewMentor(JSON.parse(this.$route.params.mentor_data))
                this.selectMentor(JSON.parse(this.$route.params.mentor_data))
            }
        },
        formatChatTime(chatTime) {
            return this.$filters.convertTime(chatTime)
        },
        setupSocketConnection() {
            const user_id = this.user.user.user_id
            this.socket = io(`https://api.educazone.id/?user_id=${user_id}`, {
                transports: ["websocket", 'polling'],
                upgrade: true,
            });

            this.socket.on('connect', () => {
                // console.log('Socket connected', this.socket.connected, this.socket.id, this.socket);
                console.log('Socket connected');
            });

            this.socket.on("connect_error", (err) => {
                console.log(`connect_error due to ${err.message}`, err);
            })

            this.socket.on('response-msg', (message) => { // on opening chat window
                console.log('new message in app', message);
                this.chats.push(message);
                
                // update chat history last message
                this.chat_history.filter(history => {
                    return history.mentor_id == message.from
                })[0].last_message = message.content;
            });

            this.socket.on('disconnect', () => {
                console.log('Socket disconnected');
            });
        },
        disconnect() {
            if (this.socket) {
                this.socket.disconnect();
            }
        },
    },
    mounted() {
        this.getMentors()
        this.getMentorChatHistory()
        this.setupSocketConnection()
    },
    computed: {
        getRouteParams() {
            this.mentor_id = this.$route.params.mentor_id;
            const mentor = this.mentors.filter(mentor => {
                mentor.user_id == this.mentor_id
            })[0]
            this.chatMentor(mentor)
        },
    },
    watch: {
        $route(to, from) {
            if (to.name != "Chat Mentor History") {
                this.disconnect()
            }
        }
    },
    beforeUnmount() {
        this.disconnect();
    }
}
</script>

<style scoped>
ul li a.active {
    font-weight: bold;
    color: #313F6E !important;
    border-bottom: 5px solid #313F6E !important;
}

.select-mentor {
    text-overflow: ellipsis;
}

.select-mentor.router-link-exact-active {
    font-weight: bold;
    color: #313F6E;
    background: rgba(49, 63, 110, 0.2);
}

.select-mentor.router-link-exact-active::after {
    display: none;
    content: "";
    margin-right: -0.1rem;
    position: absolute;
    right: -10px;
    width: 5px;
    height: 30px;
    background: #313F6E;
    border-radius: 15px;
}
</style>

