<template>
    <div class="mt-10">
        <div class="shadow rounded-lg mx-auto h-auto py-7 px-20 bg-white" style="width: 596px;">
            <div class="flex flex-col">
                <img src="../../public/assets/img/transaction-success.png" alt="" class="mx-auto" style="width: 362px;">
                <p class="font-bold text-32 mb-2">Penukaran Token Berhasil</p>
                <p class="text-16 mb-6">Yuk mulai belajar</p>
                <button @click="returnToBelajarMandiriPage" class="bg-primary text-white font-semibold text-center text-14 rounded py-4">Belajar Sekarang</button>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            payment_method: 'token'
        }
    },
    methods: {
        returnToBelajarMandiriPage() {
            console.log(this.$route.params.id);
            return 
            this.$router.push({name: 'Detail Belajar Mandiri', params: {course_id: this.$route.params.id}})
        }
    }
}
</script>

<style>

</style>