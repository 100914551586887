<template>
    <div class="h-screen">
        <PDFViewer
            class="h-screen w-screen"
            :source="module_url"
            :controls="pdf_control"
            id="myFrame"
        />
    </div>
</template>

<script>
import PDFViewer from 'pdf-viewer-vue'
export default {
    components: {
        PDFViewer
    },
    data() {
        return {
            module_url: this.$store.getters.getCourseModuleUrl,
            pdf_control: [
                'rotate',
                'zoom',
                'catalog',
                'switchPage',
            ],
            myFrame: null
        }
    },
    methods: {
        setupViewer() {
            $("#myFrame").on("load", function (e) {
                $(this).contents().bind('contextmenu', function (e) {
                    return false;
                })
            });
        }
    },
    mounted() {
        this.setupViewer();
    }
}
</script>
<style>

</style>