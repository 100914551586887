<template>
    <div class="relative py-20">
        <div class="absolute w-full top-0 left-0 bg-gradient"></div>
        <div class="relative">
            <div class="shadow-lg rounded-lg p-7 bg-white mx-auto mb-5" style="width: 800px">
                <div class="flex items-center justify-between">
                    <div class="flex flex-col items-start justify-center">
                        <p class="text-24 font-bold mb-4 ">Token</p>
                        <div class="flex justify-start items-center">
                            <img src="../../public/assets/img/icons/teacher-token.png" alt="teacher-token" class="mr-2" height="31" width="31">
                            <span class="text-32 text-red-500 font-bold m-0">0</span>
                        </div> 
                    </div>
                    <button data-bs-toggle="modal" data-bs-target="#topup-token-modal" class="flex items-center justify-center text-white text-14 font-semibold bg-primary rounded py-4 px-11">
                        <img src="../../public/assets/img/icons/mdi_wallet-plus.svg" alt="mdi_wallet-plus">
                        Topup Token
                    </button>
                </div>
                <hr class="my-6 ">
                <div class="text-left bg-blue-100 border border-blue-400 rounded-xl py-6 px-5">
                    <p class="text-16 font-bold mb-3">Cara Topup Token</p>
                    <ol class="list-decimal text-14 px-5">
                        <li>Klik tombol top up token</li>
                        <li>Kemudian kamu akan menuju halaman product jumlah token pada halaman shopee</li>
                        <li>Pilih jumlah token yang akan dibeli</li>
                        <li>Lakukan pembelian pada shopee</li>
                        <li>Tambahkan pada notes pembelian alamat email yang anda gunakan pada aplikasi educazone</li>
                        <li>Lakukan checkout pada shopee</li>
                        <li>Lakukan pembayaran sesuai yang anda pilih pada halaman checkout shopee</li>
                        <li>Admin educazone akan melakukan topup melalui system internal pada akun pembeli</li>
                        <li>Admin educazone akan melakukan konfirmasi pada pembeli bahwa sudah dilakukan topup pada aplikasi educazone</li>
                        <li>Kamu akan mendapatkan email konfirmasi token sudah di top up dan status pembelian tokenmu pada riwayat transsaksi berubah menjadi berhasil</li>
                    </ol>
                </div>
            </div>

            <div class="shadow-lg rounded-lg p-7 bg-white mx-auto mb-5 text-left" style="width: 800px">
                <p class="text-24 font-bold mb-7">Riwayat Penggunaan Token</p>

                <!-- Tab Content -->
                <ul class="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b pl-0 mb-8 " id="tabs-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                    <a href="#topup" class="
                        nav-link
                        block
                        font-medium
                        text-18
                        leading-tight
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-6
                        py-3
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent
                        active
                    " id="topup-tab" data-bs-toggle="pill" data-bs-target="#topup" role="tab" aria-controls="topup"
                        aria-selected="true"
                        data-mdb-ripple="true" data-mdb-ripple-color="gray"
                        >Tambah</a>
                    </li>
                    <li class="nav-item" role="presentation">
                    <a href="#reedem" class="
                        nav-link
                        block
                        font-medium
                        text-18
                        leading-tight
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-6
                        py-3
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent
                    " id="reedem-tab" data-bs-toggle="pill" data-bs-target="#reedem" role="tab"
                        aria-controls="reedem" aria-selected="false"
                        data-mdb-ripple="true" data-mdb-ripple-color="gray"
                        >Tukar</a>
                    </li>
                </ul>

                <div class="tab-content overflow-auto" id="tabs-tabContent">
                    <!-- Topup Tab -->
                    <div class="tab-pane fade show active mb-8" id="topup" role="tabpanel" aria-labelledby="topup-tab">
                        <div class="flex px-6 items-center justify-start list-none text-14 mb-5">
                            <p class="text-14 font-bold mr-5">Status</p>
                            <li class="relative mr-2">
                                <input class="sr-only peer" type="radio" v-model="topup_filter" value="processed" id="processed-topup">
                                <label id="processed-topup-label" for="processed-topup" class="text-primary font-bold rounded-full px-4 py-2 flex p-5 border border-primary cursor-pointer focus:outline-none peer-checked:bg-primary peer-checked:text-white">
                                    Diproses
                                </label>
                            </li>
                            <li class="relative mr-2">
                                <input class="sr-only peer" type="radio" v-model="topup_filter" value="succeeded" id="success-topup">
                                <label for="success-topup" class="text-primary font-bold rounded-full px-4 py-2 flex p-5 border border-primary cursor-pointer focus:outline-none peer-checked:bg-primary peer-checked:text-white">
                                    Berhasil
                                </label>
                            </li>
                        </div>
                        <hr>
                        <div class="border-b mt-5 py-2">
                            <p class="text-14 font-bold mb-4">Sabtu, 24 Maret 2022</p>
                            <div class="flex items-center justify-between">
                                <div class="flex">
                                    <img src="../../public/assets/img/icons/topup.svg" alt="topup icon" class="mr-2 ">
                                    <div class="flex flex-col justify-center">
                                        <p class="text-16 font-bold">Tambah Token</p>
                                        <p class="text-12 font-semibold text-primary">Dalam Proses</p>
                                    </div>
                                </div>
                                <p class="text-16 font-bold">200 Token</p>
                            </div>
                        </div>
                        <div class="border-b mt-5 py-2">
                            <p class="text-14 font-bold mb-4">Sabtu, 24 Maret 2022</p>
                            <div class="flex items-center justify-between">
                                <div class="flex">
                                    <img src="../../public/assets/img/icons/topup.svg" alt="topup icon" class="mr-2 ">
                                    <div class="flex flex-col justify-center">
                                        <p class="text-16 font-bold">Tambah Token</p>
                                        <p class="text-12 font-semibold text-primary">Dalam Proses</p>
                                    </div>
                                </div>
                                <p class="text-16 font-bold">200 Token</p>
                            </div>
                        </div>
                        <!-- <div class="flex">
                            <div class="mx-auto text-center align-middle">
                                <img src="../../public/assets/img/empty-state.png" alt="empty-state" class="mx-auto mb-8">
                                <p class="font-bold text-36 mb-2">Belum Ada Riwayat</p>
                            </div>
                        </div> -->
                    </div>

                    <!-- Reedem Tab -->
                    <div class="tab-pane fade show mb-8" id="reedem" role="tabpanel" aria-labelledby="reedem-tab">
                        <div class="flex">
                            <div class="mx-auto text-center align-middle">
                                <img src="../../public/assets/img/empty-state.png" alt="empty-state" class="mx-auto mb-8">
                                <p class="font-bold text-36 mb-2">Belum Ada Riwayat</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- Topup Token Modal -->
        <div id="topup-token-modal"  class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" tabindex="-1" aria-labelledby="TopupTokenModal" aria-modal="true" role="dialog">
            <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
                <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-xl outline-none text-current p-5">
                    <div class="flex justify-between items-center">
                        <div class="w-4 h-4"></div>
                        <h5 class="text-16 font-bold leading-normal text-gray-800">
                            Pilih Nominal
                        </h5>
                        <button type="button"
                        class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                        data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div v-for="item in price_list" :key="item.value">
                            <div class="flex items-center border-t w-full">
                                <input class="peer mr-4" type="radio" v-model="topup_amount" :value="`${item.value}`" :id="`token-${item.value}`">
                                <label :id="`token-${item.value}-label`" :for="`token-${item.value}`" class="flex justify-between items-center text-16 font-semibold cursor-pointer peer-checked:text-blue-400 py-5 h-full w-full">
                                    <p>{{item.value}} Token</p>
                                    <p>{{$filters.toCurrency(item.price)}}</p>
                                </label>
                            </div>
                        </div>
                        <div class="flex justify-center items-center mt-3 ">
                            <button data-bs-dismiss="modal" class="text-14 font-semibold text-primary border border-primary flex items-center justify-center rounded-md py-3 w-32 mr-2 ">Batal</button>
                            <button data-bs-dismiss="modal" class="border border-primary bg-primary flex items-center justify-center rounded-md">
                                <button @click="topupToken" class="text-14 font-semibold text-white py-3 w-32" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">Lanjutkan</button>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- After Topup Modal -->
        <div class="offcanvas offcanvas-bottom fixed bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 left-0 right-0 border-none h-screen max-h-full" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
            <div class="offcanvas-body flex flex-col items-center justify-center flex-grow p-4 overflow-y-auto mx-auto">
                <img class="loading-spin z-10" width="175" height="175" src="../../public/assets/img/icons/loading.svg" alt="loading">
                <p class="text-36 font-bold mb-3 z-20 ">Tunggu Sebentar</p>
                <p>Anda akan diarahkan ke halaman Shopee <br>
                    untuk melanjutkan proses penambahan Token
                </p>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            topup_filter: "processed",
            redeem_filter: "processed",
            price_list: [
                {value: 100, price: 100000},
                {value: 200, price: 200000},
                {value: 500, price: 500000},
                {value: 1000, price: 800000},
            ],
            topup_amount: 100,
        }
    },
    methods: {
        topupToken() {
            
        }
    }
}
</script>

<style scoped>
.bg-gradient {
    background: linear-gradient(90deg, #313F6E 0%, #5B6CA5 24.69%, #596795 70.8%, #293D80 100%);
    height: 210px;
}
ul li a.active {
  font-weight: bold;
  color: #0099DC !important;
  border-bottom: 5px solid #009EEB !important;
}
.loading-spin {
    animation: spin 2s linear infinite;
}
</style>