<template>
    <div>
        <div class="flex justify-center items-center my-14 ">
            <img src="../../public/assets/img/logo/main-logo.png" alt="main-logo-big" />
        </div>
        <div class="text-left bg-white rounded shadow p-10 laptop:p-20 laptop:mx-20">
            <p class="text-24 font-bold mb-2 text-center laptop:text-left">KEBIJAKAN PRIVASI</p>
            <p class="text-16 font-semibold text-gray-500 mb-7 text-center laptop:text-left">Berlaku efektif sejak 14 Februari 2022</p>
            <p class="mb-7">Kerahasiaan Informasi Pribadi adalah hal yang terpenting bagi PT. AZZUKHRUF INDONESIAN TRADE, <b>(“Kami”)</b>. Kami berkomitmen untuk melindungi dan menghormati privasi pengguna <b>(“Anda”)</b> saat mengakses dan menggunakan aplikasi, situs web (www.ikalsmart.id), fitur, teknologi, konten dan produk yang Kami sediakan (selanjutnya, secara bersama-sama disebut sebagai <b>“Platform”</b>).</p>
            <p class="mb-7"><b>Harap membaca Kebijakan Privasi ini secara seksama sebelum Anda mulai menggunakan Platform
                Kami, karena peraturan ini berlaku pada penggunaan Anda terhadap Platform Kami.</b> 
                Kebijakan Privasi ini
                mengatur landasan dasar mengenai bagaimana Kami menggunakan informasi pribadi yang Kami kumpulkan
                dan/atau Anda berikan <b>(“Informasi Pribadi”)</b>. Kebijakan Privasi ini berlaku bagi seluruh pengguna
                Platform, kecuali diatur pada Kebijakan Privasi yang terpisah. Mohon membaca Kebijakan Privasi Kami
                dengan seksama sehingga Anda dapat memahami pendekatan dan cara Kami dalam menggunakan informasi
                tersebut.</p>

            <p>
                Kebijakan Privasi ini mencakup hal-hal sebagai berikut:
            <ul class="mb-7">
                <li>A. Informasi Pribadi yang Dapat Kami Kumpulkan</li>
                <li>B. Penggunaan Informasi Pribadi</li>
                <li>C. Pengungkapan Informasi Pribadi</li>
                <li>D. Penyimpanan Informasi Pribadi</li>
                <li>E. Hak Anda</li>
                <li>F. Kebijakan Cookies </li>
                <li>G. Pengakuan dan Persetujuan</li>
                <li>H. Materi Pemasaran</li>
                <li>I. Perubahan dalam Kebijakan Privasi Kami</li>
                <li>J. Cara Menghubungi Kami</li>
            </ul>
            Dengan mengunjungi dan/atau mendaftar Akun pada Platform Kami, Anda menerima dan menyetujui pendekatan dan
            cara-cara yang digambarkan dalam Kebijakan Privasi ini.
            </p>
            <br>

            <ol class="ol">
                <li>
                    <b>A. INFORMASI PRIBADI YANG DAPAT KAMI KUMPULKAN</b>
                    <p>Kami dapat mengumpulkan Informasi Pribadi berupa:
                        <ol>
                            <li>a. <b>Informasi yang Anda berikan</b>. Anda dapat memberikan informasi melalui formulir elektronik pada Platform Kami maupun dengan berkorespondensi melalui telepon, surat elektronik, dan sebagainya. Informasi ini meliputi informasi yang Anda berikan ketika mendaftar pada Platform Kami, berlangganan layanan Kami, mencari produk, berpartisipasi dalam diskusi online atau fungsi media sosial lain pada Platform Kami, mengikuti kompetisi, promosi, atau survei, serta ketika Anda melaporkan masalah dengan Platform Kami. Informasi yang Anda berikan dapat meliputi nama, alamat, alamat surat elektronik, nomor telepon, informasi finansial dan kartu kredit, deskripsi pribadi, foto, dan data lainnya. Kami dapat meminta Anda untuk melakukan verifikasi terhadap informasi yang Anda berikan, untuk memastikan akurasi dari informasi tersebut.</li>
                            <li>b. <b>Informasi yang Kami kumpulkan</b>. Untuk setiap kunjungan Anda, Kami dapat mengumpulkan informasi berikut secara otomatis:
                                <ol>
                                    <li>i. Informasi teknis, meliputi alamat Protokol Internet (IP address) yang digunakan untuk menghubungkan komputer Anda dengan internet, informasi log in Anda, jenis dan versi perambah (browser) yang digunakan, pengaturan zona waktu, jenis dan versi ekstensi perambah (browser plug-in), sistem operasi dan platform.</li>
                                    <li>ii. Informasi tentang kunjungan Anda, termasuk daftar lengkap Lokator Sumber Seragam (Uniform Resource Locators atau URL) yang dikunjungi menuju, melalui, dan dari Platform Kami (termasuk tanggal dan waktu); produk yang Anda lihat atau cari; waktu respon halaman, masalah pengunduhan, lama kunjungan pada halaman tertentu, informasi interaksi pada halaman (seperti pengguliran, klik, maupun pergerakan tetikus), metode yang digunakan untuk meninggalkan situs, serta nomor telepon yang digunakan untuk menghubungi layanan pelanggan Kami.</li>
                                    <li>iii. Data nilai Anda, termasuk namun tidak terbatas pada hasil ujian Anda yang diperoleh melalui Platform, serta data akademis lain.</li>
                                </ol>
                            </li>
                        </ol>
                    </p>
                </li>
                
                <li>
                    <b>B. PENGGUNAAN INFORMASI PRIBADI</b>
                    <p>Kami menggunakan Informasi Pribadi dengan cara-cara dan untuk tujuan berikut:
                        <ol>
                            <li>A. <b>Informasi yang Anda berikan</b>. Kami akan menggunakan informasi ini:
                                <ol>
                                    <li>i. Untuk menjalankan kewajiban Kami dalam menyediakan informasi, produk, dan jasa kepada Anda;</li>
                                    <li>ii. Untuk menyediakan informasi terkait produk dan jasa lain yang Kami tawarkan; guna menyediakan Anda, atau mengizinkan pihak ketiga untuk menyediakan Anda, informasi tentang produk dan jasa yang Kami anggap dapat menarik minat Anda. Jika Anda adalah pelanggan lama, Kami dapat menghubungi Anda secara elektronik atau cara-cara lain dengan informasi tentang produk dan jasa Kami;</li>
                                    <li>iii. Untuk memberitahukan Anda tentang perubahan pada jasa Kami; dan </li>
                                    <li>iv. Untuk memastikan bahwa konten dari Platform Kami disajikan dengan cara yang paling efektif bagi Anda.</li>
                                </ol>
                            </li>
                            <li>B. <b>Informasi yang Kami kumpulkan</b>. Kami akan menggunakan informasi ini:
                                <ol>
                                    <li>i. Untuk mengelola Platform dan operasi internal Kami, termasuk pencarian sumber masalah (troubleshooting), analisis data, pengujian, penelitian, serta tujuan-tujuan statistik dan survei lainnya;</li>
                                    <li>ii. Untuk memperbaiki Platform Kami sehingga konten dipastikan dapat disajikan dengan cara yang paling efektif untuk Anda;</li>
                                    <li>iii. Untuk memungkinkan Anda berpartisipasi dalam fitur layanan interaktif Kami, ketika Anda inginkan;</li>
                                    <li>iv. Sebagai bagian dari usaha dalam memastikan keselamatan dan keamanan Platform Kami;</li>
                                    <li>v. Untuk mengukur dan memahami efektivitas periklanan  yang Kami lakukan kepada Anda dan pihak lain, serta menyajikan iklan produk dan jasa yang relevan bagi Anda;</li>
                                    <li>vi. Untuk memberi masukan dan rekomendasi kepada Anda dan pengguna lain dalam Platform Kami mengenai produk dan jasa yang dapat menarik minat Anda dan mereka.</li>
                                </ol>
                            </li>
                            <li>C. <b>Informasi yang Kami terima dari sumber lain</b>. Kami dapat menggabungkan informasi yang Kami terima dari sumber lain dengan informasi yang Anda berikan dan informasi yang Kami kumpulkan. Kami dapat menggunakan informasi ini maupun informasi gabungan untuk tujuan yang diatur di atas (tergantung tipe informasi yang Kami terima).</li>
                        </ol>
                    </p>
                </li>

                <li>
                    <b>C. PENGUNGKAPAN INFORMASI PRIBADI</b>
                    <p>Kami dapat membagi atau mengungkapkan informasi Pribadi Anda dengan anggota kelompok usaha Kami, yang melingkupi cabang dan anak perusahaan, serta perusahaan induk utama dan anak perusahaannya.</p>
                    <p>
                        <ol>
                            Kami dapat membagi Informasi Pribadi Anda dengan pihak ketiga, termasuk:
                            <li>a. Mitra bisnis, vendor, dan sub-kontraktor dalam penyelenggaraan kontrak yang Kami laksanakan dengan mereka atau Anda untuk mendukung penyediaan jasa yang optimal kepada Anda.</li>
                            <li>b. Penerbit iklan dan jaringan iklan yang membutuhkan data untuk memilih dan menawarkan iklan yang relevan bagi Anda dan pengguna lain. Kami tidak membuka informasi tentang individu yang dapat diidentifikasi, namun Kami dapat menyediakan mereka informasi agregat tentang pengguna Kami(misalnya informasi bahwa 500 pria berusia di bawah 30 tahun telah mengakses tautan (link) iklan mereka pada hari tertentu). Kami juga dapat memberikan informasi agregat untuk membantu penerbit iklan dalam menjangkau target audiens tertentu (misalnya, perempuan di Jakarta Pusat). Kami dapat menggunakan data personal yang Kami kumpulkan untuk memenuhi permintaan pemasangan iklan dengan menampilkan iklan mereka kepada target audiens tersebut.</li>
                            <li>c. Penyedia analisis dan mesin pencari yang membantu Kami untuk memperbaiki dan meningkatkan optimalisasi Platform Kami.</li>
                        </ol>
                    </p>
                    <p>
                        <ol>
                            Kami dapat mengungkap informasi kepada pihak ketiga:
                            <li>a. Dalam situasi di mana Kami menjual atau membeli perusahaan dan/atau aset, Kami dapat mengungkap data kepada calon pembeli atau penjual dari perusahaan atau aset tersebut.</li>
                            <li>b. Jika Kami atau perusahaan induknya atau aset-aset substansial yang terkait di dalamnya diperoleh oleh pihak ketiga, maka informasi Pribadi yang dimiliki tentang pelanggan Kami akan menjadi salah satu aset yang dipindahtangankan. </li>
                            <li>c. Jika Kami berada di bawah tanggung jawab untuk membagikan atau memberikan data guna mematuhi kewajiban hukum dan perjanjian lain; atau melindungi hak, harta benda, maupun keamanan dari Kami, pelanggan Kami, dan lain-lain. Hal ini mencakup penukaran informasi dengan perusahaan dan organisasi lain untuk tujuan perlindungan dari penipuan dan pengurangan risiko kredit.</li>
                        </ol>
                    </p>
                </li>
                
                <li>
                    <b>D. PENYIMPANAN INFORMASI PRIBADI</b>
                    <p>Informasi Pribadi dari Anda yang Kami kumpulkan dapat disimpan, ditransfer, atau diolah oleh penyedia layanan pihak ketiga. Kami akan menggunakan semua upaya yang wajar untuk memastikan bahwa semua penyedia layanan pihak ketiga tersebut memberikan tingkat perlindungan yang sebanding dengan komitmen Kami berdasarkan Kebijakan Privasi ini. Informasi Pribadi Anda juga dapat disimpan atau diproses di luar negara Anda oleh pihak yang bekerja untuk Kamidi negara lain, atau oleh penyedia layanan pihak ketiga, vendor, pemasok, mitra, kontraktor, atau afiliasi Kami. dalam hal tersebut, Kami akan memastikan bahwa Informasi Pribadi tersebut tetap menjadi subjek dari tingkat perlindungan yang sebanding dengan apa yang disyaratkan dalam hukum negara Anda (dan, dalam hal apapun, sejalan dengan komitmen Kami dalam Kebijakan Privasi ini). </p>
                    <p>Semua transaksi pembayaran pada Platform akan dienkripsi. Dengan menyerahkan Informasi Pribadi Anda pada Platform, Anda menyetujui pengalihan, penyimpanan, serta pengolahan yang terjadi pada Platform Kami. Kami akan mengambil langkah-langkah dalam batas kewajaran yang diperlukan untuk memastikan bahwa Informasi Pribadi tersebut diperlukan dengan aman dan sesuai dengan Kebijakan Privasi serta peraturan yang berlaku. </p>
                    <p>Seluruh Informasi Pribadi yang Anda berikan akan Kami simpan: (i) selama Anda masih menjadi pengguna dari Platform Kamidan (ii) setidaknya 5 (lima) tahun sejak tanggal di mana Anda berhenti menggunakan Platform Kami; atau (iii) sesuai dengan tujuan awal dari pengumpulan Informasi Pribadi tersebut.</p>
                    <p>Dalam situasi di mana Kami memberikan (atau Anda memilih) sebuah kata sandi (password) dan/atau one time password (OTP) yang mengizinkan Anda untuk mengakses bagian-bagian tertentu pada Platform Kami, Anda bertanggung jawab untuk menjaga kerahasiaan kata sandi ini. Kamimeminta Anda untuk tidak membagi kata sandi dengan siapa pun. </p>
                    <p>Mohon untuk dapat diperhatikan bahwa transmisi informasi melalui internet tidak sepenuhnya aman. Meskipun demikian, Kami akan berusaha sebaik mungkin untuk melindungi Informasi Pribadi tersebut. Kami tidak bisa menjamin keamanan data yang dikirimkan ke Platform Kami ataupun menjamin data tersebut tidak dihentikan, diakses, diungkapkan, diubah atau dirusak oleh pihak ketiga yang tidak berwenang akibat faktor-faktor di luar kekuasaan Kami; risiko dari setiap transmisi menjadi tanggung jawab Anda. Begitu Kami menerima Informasi Pribadi Anda, Kami akan menggunakan prosedur yang ketat dan fitur keamanan untuk mencegah akses yang tidak diizinkan.</p>
                </li>

                <li>
                    <b>E. HAK ANDA</b>
                    <p>Anda dapat memohon untuk penghapusan Informasi Pribadi Anda pada Platform atau menarik persetujuan Anda untuk setiap atau segala pengumpulan, penggunaan atau pengungkapan Informasi Pribadi Anda dengan memberikan kepada Kami pemberitahuan secara tertulis melalui detail kontak yang tercantum pada bagian Kebijakan Privasi ini. Tergantung pada keadaan dan sifat permohonan yang Anda minta, Anda harus memahami dan mengakui bahwa setelah penarikan persetujuan atau permohonan penghapusan tersebut, Anda mungkin tidak lagi dapat menggunakan Platform. Penarikan persetujuan Anda dapat mengakibatkan penghentian Akun Anda atau hubungan kontraktual Anda dengan Kami, dengan semua hak dan kewajiban yang muncul sepenuhnya harus dipenuhi. Setelah menerima pemberitahuan untuk menarik persetujuan untuk pengumpulan, penggunaan atau pengungkapan Informasi Pribadi Anda, Kami akan menginformasikan Anda tentang konsekuensi yang mungkin terjadi dari penarikan tersebut sehingga Anda dapat memutuskan apakah Anda tetap ingin menarik persetujuan atau tidak.</p>
                    <p>Anda dapat meminta kepada Kami untuk mengakses dan/atau mengoreksi Informasi Pribadi Anda yang berada dalam kepemilikan dan penguasaan Kami, dengan menghubungi Kami melalui detail kontak yang tercantum pada bagian Kebijakan Privasi ini. </p>
                    <p>Platform Kami dapat dari waktu ke waktu, memuat tautan menuju dan dari situs-situs milik jaringan mitra, pemuat iklan, dan afiliasi lainnya. Jika Anda mengikuti tautan ke salah satu situs tersebut, mohon perhatikan bahwa situs-situs tersebut memiliki Kebijakan Privasi mereka sendiri dan bahwa Kami tidak bertanggung jawab atau memiliki kewajiban apapun atas kebijakan-kebijakan tersebut. Mohon periksa kebijakan-kebijakan tersebut sebelum Anda menyerahkan informasi apapun ke situs-situs tersebut. </p>
                </li>

                <li>
                    <b>F. KEBIJAKAN COOKIES</b>
                    <p>Ketika Anda menggunakan Platform, Kami dapat menempatkan sejumlah cookies pada browser Anda. Cookies adalah sebuah berkas digital kecil berisi huruf dan angka yang Kami simpan pada browser atau hard drive komputer Anda atas persetujuan Anda. cookies mengandung informasi yang dipindahkan ke hard drive komputer Anda. </p>
                    <p>Cookies dapat digunakan untuk tujuan berikut: (1)mengaktifkan fungsi tertentu, (2) memberikan analisis, (3) menyimpan preferensi Anda; dan (4) memungkinkan pengiriman iklan dan pengiklan berdasarkan perilaku. Beberapa cookies ini hanya akan digunakan jika Anda menggunakan fitur tertentu, atau memilih preferensi tertentu, sementara sebagian cookie lain akan selalu digunakan.</p>
                    <p>
                        <ol>
                            Kami menggunakan cookies untuk alasan-alasan berikut:
                            <li>a. Cookies dibutuhkan untuk pengoperasian Platform Kami. ini termasuk, misalnya, Cookies yangmemungkinkan Anda memasuki Area yang aman di Platform Kami, menggunakan keranjang belanja, ataupun menggunakan layanan penagihan elektronik. </li>
                            <li>b. Cookies memungkinkan Kami untuk mengenali dan menghitung jumlah pengunjung serta melihat bagaimana pengunjung bergerak di sekitar Platform Kami saat mereka menggunakannya. Ini membantu Kami memperbaiki cara kerja Platform Kami, misalnya dengan memastikan pengguna menemukan apa yang mereka cari dengan mudah.</li>
                            <li>c. Cookies digunakan untuk mengenali Anda saat kembali ke Platform Kami. Ini memungkinkan Kami melakukan personalisasi terhadap konten Kami untuk Anda, menyapa Anda dengan nama, serta mengingat preferensi Anda (misalnya, pilihan bahasa atau wilayah Anda).</li>
                            <li>d. Cookies mencatat kunjungan Anda ke Platform Kami, halaman yang telah Anda kunjungi, serta tautan yang telah Anda ikuti. Kami akan menggunakan informasi ini untuk membuat Platform Kami serta iklan yang terpasang di dalamnya lebih relevan kepada minat Anda. Kami juga dapat membagi informasi ini dengan pihak ketiga untuk tujuan tersebut. </li>
                        </ol>
                    </p>
                    <br>
                    <p>Mohon perhatikan bahwa pihak ketiga (termasuk, misanya, jaringan periklanan dan penyedia jasa eksternal seperti jasa analisis lalu lintas web) juga dapat menggunakan Cookies ini, di mana Kamitidak memiliki kendali hal ini. Cookies membuat Platform Kamidan iklan yang ditampilkan di dalamnya lebih relevan dengan minat Anda, serta meningkatkan kinerja PLatform Kami. </p>
                    <p>Anda dapat menghapus Cookies dengan cara melakukan fungsi clear data pada mobile app maupun web browser Anda yang memungkinkan Anda untuk menolak pengaturan atau sebagian Cookies. Akan tetapi, Anda mungkin tidak dapat mengakses seluruh atau sebagian Platform Kami.</p>
                </li>

                <li>
                    <b>G. PENGAKUAN DAN PERSETUJUAN</b>
                    <p>Dengan menyetujui Kebijakan Privasi, Anda mengakui bahwa Anda telah membaca dan memahami Kebijakan Privasi ini dan menyetujui segala ketentuannya. Secara khusus, Anda setuju dan memberikan persetujuan kepada Kami untuk mengumpulkan, menggunakan, membagikan, mengungkapkan, menyimpan, mentransfer, atau mengolah Informasi Pribadi Anda sesuai dengan Kebijakan Privasi ini.</p>
                    <p>Dalam hal Anda memberikan Informasi Pribadi yang berkaitan dengan individu lain (misalnya Informasi Pribadi yang berkaitan dengan pasangan Anda, anggota keluarga, teman, atau pihak lain) kepada Kami, maka Anda menyatakan dan menjamin bahwa Anda telah memperoleh persetujuan dari individu tersebut untuk, dan dengan ini menyetujui atas nama individu tersebut untuk, pengumpulan, penggunaan, pengungkapan dan pengolahan Informasi Pribadi mereka oleh Kami. </p>
                </li>

                <li>
                    <b>H. MATERI PEMASARAN</b>
                    <p>Kamidan Mitra Kami Dapat mengirimkan Anda dan pemasaran langsung, iklan, dan komunikasi promosi melalui aplikasi push-notification pesan melalui aplikasi, pos, panggilan telepon, layanan pesan singkat, email dan/atau aplikasi pesan lainnya <b>(“Materi Pemasaran”)</b> jika Anda telah setuju untuk berlangganan milis Kami, dan/atau setuju untuk menerima materi pemasaran dan promosi dari Kami. Anda dapat memilih untuk tidak menerima komunikasi pemasaran tersebut kapan saja dengan menghubungi Kami melalui detail kontak yang tercantum pada bagian Kebijakan Privasi ini. Mohon perhatikan bahwa jika Anda memilih untuk keluar, Kami masih dapat mengirimi Anda pesan-pesan non-promosi, seperti tAnda terima atau informasi tentang Akun Anda.</p>
                </li>

                <li>
                    <b>I. PERUBAHAN DALAM KEBIJAKAN PRIVASI KAMI</b>
                    <p>Kami dapat meninjau dan mengubah Kebijakan Privasi ini atas kebijakan Kami dari waktu ke waktu, untuk memastikan bahwa kebijakan ini konsisten dengan perkembangan Kamiserta perubahan-perubahan pada ketentuan hukum atau peraturan terkait. Perubahan apapun yang Kami lakukan terhadap Kebijakan Privasi Kami di masa depan akan diterbitkan melalui halaman ini dan, ketika dibutuhkan diberitahukan kepada Anda melalui surat elektronik. Mohon kunjungi kembali halaman ini dari waktu ke waktu untuk melihat adanya pembaruan atau perubahan pada Kebijakan Privasi Kami. Penggunaan secara berkelanjutan oleh Anda atas layanan  yang diberikan dalam Platform setelah perubahan dan/atau penambahan Kebijakan Privasi ini akan dianggap sebagai persetujuan dan penerimaan Anda atas perubahan dan/atau penambahan tersebut.</p>
                </li>

                <li>
                    <b>J. CARA MENGHUBUNGI KAMI</b>
                    <p>Untuk menghubungi Kami, silakan kirim surat elektronik ke hello@ikalsmart.id</p>
                </li>
            </ol>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
 p {
  margin-bottom: 28px;
 }
 ol > li{
  margin-left: 28px;
 }
 .ol > li {
    margin-bottom: 28px;
 }
</style>