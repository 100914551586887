<template>
    <div class="relative bg-white rounded-lg shadow-lg text-left col-span-3 p-8 mb-5 mt-10 min-h-screen">

        <!-- Toast Component-->
        <Toast v-show="showToast" :isToastSuccess="isToastSuccess" :toast_message="toast_message" @toggle-toast="toggleToast"></Toast>

        <p class="text-24 font-bold mb-9">Ubah Kata Sandi</p>
        <form @keyup.enter="updatePassword">
            <div class="mb-8">
                <label for="new-password-field" class="block text-sm font-bold text-slate-700 mb-3">Kata Sandi Baru</label>
                <input ref="new_password" v-model="new_password" id="new-password-field" :type="showPassword ? 'text' : 'password'" class="input-field w-full py-2" placeholder="Masukkan kata sandi baru"/>
                <!-- eye icon -->
                <span class="eye-icon" v-if="!showPassword" @click="showPassword = !showPassword"><i class="fa fa-eye" aria-hidden="true"></i></span>
                <span class="eye-icon" v-else @click="showPassword = !showPassword"><i class="fa fa-eye-slash" aria-hidden="true"></i></span>
            </div>
            <div class="mb-8">
                <label for="confirm-new-password-field" class="block text-sm font-bold text-slate-700 mb-3">Konfirmasi Kata Sandi</label>
                <input v-model="confirm_new_password" id="confirm-new-password-field" :type="showConfirmPassword ? 'text' : 'password'" class="input-field w-full py-2" placeholder="Konfirmasi kata sandi baru"/>
                <!-- eye icon -->
                <span class="eye-icon" v-if="!showConfirmPassword" @click="showConfirmPassword = !showConfirmPassword"><i class="fa fa-eye" aria-hidden="true"></i></span>
                <span class="eye-icon" v-else @click="showConfirmPassword = !showConfirmPassword"><i class="fa fa-eye-slash" aria-hidden="true"></i></span>
            </div>
            <div v-if="isFormChanged" class="flex justify-end items-center">
                <button @click="resetForm" class="text-primary font-medium text-14 hover:text-white rounded border border-primary hover:bg-primary py-3 px-5 mt-2 mr-2">Batal</button>
                <button @click="updatePassword" class="text-white font-medium text-14 rounded bg-primary py-3 px-5 mt-2">Simpan</button>
            </div>
        </form>
    </div>
</template>

<script>
import Toast from '../../components/Toast.vue'
export default {
    components: {
        Toast
    },
    data() {
        return {
            showButton: false,
            showToast: false,
            isToastSuccess: false,
            toast_message: '',

            showPassword: false,
            showConfirmPassword: false,

            new_password: '',
            confirm_new_password: '',
        }
    },
    methods: {
        updatePassword() {
            if (this.new_password !== this.confirm_new_password) {
                return this.toastResponse('failed', 'Konfirmasi Kata sandi harus sama dengan Kata Sandi')
            }
            this.scrollToTop()
            const body = {
                token: this.axios.defaults.headers.common['Authorization'],
                password: this.new_password,
                conf_password: this.confirm_new_password
            }
            this.axios.post('auth/reset-password', body).then(response => {
                this.toastResponse('success', 'Kata sandi berhasil diperbarui')
            }).catch(error => {
                console.log(error);
                this.toastResponse('failed', 'Kata sandi gagal diperbarui')
            })
        },
        // scroll to top
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        toggleToast() {
            this.showToast = !this.showToast
        },
        toastResponse(status, message) {
            this.isToastSuccess = status === 'success'
            this.toast_message = message
            this.showToast = true
            setTimeout(() => {
                if (this.showToast) {
                    this.showToast = false
                }
                this.isToastSuccess = false
                this.toast_message = ''
            }, 3000);
        },
        resetForm() {
            this.new_password = ''
            this.confirm_new_password = ''

        }
    },
    mounted() {
        this.$refs.new_password.focus()
    },
    computed: {
        isFormChanged() {
            return this.new_password !== '' || this.confirm_new_password !== ''
        }
    }
}
</script>

<style scoped>
.input-field {
    border: none;
    border-bottom: 2px solid #F2F2F2;
}
.input-field:focus {
    outline: none;
    border-bottom: 2px solid #0099DC;
}
.eye-icon {
	float: right;
    /* margin-left: -25px; */
    margin-top: -32px;
    margin-right: 5px;
    position: relative;
    z-index: 2;
}
</style>

