<template>
    <div class="container mx-auto">
        <div class="grid grid-cols-1 min-h-screen">
            <div class="my-auto mx-auto w-[345px]">
                <div class="mx-auto">
                    <form @submit.prevent="chooseVerificationMethod" class="align-left mx-auto text-left">
                        <router-link to="/"> 
                            <img class="my-5" src="../../public/assets/img/logo/main-logo.png" alt="main-logo" />
                        </router-link>
                        <p class="font-bold mb-6 text-24">Daftar Baru</p>
                        <div class="text-left mb-6">
                            <label for="fullname-field" class="block text-sm font-bold text-slate-700 mb-3">Nama Lengkap</label>
                            <input v-model="fullname" ref="fullname" id="fullname-field" type="text" class="input-field w-full py-2" placeholder="Nama lengkap Anda" required/>
                        </div>
                        <div class="text-left mb-6">
                            <label for="email-field" class="block text-sm font-bold text-slate-700 mb-3">Email <span class="text-red-500">*</span></label>
                            <input v-model="email" id="email-field" type="email" class="input-field w-full py-2" placeholder="example@mail.com" required/>
                        </div>
                        <div class="text-left mb-8">
                            <label for="password-field" class="block text-sm font-bold text-slate-700 mb-3">Kata Sandi</label>
                            <input v-model="password" id="password-field" :type="showPassword ? 'text' : 'password'" class="input-field w-full py-2" placeholder="Masukkan kata sandi" required/>
                            <!-- eye icon -->
                            <span class="eye-icon" v-if="!showPassword" @click="showPassword = !showPassword"><i class="fa fa-eye" aria-hidden="true"></i></span>
                            <span class="eye-icon" v-else-if="showPassword" @click="showPassword = !showPassword"><i class="fa fa-eye-slash" aria-hidden="true"></i></span>
                        </div>
                        <div class="text-left mb-6">
                            <label for="phone-field" class="block text-sm font-bold text-slate-700 mb-3">Nomor Handphone <span class="text-red-500">*</span><i class="text-10">(Pastikan nomor handphone Anda terhubung dengan WhatsApp)</i></label>
                            <input v-model="phone" id="phone-field" type="tel" class="input-field w-full py-2" placeholder="081243456xxx" required/>
                        </div>
                        <p class="text-red-500 text-center mb-4 px-5" v-show="message">{{message}}</p>
                        <button type="submit" class="bg-primary rounded text-center font-bold w-full text-white py-2">
                            <div v-if="isRegistering" class="spinner-border animate-spin inline-block w-5 h-5 border-4 rounded-full text-white" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div v-else>Daftar</div>
                        </button>
                    </form>
                    <div class="text-center mx-auto my-4">
                        <p class="mx-auto mb-6">Sudah punya Akun? <span class="font-bold text-primary"><router-link to="/login">Masuk</router-link></span> </p>
                        <p>Dengan masuk atau mendaftar, Anda telah menyetujui <router-link to="terms-condition" class="font-bold text-primary">ketentuan layanan</router-link> dan <router-link to="privacy-policy" class="font-bold text-primary">kebijakan kami</router-link>.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showPassword: false,
            fullname: '',
            email: '',
            password: '',
            phone: '',
            message: '',
            isRegistering: false
        }
    },
    methods: {
        chooseVerificationMethod() {
            this.isRegistering = true
            const user = {
                name: this.fullname,
                email: this.email,
                password: this.password,
                phone: this.phone,
            }

            this.$store.dispatch('keepUserRegisterData', user).then(response => {
                this.isRegistering = false
            }).catch(error => {
                this.isRegistering = false
                this.message = error.response.data.message
            })
        }
    },
    mounted() {
        this.$refs.fullname.focus()
    }
};
</script>

<style scoped>
.input-field {
    border: none;
    border-bottom: 2px solid rgba(37, 40, 43, 0.33);
}
.input-field:focus {
    outline: none;
    border-bottom: 2px solid #0099DC;
}
.eye-icon {
	float: right;
    /* margin-left: -25px; */
    margin-top: -32px;
    margin-right: 5px;
    position: relative;
    z-index: 2;
}
</style>