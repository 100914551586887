<template>
    <div class="container mx-auto">
        <div class="grid grid-cols-1 min-h-screen">
            <div class="my-auto mx-auto w-[345px]">
                <div class="mx-auto">
                    <form @submit.prevent="send" class="align-left mx-auto text-left">
                        <img class="mb-14" src="../../public/assets/img/logo/main-logo.png" alt="main-logo" />
                        <p class="font-bold text-24">Lupa Kata Sandi</p>
                        <p class="mb-6 text-14">Masukkan email yang Anda daftarkan di educazone Ikuti instruksi selanjutnya yang tertera di email.</p>
                        <div class="text-left mb-6">
                            <label for="email-field" class="block text-sm font-bold text-slate-700 mb-3">Email</label>
                            <input v-model="email" id="email-field" type="email" class="input-field w-full py-2" placeholder="example@mail.com"/>
                        </div>
                        <p :class="text_color" class="text-center mb-4 px-3" v-if="message">{{message}}</p>
                        <button type="submit" class="bg-primary rounded text-center font-bold w-full text-white py-2">Kirim</button>
                    </form>
                    <div class="text-center mx-auto my-4">
                        <p class="mx-auto mb-6 font-bold text-primary"><router-link to="/login">Kembali ke halaman Masuk</router-link></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            message: '',
            text_color: 'text-red-500',
        }
    },
    methods: {
        send() {
            const data = {
                email: this.email,
            }
            this.axios.post('auth/forgot-password', data).then(response => {
                this.message = 'Instruksi perubahan kata sandi telah dikirim. Segera periksa email Anda untuk mengubah kata sandi.';
                this.text_color = 'text-success';
            }).catch(error => {
                if (error.response.status == '404') {
                    this.message = 'Maaf, email yang Anda masukkan salah atau tidak terdaftar'
                } else {
                    this.message = 'Terjadi kesalahan. Silahkan coba lagi.';
                    this.text_color = 'text-red-500';
                }
            })
        }
    },
};
</script>

<style scoped>
.input-field {
    border: none;
    border-bottom: 2px solid rgba(37, 40, 43, 0.33);
}
.input-field:focus {
    outline: none;
    border-bottom: 2px solid #313F6E;
}
.eye-icon {
	float: right;
    /* margin-left: -25px; */
    margin-top: -32px;
    margin-right: 5px;
    position: relative;
    z-index: 2;
}
</style>