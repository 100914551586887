<template>
    <div class="flex flex-col items-center justify-center h-screen -mt-10">

        <!-- INITIAL STATE -->
        <div v-if="current_state == states[0]"
            class="shadow rounded-lg mx-auto h-auto p-7 bg-light-white w-full my-0 laptop:my-auto laptop:w-[1024px]">
            <div class="flex flex-col laptop:grid laptop:grid-cols-5 gap-6 w-full">
                <!-- Detail Order -->
                <div class="col-span-3">
                    <div class="bg-white rounded-lg flex flex-col justify-between text-left px-6 pb-5 pt-8">
                        <p class="font-bold text-14 mb-4">Detail Order</p>
                        <div @click="$router.push({ name: 'Detail Belajar Mandiri', params: { course_id: this.$route.params.id, type: this.type } })"
                            class="flex cursor-pointer">
                            <img :src="item_data.thumbnail" alt="" class="h-16 mr-4">
                            <div class="flex flex-col">
                                <p class="text-14">{{ getItemType() }}</p>
                                <!-- <p class="text-18">{{item_type == 'online_class' ? item_data.title : item_data.name }} -->
                                <p class="text-18">{{ transaction_detail.course }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Pembayaran -->
                <div class="col-span-2 h-[450px]">
                    <div class="flex flex-col justify-between bg-white rounded-lg text-left px-6 pb-5 pt-8 h-full">
                        <div class="flex flex-col items-stretch justify-center">
                            <div>
                                <p class="text-12 font-medium mb-3">Kode Promo</p>
                                <div v-if="!promo_code" class="flex text-14 justify-between items-center">
                                    <p class="text-14">Belum ada kode promo</p>
                                    <a @click="choosePromo" class="font-semibold cursor-pointer">Pilih</a>
                                </div>
                                <div v-else class="flex text-14 justify-between items-center">
                                    <p class="text-14">{{ promo_code }}</p>
                                    <a @click="choosePromo" class="font-semibold cursor-pointer">Ubah</a>
                                </div>
                            </div>
                            <hr class="w-full border my-4 ">
                            <div>
                                <p class="text-12 font-medium mb-3">Metode Pembayaran</p>
                                <div v-if="!payment_method" class="flex text-14 justify-between items-center">
                                    <p class="text-14">Belum ada metode</p>
                                    <a @click="choosePaymentMethod" class="font-semibold cursor-pointer">Pilih</a>
                                </div>
                                <div v-else class="flex text-14 justify-between items-center">
                                    <img :src="payment_method.image_url" alt="payment method" class="w-[50px]">
                                    <a @click="choosePaymentMethod" class="font-semibold cursor-pointer">Ubah</a>
                                </div>
                            </div>
                            <hr class="w-full border my-4 ">
                            <div>
                                <p class="text-12 font-medium mb-3">Ringkasan Tagihan</p>
                                <div class="flex text-14 justify-between items-center mb-3">
                                    <p>Harga Pembelajaran</p>
                                    <p class="font-semibold text-14">
                                        {{ this.$filters.toCurrency(transaction_detail.payment_amount) }}</p>
                                </div>
                                <!-- temporary hidden -->
                                <!-- <div v-if="promo_code" class="flex text-14 justify-between items-center mb-3">
                                    <p>Promo</p>
                                    <p class="font-semibold text-14">-Rp. 10.000</p>
                                </div> -->
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-center ">
                            <p class="text-12 mb-3">Dengan membayar, saya menyetujui
                                <router-link to="/terms-condition">
                                    <span class="font-medium ">syarat & ketentuan</span>
                                </router-link>
                            </p>
                            <p class="text-14 mb-1">Total Pembayaran</p>
                            <p class="font-bold text-18 mb-3">
                                {{ this.$filters.toCurrency(transaction_detail.payment_amount) }}</p>

                            <button @click="confirmTransaction" :disabled="!payment_method"
                                :class="!payment_method ? 'bg-[#BDBDBD] text-[#7B838A] cursor-default' : 'bg-primary text-white cursor-pointer'"
                                class="text-center font-semibold rounded-md py-2 w-full">Bayar</button>
                        </div>

                    </div>
                </div>

            </div>
        </div>

        <!-- CHOOSE PROMO -->
        <div v-else-if="current_state == states[1]"
            class="shadow rounded-lg mx-auto h-auto p-7 bg-light-white w-full my-0 laptop:my-auto laptop:w-[529px]">
            <p class="font-bold text-14 mb-4 text-left">Promo</p>
            <div class="flex mb-3">
                <input type="text" class="bg-white rounded-md w-full mr-2 px-4 " placeholder="Masukkan Kode Promo"
                    v-model="promo_code">
                <button @click="redirectToCheckout"
                    class="bg-primary rounded-md text-white text-center p-3 ">Pakai</button>
            </div>
            <hr class="bg-primary border-0 h-1 mb-10 ">

            <div class="flex items-start mb-7 ">
                <div class="flex w-full justify-start items-center border-b-2 ">
                    <a class="mx-4 cursor-pointer " :class="promo_state == promo_states[0] ? 'menu-choosed' : ''"
                        @click="promo_state = promo_states[0]">Promo</a>
                    <a class="mx-4 cursor-pointer " :class="promo_state == promo_states[1] ? 'menu-choosed' : ''"
                        @click="promo_state = promo_states[1]">Voucher Saya</a>
                </div>
            </div>

            <!-- Choose Promo -->
            <div v-if="promo_state == promo_states[0]" class="flex flex-col w-full overflow-y-scroll h-50v">
                <!-- List Promo -->
                <div v-if="promos.length > 0">
                    <div v-for="(promo, index) in promos" :key="index" @click="promo_code = 'PROMO1'"
                        class="bg-white rounded-lg flex flex-col justify-between text-left p-6 mb-3 cursor-pointer">
                        <div class="flex flex-col items-start justify-center">
                            <img src="../../../public/assets/img/empty-state.png" alt="empty state" class="mb-11 mx-auto">
                            <p class="text-left text-16 font-semibold mt-4 mb-5">{{ promo.title }}</p>
                            <div class="flex justify-between items-center w-full">
                                <div class="text-left">
                                    <p class="text-12 font-bold mb-2 text-disabled">Periode Promo</p>
                                    <p class="text-14 font-semibold">{{ promo.periode }}</p>
                                </div>
                                <div class="text-left">
                                    <p class="text-12 font-medium mb-2 text-disabled">Kode Promo</p>
                                    <p class="text-14 font-semibold">{{ promo.code }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <img src="../../../public/assets/img/empty-state-rounded.png" alt="empty state" class="mb-11 mx-auto">
                    <p class="font-bold text-16 ">Promo tidak ditemukan</p>
                </div>
            </div>

            <!-- Choose Voucher -->
            <div v-else-if="promo_state == promo_states[1]" class="flex flex-col w-full overflow-y-scroll h-50v">
                <!-- List Voucher -->
                <div v-if="vouchers.length > 0">
                    <div v-for="(voucher, index) in vouchers" :key="index"
                        class="bg-white rounded-lg flex flex-col justify-between text-left p-6 mb-3">
                        <div class="flex flex-col items-start justify-center">
                            <p class="text-left text-16 font-semibold mb-10">{{ voucher.title }}</p>
                            <div class="flex justify-between items-center w-full">
                                <div class="text-left">
                                    <p class="text-12 font-bold mb-2 text-disabled">Berlaku sampai</p>
                                    <p class="text-14 font-semibold">{{ voucher.expiration_date }}</p>
                                </div>
                                <button @click="promo_code = voucher.code"
                                    class="border border-primary rounded-md text-primary text-12 font-semibold py-3 px-8 ">Pilih</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <img src="../../../public/assets/img/empty-state-rounded.png" alt="empty state" class="mb-11 mx-auto">
                    <p class="font-bold text-16 ">Voucher tidak ditemukan</p>
                </div>
            </div>
        </div>

        <!-- CHOOSE PAYMENT METHOD -->
        <div v-else-if="current_state == states[2]"
            class="shadow rounded-lg mx-auto p-7 bg-light-white w-full my-0 laptop:my-auto laptop:w-[529px] h-[435px] overflow-scroll">
            <p class="font-bold text-16 mb-10 text-left">Metode Pembayaran</p>
            <div v-show="wallets" class="flex flex-col justify-center items-start ">
                <p class="font-semibold text-14 mb-6">Wallet</p>
            </div>
            <div class="flex flex-col items-stretch justify-center mb-3">
                <div v-for="(wallet, index) in wallets" :key="index">
                    <div @click="usePaymentMethod(wallet)" class="flex items-center justify-start cursor-pointer pb-4"
                        :class="index == wallets.length - 1 ? '' : 'border-b mb-4'">
                        <img :src="wallet.image_url" :alt="wallet.code" class="mr-2 w-[50px]">
                        <p class="text-14 font-semibold">{{ wallet.name }}</p>
                    </div>
                </div>
            </div>

            <hr v-show="wallets" class="bg-soft-disabled h-1 my-6">

            <div v-if="virtual_accounts" class="flex flex-col justify-center items-start ">
                <p class="font-semibold text-14 mb-6">Virtual Account</p>
            </div>
            <div class="flex flex-col items-stretch justify-center mb-3">
                <div v-for="(va, index) in virtual_accounts" :key="index">
                    <div @click="usePaymentMethod(va)" class="flex items-center justify-start cursor-pointer pb-4"
                        :class="index == va.length - 1 ? '' : 'border-b mb-4'">
                        <img :src="va.image_url" :alt="va.code" class="mr-2 w-[50px]">
                        <p class="text-14 font-semibold">{{ va.name }}</p>
                    </div>
                </div>
            </div>
        </div>

        <SuccessCheckoutCard v-else/>

        <!-- <div v-else class="shadow rounded-lg mx-auto h-auto py-7 px-20 bg-white" style="width: 596px;">
            <div class="flex flex-col">
                <img src="../../../public/assets/img/transaction-success.png" alt="" class="mx-auto" style="width: 362px;">
                <p class="font-bold text-32 mb-2">Berhasil Checkout Materi</p>
                <p class="text-16 mb-6">Anda akan diarahkan ke halaman pembayaran</p>
                <button @click="returnToMainPage()"
                    class="bg-primary text-white font-semibold text-center text-14 rounded py-4">Belajar Sekarang</button>
            </div>
        </div> -->

        <!-- Toast-->
        <div v-show="showToast" class="absolute flex space-x-2 justify-center inset-x-1/3 top-14">
            <div :class="isToastSuccess ? 'bg-green-100' : 'bg-red-100'"
                class="shadow-lg mx-auto w-auto px-5 max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block"
                id="static-example" role="alert" aria-live="assertive" aria-atomic="true" data-mdb-autohide="false">
                <div class="flex items-center justify-center">
                    <img v-if="isToastSuccess" src="../../../public/assets/img/icons/icon_success-standard-solid.png"
                        alt="icon success">
                    <img v-else src="../../../public/assets/img/icons/icon_triangle-danger-f.png" alt="icon error">
                    <div class="p-3 rounded-b-lg break-words"
                        :class="isToastSuccess ? 'bg-green-100 text-green-500' : 'bg-red-100 text-red-500'">
                        <span v-if="isToastSuccess">
                            Pembayaran Berhasil
                        </span>
                        <span v-else>
                            Pembayaran Gagal
                        </span>
                    </div>
                    <img v-if="isToastSuccess" @click="toggleToast()"
                        src="../../../public/assets/img/icons/close_24px_success.png" alt="close_24px_success"
                        data-mdb-dismiss="toast" class="cursor-pointer ">
                    <img v-else @click="toggleToast()" src="../../../public/assets/img/icons/close_24px.png"
                        alt="close_24px" data-mdb-dismiss="toast" class="cursor-pointer ">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SuccessCheckoutCard from './Component/SuccessCheckoutCard.vue';
export default {
    components: {
        SuccessCheckoutCard
    },
    data() {
        return {
            total_token: this.$store.getters.userDetail.user.token,
            transaction_id: this.$route.params.transaction_id,
            payment_methods: [],
            wallets: [],
            vouchers: [],
            promos: [],
            virtual_accounts: [],
            payment_method: '',
            item_data: '',
            item_type: '',
            message: '',
            states: ['InitState', 'ChoosePromo', 'ChoosePaymentMethod', 'Done'],
            current_state: '',
            promo_states: ['promo', 'voucher'],
            promo_state: '',
            promo_code: '',
            showToast: false,
            isToastSuccess: false,
            transaction_detail: ''
        }
    },
    methods: {
        getItemDetail() {
            var body = {
                id: this.$route.params.id
            };
            if (this.item_type == 'belajar-mandiri') {
                this.axios.post('course/detail', body).then(response => {
                    this.item_data = response.data.data;
                }).catch(error => {
                    console.log(error.response);
                });
            } else if (this.item_type == 'online_class') {
                const body = {
                    id: this.$route.params.id
                }
                this.axios.post('online-class/detail', body).then(response => {
                    this.item_data = response.data.data
                }).catch(error => {
                    console.log(error.response)
                })
            }
        },
        getTransactionDetail() {
            this.axios.get(`transaction/${this.transaction_id}`).then(response => {
                this.transaction_detail = response.data.data
                // this.isPaid()
            }).catch(error => {
                console.log(error.response);
            });
        },
        getPaymentMethods() {
            this.axios.get('payment-method').then(response => {
                this.payment_methods = response.data;
                this.getWalletsPayments();
                this.getVirtualAccountPayment();
            }).catch(error => {
                console.log(error.response);
            })
        },
        getWalletsPayments() {
            this.wallets = this.payment_methods.ewallet
        },
        getVirtualAccountPayment() {
            this.virtual_accounts = this.payment_methods.va
        },
        confirmTransaction() {
            this.toastResponse();
            let body = {
                transaction_id: this.transaction_id,
                payment_method_name: this.payment_method.name,
                payment_method_code: this.payment_method.code,
                payment_method_image: this.payment_method.image_url
            }
            this.axios.post('payment', body).then(response => {
                var payment_url = response.data.data.payment_url
                console.log(payment_url);
                if (payment_url) {
                    this.toggleSuccessToast();
                    this.current_state = this.states[3];
                    this.$store.state.hasCheckoutItem = true;
                    setTimeout(() => {
                        window.location.replace(`${payment_url}`);
                    }, 1000);
                } else {
                    this.toggleErrorToast()
                }
            }).catch(error => {
                this.toggleErrorToast();
                console.log(error);
            });
        },
        returnToMainPage() {
            this.$router.push({ name: 'Detail Belajar Mandiri', params: { course_id: this.$route.params.id } });
        },
        getItemType() {
            return this.item_type == 'belajar-mandiri' ? 'Belajar Mandiri' : 'Kelas Online';
        },
        topupToken() {
            this.$router.push({ name: 'TopupToken' });
        },
        choosePromo() {
            this.current_state = this.states[1]; // choose Promo
        },
        choosePaymentMethod() {
            this.getPaymentMethods();
            this.current_state = this.states[2]; // choose Payment Method
        },
        redirectToCheckout() {
            this.current_state = this.states[0]
        },
        usePaymentMethod(payment_method) {
            this.payment_method = payment_method;
            this.redirectToCheckout();
        },

        toastResponse() {
            // scroll to top
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            setInterval(() => {
                this.showToast = false
            }, 5000);
        },
        toggleToast() {
            this.showToast = !this.showToast
        },
        toggleSuccessToast() {
            this.isToastSuccess = true
            this.toggleToast()
        },
        toggleErrorToast() {
            this.isToastSuccess = false
            this.toggleToast()
        },
        getImageUrl(image) {
            return require(`${image}`);
        },
        isPaid() {
            var payment_status = this.transaction_detail.payment_status;
            console.log(payment_status);
            // console.log(this.transaction_detail.payment_status);
            if (payment_status == 'waiting' || payment_status == 'paid') {
                this.$router.push({
                    name: 'Transaction Detail',
                    params: {
                        transaction_id: this.transaction_id
                    }
                })
            }
        }
    },
    computed: {
        getTotalPayment() {
            var amount = this.transaction_detail.payment_amount;
            var discount = this.transaction_detail.promo.discount;
            return discount ? amount - (payment * discount / 100) : amount;
        }
    },
    mounted() {
        this.current_state = this.states[0]; // set state
        this.promo_state = this.promo_states[0] // set promo state

        this.$store.state.hasCheckoutItem = false;
        this.item_type = this.$route.params.type

        this.getItemDetail();
        this.getTransactionDetail()
    },
}
</script>

<style scoped>
.menu-choosed {
    border-bottom: 4px solid #313F6E;
    font-weight: bold;
    color: #313F6E;
}

::-webkit-scrollbar-thumb {
    background: #313F6E;
}
</style>