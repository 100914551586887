<template>
  <main class="h-full">
    <div class="bg-white rounded-lg shadow-lg text-left col-span-3 pt-0 laptop:pt-8 p-8 mt-10 h-full">
      <!-- Title -->
      <div class="text-left mb-8 ">
        <p class="text-24 font-bold">Kelas Online </p>
        <p class="text-14 laptop:text-16">Tingkatkan kemampuanmu sebagai seorang guru dengan belajar secara mandiri di Educazone</p>
      </div>

      <!-- Tab Content - temporary hidden -->
      <!-- <ul class="nav nav-tabs flex flex-col laptop:flex-row flex-wrap list-none border-b pl-0 mb-8 " id="tabs-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <a href="#ragam-materi-belajar" class="
            nav-link
            block
            font-medium
            text-18
            leading-tight
            border-x-0 border-t-0 border-b-2 border-transparent
            px-6
            py-3
            hover:border-transparent hover:text-primary
            focus:border-transparent
            active
          " id="ragam-materi-belajar-tab" data-bs-toggle="pill" data-bs-target="#ragam-materi-belajar" role="tab" aria-controls="ragam-materi-belajar"
            aria-selected="true"
            >Kelas Online</a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#materi-yang-dipilih" class="
            nav-link
            block
            font-medium
            text-18
            leading-tight
            border-x-0 border-t-0 border-b-2 border-transparent
            px-6
            py-3
            hover:border-transparent hover:text-primary
            focus:border-transparent
          " id="materi-yang-dipilih-tab" data-bs-toggle="pill" data-bs-target="#materi-yang-dipilih" role="tab"
            aria-controls="materi-yang-dipilih" aria-selected="false"
            >Kelas Yang Diikuti</a>
        </li>
      </ul> -->
      
      <div class="tab-content overflow-auto" id="tabs-tabContent">
        <div class="class-level-section grid grid-cols-1 laptop:flex items-center justify-start mb-4 laptop:mb-8">
            <p class="font-bold mr-4 mb-4 laptop:mb-0">Status Kelas</p>
            <div class="flex max-w-screen-laptop  overflow-auto laptop:overflow-visible p-0">
              <label for="all_status" class="mr-4">
                  <input v-model="class_status" type="radio" value="all_status" id="all_status" class="hidden form-check-input"/>
                  <span class="flex rounded-full border border-primary bg-white form-check-label text-primary font-bold w-full py-2 px-4 cursor-pointer min-w-max">
                      Semua Kelas
                  </span>
              </label>
              <label for="belum_dimulai" class="mr-4">
                  <input v-model="class_status" type="radio" value="Belum Dimulai" id="belum_dimulai" class="hidden form-check-input"/>
                  <span class="flex rounded-full border border-primary bg-white form-check-label text-primary font-bold w-full py-2 px-4 cursor-pointer min-w-max">
                      Belum Dimulai
                  </span>
              </label>
              <label for="berlangsung" class="mr-4">
                  <input v-model="class_status" type="radio" value="Sedang Berlangsung" id="berlangsung" class="hidden form-check-input"/>
                  <span class="flex rounded-full border border-primary bg-white form-check-label text-primary font-bold w-full py-2 px-4 cursor-pointer min-w-max">
                      Berlangsung
                  </span>
              </label>
              <label for="selesai" class="mr-4">
                  <input v-model="class_status" type="radio" value="Selesai" id="selesai" class="hidden form-check-input"/>
                  <span class="flex rounded-full border border-primary bg-white form-check-label text-primary font-bold w-full py-2 px-4 cursor-pointer min-w-max">
                      Selesai
                  </span>
              </label>
            </div>
        </div>

        <!-- Ragam Materi Belajar -->
        <div class="tab-pane fade show active mb-8" id="ragam-materi-belajar" role="tabpanel" aria-labelledby="ragam-materi-belajar-tab">
          <div v-if="filtered_online_class.length > 0" class="flex flex-col items-center justify-center laptop:items-start laptop:grid grid-cols-3 gap-10">
            <!-- card -->
            <div v-for="online_class in filtered_online_class" :key="online_class.id" class="bg-white rounded-lg shadow-lg max-w-screen-tablet">
              <router-link :to="`/online-class/${online_class.id}`">
                <img :src="online_class.thumbnail" alt="" class="rounded-t-lg w-full h-[135px]">
                <div class="text-left p-5 ">
                  <div class="flex justify-start items-center mb-4">
                    <button class="flex justify-center items-center text-white text-12 font-medium uppercase rounded py-1 px-3" :class="online_class_status(online_class.status)">
                      <span class="rounded-full bg-white h-2 w-2 mr-2"></span> 
                      {{online_class.status}}
                    </button>
                  </div>
                  <p class="font-bold text-18 mb-4 capitalize">{{online_class.title}}</p>
                  <div class="flex justify-start items-center my-5 ">
                    <img :src="online_class.mentor_photo" alt="mentor_3" class="rounded-full mr-4 h-10 w-10">
                    <p class="font-bold text-16 capitalize">{{online_class.mentor_name}}</p>
                  </div>
                  <div class="flex items-center justify-start">
                    <img src="../../public/assets/img/icons/icon_time_small.png" alt="icon_time_small" class="flex-none mr-1">
                    <p class="text-12 ">{{convertTime(online_class.start_at)}} - {{convertTime(online_class.finish_at)}} | {{convertDate(online_class.finish_at)}}</p>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div v-else class="flex flex-col items-center justify-start w-full h-screen text-center">
            <img src="../../public/assets/img/empty-state-rounded.png" alt="empty-state-rounded" class="mx-auto">
            <p class="text-48 font-bold">Belum Ada Kelas</p>
            <p class="text-18 mb-4">Tidak ada kelas online {{class_status == 'all_status' ? '' : `dengan status ${class_status}`}}</p>
          </div>
        </div>

        <!-- Materi Yang Anda Pilih -->
        <div class="tab-pane fade show mb-8" id="materi-yang-dipilih" role="tabpanel" aria-labelledby="materi-yang-dipilih-tab">
          <div class="flex">
            <div class="mx-auto text-center align-middle">
              <div class="mb-11">
                <img src="../../public/assets/img/empty-state-rounded.png" alt="empty-state-rounded" class="mx-auto">
                <p class="font-bold text-3xl mb-2 mt-8">Belum Ada Kelas</p>
                <p class="text-16">Kamu belum mempunyai kelas.</p>
                <p>Yuk ambil kelas sekarang</p>
              </div>
              <button class="bg-primary text-16 font-semibold text-center text-white w-full py-4" data-mdb-ripple="true" data-mdb-ripple-color="blue" >CARI KELAS</button>
            </div>
          </div>
        </div>

      </div>
    </div>

    
  </main>
</template>

<script>
export default {
  data() {
    return {
      isLoggedIn: this.$store.getters.isLoggedIn,
      online_class: [],
      filtered_online_class: [],
      keyword: '',
      limit: 10,
      page: 1,
      class_status: 'all_status',
      label_colors: {
        'Belum Dimulai': 'bg-gray-400',
        'Sedang Berlangsung': 'bg-warning',
        'Selesai': "bg-success"
      },
    }
  },
  methods: {
    getListOnlineClass() {
      this.axios.get(`online-class/list?page=${this.page}&limit=${this.limit}&keyword=${this.keyword}`).then(response => {
        this.online_class = response.data.data ? response.data.data : []
        this.filtered_online_class = this.online_class
      }).catch(error => {
        console.log(error);
      })
    },
    convertTime(date){
      const time = new Date(date);
      const hours = time.getHours();
      const minutes = (time.getMinutes() < 10 ? '0' : '') + time.getMinutes();
      return hours + ':' + minutes;
    },
    convertDate(date){
      const list_month = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
      const time = new Date(date);
      const day = time.getDate();
      const month = list_month[time.getMonth()];
      const year = time.getFullYear();
      return day + ' ' + month + ' ' + year;
    },
    online_class_status(status) {
      return this.label_colors[status]
    }
  },
  mounted() {
    this.getListOnlineClass()
  },
  watch: {
    class_status() {
      this.filtered_online_class = this.class_status == 'all_status' ? this.online_class : this.online_class.filter(online_class => online_class.status == this.class_status)
    }
  }
}
</script>

<style scoped>
::-webkit-scrollbar-thumb {
    display: none;
}
ul li a.active {
  font-weight: bold;
  color: #313F6E !important;
  border-bottom: 5px solid #313F6E !important;
}
.class-level-section input:checked + .form-check-label {
    background-color: #313F6E;
    color: white;
    border: 1px solid #313F6E;
}
</style>