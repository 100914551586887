<template>
    <div class="bg-white text-black flex flex-col rounded-lg w-[276px] overflow-hidden min-h-full ">
        <img :src="getImgUrl(image)" alt="service_1">
        <div class="flex flex-col items-center justify-center text-center py-6 px-4 ">
            <p class="font-bold text-16 mb-6">{{title}}</p>
            <p class="text-12">{{desc}}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        desc: String,
        image: String
    },
    methods: {
        getImgUrl(image) {
            return require('../../../public/assets/img/' + image)
        }
    }
}
</script>

<style>

</style>