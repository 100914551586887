<template>
    <div class="shadow rounded-lg mx-auto h-auto py-7 px-20 bg-white" style="width: 596px;">
        <div class="flex flex-col">
            <img src="../../../../public/assets/img/transaction-success.png" alt="" class="mx-auto" style="width: 362px;">
            <p class="font-bold text-32 mb-2">Berhasil Checkout Materi</p>
            <p class="text-16 mb-6">Anda akan diarahkan ke halaman pembayaran</p>
            <!-- <button @click="returnToMainPage()"
                class="bg-primary text-white font-semibold text-center text-14 rounded py-4">Belajar Sekarang</button> -->
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>