<template>
    <div class="flex justify-between bg-white rounded h-screen p-7">
        <img src="../../public/assets/img/certificate-img.png" alt="certificate-img" class="mr-28">
        <div class="flex flex-col justify-between items-start text-left py-10 w-full">
            <div>
                <p class="flex text-63 mt-28">Certified &#160;<span class="font-bold">User</span></p>
                <p class="font-extrabold text-48 mt-12 capitalize" v-if="quiz_user.user">{{quiz_user.user.teacher.name}}</p>
                <p class="mt-6 ">for successfully completing the examination <span class="capitalize" v-if="course">{{course.name}}</span> with grade of : </p>
                <p class="font-semibold text-48 mt-3">{{certificate.accumulative_score}} / 100</p>
            </div>
            <div>
                <p class="font-semibold uppercase">Certification code : {{certificate.code}}</p>
                <p class="mt-5 font-semibold ">{{$filters.convertDate(certificate.created_at)}}</p>
                <img class="mt-5" width="150px" height="150px" src="../../public/assets/img/certificate-barcode.png" alt="certificate-barcode">
            </div>
        </div>
        <div class="flex flex-col justify-between py-10">
            <div class="flex w-full">
                <div class="flex justify-end relative items-center ">
                    <div class="text-36 font-bold mt-0 mr-24"><span class="text-primary">Educazone</span></div>
                    <div style="height: 70px; width: 70px" class="bg-blue-200 rounded absolute mt-5 mr-6"></div>
                    <div style="height: 70px; width: 70px" class="bg-primary rounded absolute mt-0"></div>
                </div>
            </div>
            <div class="flex flex-col justify-between space-y-5">
                <div class="flex justify-center">
                    <img src="../../public/assets/img/certificate-signature.png" alt="certificate-signature">
                </div>
                <div class="text-center">
                    <hr class="font-bold border-black mb-4 ">
                    <p>Ardharikza</p>
                    <p>CEO Educazone</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            certificate: {},
            course: {},
            quiz_user: {},
            certificate_id: this.$route.params.certificate_id,
        };
    },
    methods: {
        getCertificateData() {
            this.axios.get(`certificate?id=${this.certificate_id}`).then(response => {
                this.certificate = response.data.data.certificate;
                this.course = response.data.data.course;
                this.quiz_user = this.certificate.quiz_user;
            }).catch(error => {
                console.log(error.response);
            });
        },
    },
    mounted() {
        this.getCertificateData();
    },
}
</script>

<style>
</style>