<template>
    <div class="bg-white rounded-md w-full py-5 px-6 border border-gray-400 mb-4">
        <div class="flex justify-between items-center mb-9">
            <div class="flex items-center">
                <p class="text-14 font-semibold mr-4">Belajar Mandiri</p>
                <p class="text-12">{{ this.$filters.convertDateWithDay(transaction.transaction_date) }}</p>
            </div>
            <div :class="this.$filters.getTransactionLabelColor(transaction.payment_status)" class="px-2 py-1 rounded-md text-white text-12 font-medium">
                {{ this.$filters.getTransactionStatus(transaction.payment_status) }}
            </div>
        </div>
        <div class="flex items-center justify-between">
            <div class="flex items-center">
                <img class="h-[69px] mr-4" :src="transaction.course_thumbnail" alt="">
                <p class="text-18 ">{{transaction.course}}</p>
            </div>
            <div class="text-right ">
                <p class="text-12">Total Pembayaran</p>
                <span class="text-16 font-bold">{{ this.$filters.toCurrency(transaction.payment_amount) }}</span>
            </div>
        </div>
        <hr class="my-4 ">
        <div class="text-right">
            <button @click="viewTransactionDetail(transaction.transaction_id)" class="text-14 font-semibold border border-primary text-primary rounded-md py-3 px-8">Lihat Detail</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        transaction: {
            type: Object,
            default: () => {}
        },
    },
    methods: {
        viewTransactionDetail(transaction_id) {
            this.$router.push({
                name: 'Transaction Detail',
                params: {
                    transaction_id
                }
            })
        },
        
    },
    mounted() {
        // this.getDetailTransaction()
    }
}
</script>

<style>

</style>