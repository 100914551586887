<template>
  <!-- Toast-->
  <div class="absolute flex space-x-2 justify-center inset-x-1/3 top-0">
        <div :class="isToastSuccess ? 'bg-green-100' : 'bg-red-100'" class="shadow-lg mx-auto w-auto px-5 max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block" id="static-example" role="alert" aria-live="assertive" aria-atomic="true" data-mdb-autohide="false">
            <div class="flex items-center justify-center">
                <img v-if="isToastSuccess" src="../../public/assets/img/icons/icon_success-standard-solid.png" alt="icon success">
                <img v-else src="../../public/assets/img/icons/icon_triangle-danger-f.png" alt="icon error">
                <div class="p-3 rounded-b-lg break-words" :class="isToastSuccess ? 'bg-green-100 text-green-500' : 'bg-red-100 text-red-500'">
                    {{toast_message}}
                </div>
                <img v-if="isToastSuccess" @click="toggleToast()" src="../../public/assets/img/icons/close_24px_success.png" alt="close_24px_success" data-mdb-dismiss="toast" class="cursor-pointer ">
                <img v-else @click="toggleToast()" src="../../public/assets/img/icons/close_24px.png" alt="close_24px" data-mdb-dismiss="toast" class="cursor-pointer ">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isToastSuccess: {
            type: Boolean,
            default: false
        },
        toast_message: {
            type: String,
            default: ""
        }
    },
    methods: {
        toggleToast() {
            this.$emit('toggle-toast')
        }
    }
}
</script>

<style>

</style>