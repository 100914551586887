import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

import Home from '../views/Home Page/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import ForgetPassword from '../views/ForgetPassword.vue'
import NewPassword from '../views/NewPassword.vue'
import Verification from '../views/Verification.vue'
import ChooseVerification from '../views/ChooseVerification.vue'
import OnlineClassDetail from '../views/OnlineClassDetail.vue'
import Mentor from '../views/Mentor.vue'
import NgajarAsik from '../views/NgajarAsik.vue'
import ViewCourseModule from '../views/ViewCourseModule.vue'

import BelajarMandiri from '../views/BelajarMandiri.vue'
import OnlineClass from '../views/OnlineClass.vue'
import Checkout from '../views/Checkout/Checkout.vue'
import TopupToken from '../views/TopupToken.vue'
import SuccessCheckout from '../views/SuccessCheckout.vue'
import Quiz from '../views/Quiz.vue'
import AboutUs from '../views/AboutUs.vue'
import PusatBantuan from '../views/PusatBantuan.vue'
import GuruPreneur from '../views/GuruPreneur.vue'
import BantuMereka from '../views/BantuMereka.vue'
import CekCertificate from '../views/CekCertificate.vue'
import Statistik from '../views/Statistik.vue'

import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsCondition from '../views/TermsCondition.vue'

// Settings
import TransactionHistory from '../views/Settings/Transaction/TransactionHistory.vue'
import TransactionDetail from '../views/Settings/Transaction/TransactionDetail.vue'
import Profile from '../views/Settings/Profile.vue'
import ChangePassword from '../views/Settings/ChangePassword.vue'

import Certificate from '../views/Certificate.vue'
import CertificateDownload from '../views/CertificateDownload.vue'
import CertificateVerify from '../views/CertificateVerify.vue' //temporary hidden

// layouts
import Navbar from '../views/layouts/Navbar.vue'
import Header from '../views/layouts/Header.vue'
import Footer from '../views/layouts/Footer.vue'
import TopBanner from '../views/layouts/TopBanner.vue'
import Sidebar from '../views/layouts/Sidebar.vue'
import SidebarSettingPage from '../views/layouts/SidebarSettingPage.vue'

// import TesVideo from '../views/TesVideo.vue'

const routes = [
  // SETTING PAGE
  {
    path: '/transaction-history/:transaction_id',
    name: 'Transaction Detail',
    components: {default: TransactionDetail, Header, Footer},
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/transaction-history',
    name: 'Transaction History',
    components: {default: TransactionHistory, Sidebar: SidebarSettingPage, Header},
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profil',
    components: {default: Profile, Sidebar: SidebarSettingPage, Header},
    meta: {
      title: "Profile",
      requiresAuth: true
    }
  },
  {
    path: '/change-password',
    name: 'Ubah Kata Sandi',
    components: {default: ChangePassword, Sidebar: SidebarSettingPage, Header, Footer},
    meta: {
      requiresAuth: true
    }
  },

  // MAIN PAGE
  // {
  //   path: '/tes-video',
  //   name: 'tes-video',
  //   components: {default: TesVideo}
  // },

  // MAIN PAGE
  {
    path: '/statistik',
    name: 'Statistik',
    components: {default: Statistik}
  },
  {
    path: '/cek-sertifikat',
    name: 'CekCertificate',
    components: {default: CekCertificate},
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bantu-mereka',
    name: 'BantuMereka',
    components: {default: BantuMereka}
  },
  {
    path: '/gurupreneur',
    name: 'GuruPreneur',
    components: {default: GuruPreneur}
  },
  {
    path: '/topup-token',
    name: 'Topup Token',
    components: {default: TopupToken, Header},
    meta: {
        title: "Topup Token",
        requiresAuth: true
    }
  },
  {
    path: '/view-course-module',
    name: 'View Course Module',
    component: ViewCourseModule
  },
  {
    path: '/ngajar-asik',
    name: 'Ngajar Asik',
    components: {default: NgajarAsik, Sidebar, Header, Footer}
  },
  {
    path: '/pusat-bantuan',
    name: 'Pusat Bantuan',
    components: {default: PusatBantuan, Header, TopBanner}
  },
  {
    path: '/about-us',
    name: 'Tentang Kami',
    components: {default: AboutUs, Header}
  },
  {
    path: '/terms-condition',
    name: 'Terms & Condition',
    component: TermsCondition
  },
  {
    path: '/kebijakan-privasi',
    name: 'Kebijakan Privasi',
    components: {default: PrivacyPolicy, Header}
  },
  
  // kebijakan privasi tanpa header
  {
    path: '/privacy-policy',
    name: 'Privacy & Policy',
    component: PrivacyPolicy
  },
  // temporary hidden
  // {
  //   path: '/certificate/verify/:certificate_id',
  //   name: 'Verify Certificate',
  //   component: CertificateVerify
  // },
  {
    path: '/course/:course_id/certificate/mobile-download/:certificate_id',
    name: 'Download Certificate for Mobile',
    component: CertificateDownload,
  },
  {
    path: '/certificate/mobile/:certificate_id',
    name: 'Certificate Mobile',
    component: Certificate,
  },
  {
    path: '/course/:course_id/certificate/download/:certificate_id',
    name: 'Download Certificate',
    component: CertificateDownload,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/certificate/:certificate_id',
    name: 'Certificate',
    component: Certificate,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/belajar-mandiri/:course_id/:quiz_type/quiz/:quiz_id',
    name: 'Do Quiz',
    components: {default: Quiz, Header, Sidebar},
    children: [
      {
          path: ':question_id/:question_index',
          name: 'Quiz Question',
          component: () => import('../views/QuizQuestion.vue'),
          props: true
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/belajar-mandiri/:id/quiz',
    name: 'Course Quiz',
    components: {default: Quiz, Header, Sidebar},
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/success-checkout/:id',
    name: 'Success Checkout',
    components: {default: SuccessCheckout, Header, TopBanner},
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:type/:id/checkout/:transaction_id',
    name: 'Checkout',
    components: {default: Checkout, Header, TopBanner},
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/belajar-mandiri/:course_id',
    name: 'Detail Belajar Mandiri',
    components: {default: BelajarMandiri, Sidebar, Header, Footer},
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mentor',
    name: 'Mentor',
    components: {default: Mentor, Sidebar, Header, Footer},
    children: [
      {
          path: ':mentor_id',
          name: 'Chat Mentor History',
          component: () => import('../views/ChatHistory.vue'),
          props: true
      }
    ],
  },
  {
    path: '/online-class/:id',
    name: 'Kelas Online',
    components: {default: OnlineClassDetail, Sidebar, Header, Footer},
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/online-class',
    name: 'List Kelas Online',
    components: {default: OnlineClass, Sidebar, Header, Footer},
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/choose-verification',
    name: 'Choose Verification',
    component: ChooseVerification
  },
  {
    path: '/verification',
    name: 'Verification',
    component: Verification
  },
  {
    path: '/reset-password/:token',
    name: 'NewPassword',
    component: NewPassword
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: ForgetPassword
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    components: {default: Home, Header, Footer, Sidebar}
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters.isLoggedIn) {
          next()
          return
      }
      next('/')
  } else {
      next()
  }
})

export default router
