<template>
    <div class="container mx-auto">
        <div class="grid grid-cols-1 min-h-screen">
            <div class="my-auto mx-auto w-[345px]">
                <div class="mx-auto text-left">
                    <router-link to="/" class="mb-14"> 
                        <img class="my-5" src="../../public/assets/img/logo/main-logo.png" alt="main-logo" />
                    </router-link>
                    <div class="text-left">
                        <p class="font-bold mb-2 text-24">Konfirmasi</p>
                        <p class="mb-6 text-12">Pilih metode konfirmasi Anda</p>
                    </div>
                    <div class="text-left mb-3">
                        <input type="radio" id="via_email" class="mr-2" v-model="confirmation_method" value="email">
                        <label for="via_email" class="text-14">Email ke {{registered_user.email}}</label>
                    </div>
                    <div class="text-left mb-20 ">
                        <input type="radio" id="via_whatsapp" class="mr-2" v-model="confirmation_method" value="phone">
                        <label for="via_whatsapp" class="text-14">WhatsApp ke {{ registered_user.phone }}</label>
                    </div>
                    <p class="text-red-500 text-center mb-4 px-5" v-show="message">{{message}}</p>
                    <button @click="sendVerificationCode" class="bg-primary rounded text-center font-bold w-full text-white py-2">
                        <div v-if="isSending" class="spinner-border animate-spin inline-block w-5 h-5 border-4 rounded-full text-white" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div v-else>
                            Kirim Kode
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            confirmation_method: '',
            registered_user: this.$store.state.registered_user,
            isSending: false,
            message: '',
        }
    },
    methods: {
        sendVerificationCode() {
            this.isSending = true
            const body = {
                name: this.registered_user.name,
                email: this.registered_user.email,
                password: this.registered_user.password,
                phone: this.registered_user.phone,
                confirmation_method: this.confirmation_method
            }
            this.$store.dispatch('register', body).then(response => {
                this.isSending = false
                this.$router.push('/verification');
            }).catch(error => {
                this.isSending = false
                if (error.response) {
                    this.message = error.response.data.message
                } else {
                    console.log(error);;
                }
            })
        }
    },
    mounted() {
        console.log(this.registered_user);
    }
};
</script>

<style scoped>
.input-field {
    border: none;
    border-bottom: 2px solid rgba(37, 40, 43, 0.33);
}
.input-field:focus {
    outline: none;
    border-bottom: 2px solid #0099DC;
}
.eye-icon {
	float: right;
    /* margin-left: -25px; */
    margin-top: -32px;
    margin-right: 5px;
    position: relative;
    z-index: 2;
}
</style>