<template>
  <div class="flex flex-col mt-10 ml-12 mr-6 min-h-screen" style="width:380px" v-if="isLoggedIn">
          <!-- Teacher Profile Card -->
    <div class="bg-white rounded-lg shadow-lg mb-5 h-full">
      <div class="pt-9 pb-3">
        <div class="relative w-20 h-20 mx-auto mb-2">
          <img class="rounded-full h-full w-full object-cover" :src="profile.photo" alt="user photo"/>
        </div>
        <p class="text-16 font-semibold mb-2 mx-auto">{{profile.name}}</p>
        <div class="flex justify-center items-center align-middle">
          <img src="../../../public/assets/img/icons/teacher_level.png" alt="teacher_level" class="mr-2">
          <span class="text-14" style="line-height:0">{{profile.school_name}}</span>
        </div>
      </div>
      <hr class="mx-3">
      <div class="px-3 py-3">
        <ul class="relative fitur-educazone">
          <li>
            <router-link to="/profile" class="flex items-center text-16 py-4 px-3 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out" href="#!" active-class="text-primary">
              <span>Profil Saya</span>
            </router-link>
          </li>
          <li>
            <router-link to="/transaction-history" class="flex items-center text-16 py-4 px-3 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out" href="#!" active-class="text-primary">
              <span>Daftar Transaksi</span>
            </router-link>
          </li>
          <li v-if="canUpdatePassword">
            <router-link to="/change-password" class="flex items-center text-16 py-4 px-3 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out" href="#!" active-class="text-primary">
              <span>Ubah Kata Sandi</span>
            </router-link>
          </li>
          <li v-else>
            <button class="hover:cursor-not-allowed flex items-center text-16 py-4 px-3 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded-lg hover:text-primary transition duration-300 ease-in-out">
              Ubah Kata Sandi
            </button>
          </li>
          <hr class=" my-4 ">
          <a @click="logout" class="flex items-center text-16 py-4 px-3 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded-lg font-bold text-red-500 hover:text-red-700 transition duration-300 ease-in-out" href="#!">
            <span>Keluar Dari Akun</span>
          </a>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoggedIn: this.$store.getters.isLoggedIn,
      profile: this.$store.state.user,
      canUpdatePassword: false
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
			this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
ul.fitur-educazone li .router-link-exact-active {
  font-weight: bold;
  background: rgba(49, 63, 110, 0.2);
}
ul.fitur-educazone li .router-link-exact-active::after {
  content: "";
  margin-right: -0.1rem;
  position: absolute;
  right: -10px;
  width: 5px;
  height: 30px;
  background: #313F6E;
  border-radius: 15px;
}
</style>