<template>
    <div v-if="!isLoggedIn" class="relative text-left mb-40">
        <div class="relative">
            <div class="absolute w-full px-28 z-20">
                <div class="bg-white rounded shadow-lg p-14 mt-12 w-full">
                    <p class="text-24 font-bold mb-2">Ngajar Asik</p>
                    <p class="text-16 mb-10">Posting kemapuanmu sebagai seorang guru dengan belajar secara mandiri di Educazone</p>

                    <!-- Uploaded Post Card  -->
                    <div class="flex flex-col bg-white shadow rounded-lg border border-gray-400 mb-8 p-5">
                        <div class="flex items-center mb-6">
                            <img src="../../public/assets/img/mentor/mentor_2.png" alt="mentor_2" class="rounded-full w-10 h-10 mr-2 "/>
                            <div>
                                <p class="text-14 font-semibold">Deka Solomon Sanripula</p>
                                <p class="text-12 text-gray-500">Product Designer</p>
                            </div>
                        </div>
                        <div class="text-14">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <hr class="my-5">
                        <div class="flex items-center justify-start">
                            <div class="flex items-center cursor-pointer">
                                <img src="../../public/assets/img/icons/cil_share.svg" alt="cil_share" class="mr-2 ">
                                <span class="text-16 font-semibold text-gray-500 ">Bagikan</span>
                            </div>
                        </div>
                    </div>

                    <!-- Uploaded Post Card  -->
                    <div class="flex flex-col bg-white shadow rounded-lg border border-gray-400 mb-8 p-5">
                        <div class="flex items-center mb-6">
                            <img src="../../public/assets/img/mentor/mentor_2.png" alt="mentor_2" class="rounded-full w-10 h-10 mr-2 " />
                            <div>
                                <p class="text-14 font-semibold">Deka Solomon Sanripula</p>
                                <p class="text-12 text-gray-500">Product Designer</p>
                            </div>
                        </div>
                        <div class="text-14">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                has been the industry's standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make ...</p>
                            <span class="text-primary">Selengkapnya...</span>
                            <img src="../../public/assets/img/content-image.png" alt="content-image"
                                class="mt-4 w-full">
                        </div>
                        <hr class="my-5">
                        <div class="flex items-center justify-start">
                            <div class="flex items-center cursor-pointer">
                                <img src="../../public/assets/img/icons/cil_share.svg" alt="cil_share" class="mr-2 ">
                                <span class="text-16 font-semibold text-gray-500 ">Bagikan</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="absolute top-0 left-0 w-full bg-primary z-10" style="min-height:436px">
            </div>
        </div>
    </div>

    <div v-else class="h-full mt-12">
        <div class="bg-white rounded-lg shadow-lg text-left col-span-3 p-8 h-full">
            <!-- Form Upload Post Card -->
            <div class="flex bg-white shadow rounded-lg mb-8 p-5">
                <!-- user photo -->
                <div class="mr-2 ">
                    <img :src="user.photo" alt="mentor_2" class="rounded-full w-10 h-10 " />
                </div>
                <!-- posting field -->
                <div class="flex flex-col w-full">
                    <div data-bs-toggle="modal" data-bs-target="#make-post-modal" class="cursor-pointer bg-gray-100 text-14 rounded-full py-3 px-7 h-10 mb-4">
                        <p class="text-gray-500">Yuk Posting Sesuatu</p>
                    </div>
                    <div class="flex items-center justify-between w-1/4">
                        <div @click="file_type = 'image'; new_image = ''" class="flex items-center cursor-pointer" data-bs-toggle="modal"
                            data-bs-target="#select-file-modal">
                            <img src="../../public/assets/img/icons/flat-color-icons_add-image.svg"
                                alt="flat-color-icons_add-image" class="mr-2 ">
                            <span class="hidden laptop:flex font-semibold text-14 ">Foto</span>
                        </div>
                        <!-- add video temporary hidden -->
                        <!-- <div @click="file_type = 'video'" class="flex items-center cursor-pointer" data-bs-toggle="modal"
                            data-bs-target="#select-file-modal">
                            <img src="../../public/assets/img/icons/flat-color-icons_video-call.svg"
                                alt="flat-color-icons_video-call" class="mr-2 ">
                            <span class="hidden laptop:flex font-semibold text-14 ">Video</span>
                        </div> -->
                    </div>
                </div>
            </div>

            <!-- Modal Buat Postingan -->
            <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                id="make-post-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="makePostModalLabel" aria-hidden="true">
                <div class="modal-dialog relative w-auto pointer-events-none">
                    <div
                        class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                        <div
                            class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                            <h5 class="text-xl font-bold leading-normal text-gray-800" id="exampleModalLabel">Buat
                                Postingan</h5>
                            <button type="button"
                                class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                                data-bs-dismiss="modal" aria-label="Close">
                            </button>
                        </div>
                        <div class="modal-body relative p-4">
                            <div class="flex items-center mb-6">
                                <img :src="user.photo" alt="mentor_2" class="rounded-full w-10 h-10 mr-2 "/>
                                <div>
                                    <p class="text-14 font-semibold">{{user.name}}</p>
                                    <p class="text-12 text-gray-500">{{user.school_name}}</p>
                                </div>
                            </div>
                            <textarea v-model="make_post" placeholder="Apa yang Kamu mau posting?" class="text-14 py-3 w-full h-full resize-none focus:outline-none"></textarea>
                        </div>
                        <div
                            class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-between p-4 border-t border-gray-200 rounded-b-md">
                            <div class="flex">
                                <img @click="file_type = 'image'; new_image = ''" src="../../public/assets/img/icons/flat-color-icons_add-image.svg"
                                    accept="image/*" alt="flat-color-icons_add-image" class="mr-7 cursor-pointer" data-bs-toggle="modal"
                                    data-bs-target="#select-file-modal">

                                <!-- add video temporary hidden -->
                                <!-- <img @click="file_type = 'video'" src="../../public/assets/img/icons/flat-color-icons_video-call.svg" data-bs-toggle="modal"
                                    data-bs-target="#select-file-modal"
                                    alt="flat-color-icons_video-call" class="cursor-pointer"> -->
                            </div>
                            <button type="button"
                                @click="createPost()"
                                data-bs-dismiss="modal"
                                :class="make_post ? 'bg-primary text-white hover:bg-blue-600 focus:bg-blue-700 hover:shadow-lg focus:shadow-lg transform transition duration-150 ease-in-out hover:scale-110' : 'bg-disabled text-gray-500'"
                                class="inline-block px-4 py-2 font-semibold text-14 leading-tight rounded shadow-md focus:outline-none focus:ring-0 ml-1">Posting</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal Select File -->
            <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                id="select-file-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="selectImagePostLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
                    <div
                        class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                        <div
                            class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                            <h5 class="text-xl font-bold leading-normal text-gray-800" id="exampleModalLabel">Posting {{file_type == 'image' ? 'Foto' : 'Video'}}</h5>
                            <button type="button"
                                class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                                data-bs-dismiss="modal" aria-label="Close"
                                @click="new_image = ''"
                                >
                            </button>
                        </div>
                        <div class="modal-body relative p-5">
                            <div class="flex flex-col items-center justify-center">
                                <label for="file-input">
                                    <div v-if="!new_image" class="text-center px-10 py-3 border border-dashed cursor-pointer">
                                        <p>Pilih {{file_type == 'image' ? 'gambar' : 'video'}} untuk diposting</p>
                                    </div>
                                    <img :src="new_image" alt="" class="hover:cursor-pointer mb-4">
                                </label>
                                <video v-if="file_type == 'video' && new_image" width="320" height="240" controls>
                                    <source :src="new_image" type="video/mp4">
                                    Browser anda tidak support video tag
                                </video>
                                <input id="file-input" ref="upload_image" type="file" class="hidden" @change="updateImage($event)"/>
                                <input v-if="new_image" placeholder="Tambahkan Komentar?" type="text" v-model="make_post" class="p-4 w-full">
                            </div>
                        </div>
                        <div
                            class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button type="button"
                                data-bs-dismiss="modal"
                                @click="new_image = ''"
                                class="inline-block px-4 py-2 bg-white border border-primary text-primary focus:bg-blue-700 hover:shadow-lg focus:shadow-lg transform transition duration-150 ease-in-out hover:scale-110 font-semibold text-14 leading-tight rounded shadow-md focus:outline-none focus:ring-0 ml-1 mr-4">Batal</button>
                            <button type="button"
                                @click="createPost()"
                                data-bs-dismiss="modal"
                                class="inline-block px-4 py-2 bg-primary text-white focus:bg-blue-700 hover:shadow-lg focus:shadow-lg transform transition duration-150 ease-in-out hover:scale-110 font-semibold text-14 leading-tight rounded shadow-md focus:outline-none focus:ring-0 ml-1">Posting</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal Share Postingan -->
            <div class="modal fade fixed top-0 left-0 hidden w-full outline-none overflow-x-hidden overflow-y-auto"
                id="share-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="editPostLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                    <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                        <div
                            class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                            <h5 class="text-xl font-bold leading-normal text-gray-800" id="exampleModalLabel">Bagikan
                                Postingan</h5>
                            <button type="button"
                                class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                                data-bs-dismiss="modal" aria-label="Close">
                            </button>
                        </div>
                        <div class="modal-body relative p-4 overflow-scroll ">
                            <div class="flex items-center mb-6">
                                <img src="../../public/assets/img/mentor/mentor_2.png" alt="mentor_2"
                                    class="rounded-full w-10 h-10 mr-2 " />
                                <div>
                                    <p class="text-14 font-semibold">{{user.name}}</p>
                                    <p class="text-12 text-gray-500">{{user.school_name}}</p>
                                </div>
                            </div>
                            <textarea placeholder="Apa yang Kamu mau posting?" class="text-14 py-3 w-full h-full resize-none focus:outline-none"></textarea>
                            <!-- Shared Post -->
                            <div class="flex flex-col bg-white shadow rounded-lg p-5">
                                <div v-if="discussion_detail.user" class="flex items-center mb-6">
                                    <img v-if="discussion_detail.user.teacher" :src="discussion_detail.user.teacher.photo" alt="mentor_2"
                                        class="rounded-full w-10 h-10 mr-2 " />
                                    <div v-if="discussion_detail.user">
                                        <p class="text-14 font-semibold">{{discussion_detail.user.teacher ? discussion_detail.user.teacher.name : '-'}}</p>
                                        <p class="text-12 text-gray-500">{{discussion_detail.user.teacher ? discussion_detail.user.teacher.school_name : '-'}}</p>
                                    </div>
                                </div>
                                <div class="text-14 mb-4">
                                    <p>{{discussion_detail.text}}</p>
                                    <!-- <span class="text-primary">Selengkapnya...</span> -->
                                    <img v-if="discussion_detail.image" :src="discussion_detail.image" alt="content-image" class="mt-4 ">
                                </div>
                            </div>
                        </div>
                        <div
                            class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-between p-4 border-t border-gray-200 rounded-b-md">
                            <div class="flex">
                                <img src="../../public/assets/img/icons/flat-color-icons_add-image.svg"
                                    alt="flat-color-icons_add-image" class="mr-7 cursor-pointer" data-bs-toggle="modal"
                                    data-bs-target="#posting_photo">
                                <img src="../../public/assets/img/icons/flat-color-icons_video-call.svg"
                                    alt="flat-color-icons_video-call" class="cursor-pointer">
                            </div>
                            <button type="button"
                                :class="make_post ? 'bg-primary text-white hover:bg-blue-600 focus:bg-blue-700 hover:shadow-lg focus:shadow-lg transform transition duration-150 ease-in-out hover:scale-110' : 'bg-disabled text-gray-500'"
                                class="inline-block px-4 py-2 font-semibold text-14 leading-tight rounded shadow-md focus:outline-none focus:ring-0 ml-1">Bagikan</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal Edit Postingan -->
            <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                id="edit-post-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="makePostModalLabel" aria-hidden="true">
                <div class="modal-dialog relative w-auto pointer-events-none">
                    <div
                        class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                        <div
                            class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                            <h5 class="text-xl font-bold leading-normal text-gray-800" id="exampleModalLabel">Edit Postingan</h5>
                            <button type="button"
                                class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                                data-bs-dismiss="modal" aria-label="Close">
                            </button>
                        </div>
                        <div v-if="discussion_detail.user" class="modal-body relative p-4">
                            <div class="flex items-center mb-6">
                                <img :src="getUploaderProfileImage(discussion_detail.user.teacher.photo)" alt=""
                                    class="rounded-full w-10 h-10 mr-2 " />
                                <div>
                                    <p class="text-14 font-semibold">{{discussion_detail.user.teacher ? discussion_detail.user.teacher.name : '-'}}</p>
                                    <p class="text-12 text-gray-500">{{discussion_detail.user.teacher ? discussion_detail.user.teacher.school_name : '-'}}</p>
                                </div>
                            </div>
                            <textarea placeholder="Apa yang Kamu mau posting?" class="text-14 py-3 w-full h-full resize-none focus:outline-none" v-model="discussion_form"></textarea>
                            <div v-if="discussion_detail.image">
                                <label for="file-input">
                                    <img :src="new_image" alt="" class="hover:cursor-pointer">
                                </label>
    
                                <input id="file-input" ref="update_image" type="file" class="hidden" @change="updateImage($event)"/>
                            </div>
                        </div>
                        <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button data-bs-dismiss="modal" @click="updateDiscussion(discussion_detail.id)" type="button" class="bg-primary text-white hover:bg-blue-600 focus:bg-blue-700 hover:shadow-lg focus:shadow-lg transform transition duration-150 ease-in-out hover:scale-110 inline-block px-4 py-2 font-semibold text-14 leading-tight rounded shadow-md focus:outline-none focus:ring-0 ml-1">Edit</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Uploaded Post Card  -->
            <div v-for="discussion in discussions" :key="discussion.id" class="flex flex-col bg-white shadow rounded-lg mb-8 p-5">
                <div class="flex items-center justify-between mb-6">
                    <div class="flex items-center justify-start">
                        <img :src="getUploaderProfileImage(discussion.user.teacher.photo)" alt="mentor_2"
                            class="rounded-full w-10 h-10 mr-2" />
                        <div>
                            <p class="text-14 font-semibold">{{discussion.user.teacher ? discussion.user.teacher.name : '-'}}</p>
                            <p class="text-12 text-gray-500">{{discussion.user.teacher ? discussion.user.teacher.school_name : '-'}}</p>
                        </div>
                    </div>
                    <div v-show="isUserDiscussion(discussion.user.id)" class="dropdown relative">
                        <button
                            class="dropdown-toggle bg-transparent text-white font-medium text-xs leading-tight uppercase transition duration-150 ease-in-out flex items-center whitespace-nowrap"
                            type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="../../public/assets/img/icons/option.svg" alt="option">
                        </button>
                        <ul class="dropdown-menu min-w-max absolute hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none"
                            aria-labelledby="dropdownMenuButton1">
                            <li>
                                <a @click="getDiscussionDetail(discussion.id)" class="flex items-center justify-start dropdown-item text-sm py-2 px-4 font-normal w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                                    href="#" data-bs-toggle="modal" data-bs-target="#edit-post-modal">
                                    <img src="../../public/assets/img/icons/edit-post.svg" alt="edit post" class="mr-3">
                                    Edit Postingan
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item flex items-center justify-start text-sm py-2 px-4 font-normal w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                                    href="#" data-bs-toggle="modal" data-bs-target="#hapus-post-modal">
                                    <img src="../../public/assets/img/icons/delete-post.svg" alt="delete post"
                                        class="mr-3">
                                    Hapus
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div class="text-14 mb-4">
                    <p>{{discussion.text}}</p>
                    <!-- <span class="text-primary">Selengkapnya...</span> -->
                    <img v-if="discussion.image" :src="discussion.image" alt="" class="mt-4">
                </div>
                <!-- <div class="flex justify-between w-full">
                    <div class="flex items-center">
                        <img src="../../public/assets/img/icons/small-like.png" alt="uiw_like-o"
                            class="mr-2 cursor-pointer">
                        230 orang lainnya menyukai
                    </div>
                    <div class="flex items-center">
                        <img src="../../public/assets/img/icons/small-comment.png" alt="uiw_like-o"
                            class="mr-2 cursor-pointer">
                        12 Komentar
                    </div>
                </div> -->
                <hr class="my-5">
                <div class="flex items-center justify-between w-1/2">
                    <div class="flex items-center cursor-pointer transform transition duration-200 hover:scale-110">
                        <img src="../../public/assets/img/icons/uiw_like-o.svg" alt="uiw_like-o" class="mr-2">
                        <span class="hidden laptop:flex text-16 font-semibold text-gray-500">Suka</span>
                    </div>
                    <div class="flex items-center cursor-pointer transform transition duration-200 hover:scale-110"
                        data-bs-toggle="collapse" :data-bs-target="`#collapseCommentSection${discussion.id}`"
                        @click="getDiscussionDetail(discussion.id)">
                        <img src="../../public/assets/img/icons/ant-design_comment-outlined.svg"
                            alt="ant-design_comment-outlined" class="mr-2 ">
                        <span class="hidden laptop:flex text-16 font-semibold text-gray-500">Komentar</span>
                    </div>
                    <div class="flex items-center cursor-pointer transform transition duration-200 hover:scale-110"
                        data-bs-toggle="modal" data-bs-target="#share-modal">
                        <img src="../../public/assets/img/icons/cil_share.svg" alt="cil_share" class="mr-2 ">
                        <span class="hidden laptop:flex text-16 font-semibold text-gray-500 ">Bagikan</span>
                    </div>
                </div>

                <!-- comment section -->
                <div class="collapse" :id="`collapseCommentSection${discussion.id}`">
                    <div class="mt-8"></div>
                    <!-- new comment -->
                    <div class="flex items-start justify-start mb-6">
                        <img :src="user.photo" alt="user photo" class="rounded-full w-10 h-10 mr-2 mt-2" />
                        <div class="flex-col w-full">
                            <input v-model="comment_text" type="text" placeholder="Tambahkan Komentar" class="rounded-xl border border-gray-200 w-full p-4 mb-2">
                            <button @click="addDiscussionComment(discussion.id)" class="flex justify-center items-center bg-primary rounded-md text-white py-1 px-3">
                                Tambahkan Komentar
                            </button>
                        </div>
                    </div>

                    <!-- all comments -->
                    <div v-for="comment in discussion.comments" :key="comment.id" class="flex items-start justify-start mb-6">
                        <img :src="getUploaderProfileImage(comment.user.teacher.photo)" alt="mentor_2" class="rounded-full w-10 h-10 mr-2 mt-2" />
                        <div class="flex flex-col items-start justify-start">
                            <div class="bg-gray-100 rounded-xl p-4">
                                <div class="mb-2">
                                    <p class="text-14 font-semibold">{{comment.user.teacher ? comment.user.teacher.name : '-'}}</p>
                                    <p class="text-12 text-gray-500">{{comment.user.teacher ? comment.user.teacher.school_name : '-'}}</p>
                                </div>
                                <div class="mb-4">{{comment.text}}</div>
                                <!-- <div class="text-[#828282]">
                                    <span class="text-primary cursor-pointer">0 Suka</span> | <span data-bs-toggle="collapse" data-bs-target="#collapseReplyComment" class="mr-3 cursor-pointer">Balas</span><span>• 0 Balasan</span>
                                </div> -->
                            </div>

                            <!-- reply comment section -->
                            <!-- <div class="collapse" id="collapseReplyComment">
                                <div class="mt-8"></div>
                                <div class="flex items-start justify-start mb-6">
                                    <img src="../../public/assets/img/mentor/mentor_2.png" alt="mentor_2" class="rounded-full w-10 h-10 mr-2 mt-2" />
                                    <div class="flex-col w-full">
                                        <input type="text" placeholder="Tambahkan Balasan" class="rounded-xl border border-gray-200 w-full p-4 mb-2">
                                        <button class="bg-primary rounded-md text-white py-1 px-3">Tambahkan Balasan</button>
                                    </div>
                                </div>
                                
                                <div class="flex items-start justify-start mb-6">
                                    <img src="../../public/assets/img/mentor/mentor_2.png" alt="mentor_2" class="rounded-full w-10 h-10 mr-2 mt-2" />
                                    <div class="flex flex-col items-start justify-start">
                                        <div class="bg-gray-100 rounded-xl p-4">
                                            <div class="mb-2">
                                                <p class="text-14 font-semibold">Deka Solomon Sanripula</p>
                                                <p class="text-12 text-gray-500">Product Designer</p>
                                            </div>
                                            <div class="mb-4 ">
                                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sed ab quas ullam! Fuga ut quo dolorum quasi eaque impedit, autem est praesentium sapiente cupiditate odio laboriosam atque minus! Perferendis, ullam?
                                            </div>
                                            <div class="text-[#828282]">
                                                <span class="cursor-pointer">Suka</span> | <span data-bs-toggle="collapse" data-bs-target="#collapseReplyRepliedComment" class="mr-3 cursor-pointer">Balas</span><span>• 1 Balasan</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                        </div>
                    </div>
                </div>

                <!-- Modal Hapus Postingan -->
                <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                    id="hapus-post-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                    aria-labelledby="hapusPostLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
                        <div
                            class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                            <div
                                class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                                <h5 class="text-xl font-bold leading-normal text-gray-800" id="exampleModalLabel">Hapus
                                    Postingan</h5>
                                <button type="button"
                                    class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                                    data-bs-dismiss="modal" aria-label="Close">
                                </button>
                            </div>
                            <div class="modal-body relative p-5">
                                <div class="flex items-center mb-6">
                                    Apakah Kamu Benar-benar mau menghapus postingan ini?
                                </div>
                            </div>
                            <div
                                class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                <button type="button"
                                    data-bs-dismiss="modal"
                                    class="inline-block px-4 py-2 bg-white border border-primary text-primary focus:bg-blue-700 hover:shadow-lg focus:shadow-lg transform transition duration-150 ease-in-out hover:scale-110 font-semibold text-14 leading-tight rounded shadow-md focus:outline-none focus:ring-0 ml-1 mr-4">Batal</button>
                                <button type="button"
                                    data-bs-dismiss="modal"
                                    @click="deletePost(discussion.id)"
                                    class="inline-return block px-4 py-2 bg-primary text-white focus:bg-blue-700 hover:shadow-lg focus:shadow-lg transform transition duration-150 ease-in-out hover:scale-110 font-semibold text-14 leading-tight rounded shadow-md focus:outline-none focus:ring-0 ml-1">Hapus</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isLoggedIn: this.$store.getters.isLoggedIn,
            discussion_form: '',
            discussions: [],
            make_post: '',
            comment_text: '',
            user: this.$store.getters.userDetail.user,
            discussion_detail: {},
            image_to_upload: '',
            new_image: '',
            new_video: '',
            options: {
                url: "http://httpbin.org/anything"
            },
            file_type: 'image',
            processing: false,
        }
    },
    methods: {
        getDiscussion() {
            this.axios.get('discussion').then(response => {
                this.discussions = response.data.data;
            }).catch(error => {
                console.log(error);
            })
        },
        getDiscussionDetail(discussion_id) {
            this.discussion_detail = this.discussions.find(discussion => discussion.id == discussion_id);
            this.discussion_form = this.discussion_detail.text
            this.new_image = this.discussion_detail.image ? this.discussion_detail.image : '';
            this.new_video = this.discussion_detail.video ? this.discussion_detail.video : '';
        },
        updateImage(e) {
            const image = e.target.files[0];
            this.getUploadedImageUrl(image)

            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.new_image = e.target.result;
            };
        },
        getUploadedImageUrl(imageToUpload) {
            var formData = new FormData();
            formData.append('fileUpload', imageToUpload);

            this.axios.post('discussion/upload-image', formData).then(response => {
                this.image_to_upload = response.data.data
                console.log(this.image_to_upload);
            }).catch(error => {
                console.log(error.response);
            })
        },
        updateDiscussion(discussion_id) {
            const body = {
                text: this.discussion_form,
                image: this.image_to_upload,
                video: this.new_video
            }
            
            this.axios.put(`discussion/${discussion_id}`, body).then(response => {
                console.log(response.data);
                this.getDiscussion();
            }).catch(error => {
                console.log(error);
                console.log(error.response);
            })
        },
        addDiscussionComment(discussion_id) {
            let discussion = this.discussions.find(discussion => discussion.id == discussion_id);
            
            discussion.comments.push({
                text: this.comment_text,
                user: {
                    teacher: {
                        name: this.user.name,
                        school_name: this.user.school_name,
                        photo: this.getUploaderProfileImage(this.user.photo)
                    }
                }
            })
            const body = {
                text: this.comment_text
            }
            this.comment_text = '';
            this.axios.post(`discussion/${discussion_id}/comment`, body).then(response => {
                this.getDiscussion();
            }).catch(error => {
                console.log(error);
            })
        },
        
        createPost() {
            const body = {
                text: this.make_post,
                image: this.image_to_upload,
                video: this.new_video
            }
            this.axios.post('discussion', body).then(response => {
                console.log(response.data);
                this.getDiscussion()
            }).catch(error => {
                console.log(error.response);
            })
        },
        deletePost(discussion_id) {
            this.axios.delete(`discussion/${discussion_id}`).then(response => {
                console.log(response.data);
                this.getDiscussion();
            }).catch(error => {
                console.log(error.response);
            })
        },
        getUploaderProfileImage(uploader_photo) {
            if (uploader_photo) {
                if (uploader_photo.slice(0, 7) == 'profile') {
                    return `https://is3.cloudhost.id/educazone/${uploader_photo}`;
                } else {
                    return uploader_photo;
                }
            } else {
                return 'https://ui-avatars.com/api/?name=' + this.user.name + '&background=0D8ABC&color=fff&size=128';
            }
        },
        isUserDiscussion(discussion_user_id) {
            return discussion_user_id == this.user.user_id
        },
        getContentTextLength() {
            var text = $('#test').text();
            text = text.trim();
            var len = text.length;
            alert(text);
            alert(len);
        }
    },
    mounted() {
        this.getDiscussion()
    },
}
</script>

<style scoped>
ul li a.active {
    font-weight: bold;
    color: #313F6E !important;
    border-bottom: 5px solid #313F6E !important;
}
</style>

