<template>
  <div class="relative flex flex-col justify-start">
    <div class="relative mb-12">
      <!-- banner background -->
      <!-- <img src="../../public/assets/img/bg-blue-gradient-sm.png" alt="bg-blue-gradient-sm" class="absolute -top-10 w-full hidden laptop:flex"> -->
      <!-- searchbar -->
      <div class="relative flex flex-col justify-center items-center mt-16 z-20 px-10 laptop:px-56">
        <p class="text-48 laptop:text-white font-bold mb-3">Pusat Bantuan</p>
        <div class="flex justify-start items-center bg-gray-100 rounded-lg w-full">
            <img
                src="../../public/assets/img/icons/search.png"
                alt="search"
                class="mx-4 h-4 w-4 bg-gray-100"
            />
            <input
                type="text"
                placeholder="Cari Bantuan"
                class="text-12 h-10 bg-gray-100 rounded-lg outline-none"
                style="width: 500px"
            />
        </div>
      </div>
    </div>
    <!-- Content -->
    <div class="relative px-10 laptop:px-56 text-left mb-24">
        <p class="text-32 font-bold  mb-12 text-white">Cari Bantuan Apa?</p>
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <!-- 1 -->
          <div class="accordion-item border-t-0 border-l-0 border-r-0 rounded-none bg-white border border-gray-200 px-5">
            <h2 class="accordion-header mb-0" id="flush-headingOne">
              <button class="accordion-button
            relative
            flex
            items-center
            w-full
            py-4
            text-16 font-bold text-black text-left
            bg-white
            border-0
            rounded-none
            transition
            focus:outline-none
            collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                aria-expanded="false" aria-controls="flush-collapseOne">
                Cara Login
              </button>
            </h2>
            <div id="flush-collapseOne" class="accordion-collapse border-0 collapse"
              aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body text-14 py-4 px-5">
                <p class="mb-2 ">Untuk Proses Login</p>
                  <ul>
                    <li>1. Masukkan Username</li>
                    <li>2. Masukkan Password</li>
                    <li>3. Klik Tombol Login</li>
                  </ul>
                
              </div>
            </div>
          </div>

          <!-- 2 -->
          <div class="accordion-item border-t-0 border-l-0 border-r-0 rounded-none bg-white border border-gray-200 px-5">
            <h2 class="accordion-header mb-0" id="flush-headingTwo">
              <button class="accordion-button
            relative
            flex
            items-center
            w-full
            py-4
            text-16 font-bold text-black text-left
            bg-white
            border-0
            rounded-none
            transition
            focus:outline-none
            collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo"
                aria-expanded="false" aria-controls="flush-collapseTwo">
                Cara Register
              </button>
            </h2>
            <div id="flush-collapseTwo" class="accordion-collapse border-0 collapse"
              aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body text-14 py-4 px-5">
                -
              </div>
            </div>
          </div>

          <!-- 3 -->
          <div class="accordion-item border-t-0 border-l-0 border-r-0 rounded-none bg-white border border-gray-200 px-5">
            <h2 class="accordion-header mb-0" id="flush-headingThree">
              <button class="accordion-button
            relative
            flex
            items-center
            w-full
            py-4
            text-16 font-bold text-black text-left
            bg-white
            border-0
            rounded-none
            transition
            focus:outline-none
            collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree"
                aria-expanded="false" aria-controls="flush-collapseThree">
                Akses Kelas Online
              </button>
            </h2>
            <div id="flush-collapseThree" class="accordion-collapse border-0 collapse"
              aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body text-14 py-4 px-5">
                -
              </div>
            </div>
          </div>
          <!-- 4 -->
          <div class="accordion-item border-t-0 border-l-0 border-r-0 rounded-none bg-white border border-gray-200 px-5">
            <h2 class="accordion-header mb-0" id="flush-headingFour">
              <button class="accordion-button
            relative
            flex
            items-center
            w-full
            py-4
            text-16 font-bold text-black text-left
            bg-white
            border-0
            rounded-none
            transition
            focus:outline-none
            collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour"
                aria-expanded="false" aria-controls="flush-collapseFour">
                Beli Materi Pembelajaran
              </button>
            </h2>
            <div id="flush-collapseFour" class="accordion-collapse border-0 collapse"
              aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body text-14 py-4 px-5">
                -
              </div>
            </div>
          </div>
        </div>
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>